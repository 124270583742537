import initialState from "./initialState";
import actionType from "../../actions/interviewQuestions/actionType";

const interviewQuestionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_INTERVIEW_QUESTIONS_CHAPTER: {
            return {
                ...state,
                chapterData: {
                    ...state.chapterData,
                    loading: true,
                    success: false,
                    error: false
                }
            }
        }
        case actionType.GET_INTERVIEW_QUESTIONS_CHAPTER_SUCCESS: {
            return {
                ...state,
                chapterData: {
                    data: action.payload,
                    loading: false,
                    success: true,
                    error: false
                }
            }
        }
        case actionType.GET_INTERVIEW_QUESTIONS_CHAPTER_FAILURE: {
            return {
                ...state,
                chapterData : {
                    ...state.chapterData,
                    loading: false,
                    success: false,
                    error: true
                }
            }
        }
        default:
            return state;
    }
};

export default interviewQuestionsReducer;
