const actionType = {
    DOWNLOAD_PDF:'DOWNLOAD_PDF',
    DOWNLOAD_PDF_SUCCESS:'DOWNLOAD_PDF_SUCCESS',
    DOWNLOAD_PDF_FAILURE:'DOWNLOAD_PDF_FAILURE',
    GET_CANDIDATE_LIST:'GET_CANDIDATE_LIST',
    GET_CANDIDATE_LIST_SUCCESS:'GET_CANDIDATE_LIST_SUCCESS',
    GET_CANDIDATE_LIST_FAILURE:'GET_CANDIDATE_LIST_FAILURE',
    GET_CANDIDATE_LIST_BY_EVALUATOR:'GET_CANDIDATE_LIST_BY_EVALUATOR',
    GET_CANDIDATE_LIST_BY_EVALUATOR_SUCCESS:'GET_CANDIDATE_LIST_BY_EVALUATOR_SUCCESS',
    GET_CANDIDATE_LIST_BY_EVALUATOR_FAILURE:'GET_CANDIDATE_LIST_BY_EVALUATOR_FAILURE',
    UPDATE_CANDIDATE_LIST_BY_EVALUATOR:'UPDATE_CANDIDATE_LIST_BY_EVALUATOR',
    GET_NEIGHBOURS_LIST:'GET_NEIGHBOURS_LIST',
    GET_NEIGHBOURS_LIST_SUCCESS:'GET_NEIGHBOURS_LIST_SUCCESS',
    GET_NEIGHBOURS_LIST_FAILURE:'GET_NEIGHBOURS_LIST_FAILURE',
    GET_CANDIDATE_LIST_FROM_REVIEW_GRID:'GET_CANDIDATE_LIST_FROM_REVIEW_GRID',
    GET_CANDIDATE_LIST_FROM_REVIEW_GRID_SUCCESS:'GET_CANDIDATE_LIST_FROM_REVIEW_GRID_SUCCESS',
    GET_CANDIDATE_LIST_FROM_REVIEW_GRID_FAILURE:'GET_CANDIDATE_LIST_FROM_REVIEW_GRID_FAILURE',
    RESET_HEADER_STATE:'RESET_HEADER_STATE'
}
export default actionType