const customState = {
    hireabilitydata: {
        chapterConstants: {},
        profileName: "",
        statusLevel: 0,
        multipleHirabilityData: [],
        shouldRender: false,
        isDisabled: false
    },
    totalModuleScores: [],
    vadcScaleNarratives: {},
    toastDetails: {
        isToast: false,
        content: null,
        type: null
    },
    chapterParams: {
        chapterId: "",
        outputFormat: "",
        locale: "",
        testId: -1,
        reportId: -1,
        defaultChapterId: "",
        isSvar: 0,
        additionalParams: {
            uniqueRefId: -1,
            source: ""
        },
        isAdminRequest: true,
        amcatId: -1,
        testAttemptId:-1,
        sulId:-1
    }
};
export default customState;
