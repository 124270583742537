import React, { useEffect, useState } from "react";
import { getCandidateList } from "../../../common/utils/apis";
import useDebounce from "../../../../customHooks/useDebounce.jsx";
import DropDownArrow from "./DropDownArrow";
import SearchBox from "./SearchBox";
import DropDownContent from "./DropDownContent";
import "../../css/AMCandidateListUR.css";

function AMCandidateListDR(props) {
  const { getCandidateReport, setCandidateListRes, candidateListRes } = props;
  const limit = 8;
  const [searchValue, setSearchValue] = useState("");
  const [isCandidateFound, setIsCandidateFound] = useState(true);
  const [errorMessage, setIsErrorMessage] = useState(
    "Please write atleast 3 characters or more"
  );
  const [statusDropdown, setStatusDropdown] = useState(false);
  const [offset, setOffset] = useState(0);

  const debouncedSearchValue = useDebounce(searchValue, 250);

  function handleChange(e) {
    let characterLength = e.target.value?.length;
    setSearchValue(e.target.value);

    if (characterLength === 0) {
      setIsCandidateFound(true);
      setCandidateListRes(false);
    }

    if (characterLength > 0 && characterLength < 3) {
      setIsCandidateFound(false);
      setIsErrorMessage("Please write atleast 3 characters or more");
    }
  }

  const toggleModal = () => {
    let sDropdown = document.getElementById("showDropdown");
    sDropdown.classList.toggle("show");
    setStatusDropdown(!statusDropdown);
  };

  const dropDownHandle = (e) => {
    if (!statusDropdown) {
      if (e.target.id !== "imgToRotateAM" && e.target.id !== "myInput") {
        var sDropdown = document.getElementById("showDropdown");
        sDropdown.classList.remove("show");
        setStatusDropdown(false);
      }
    }
  };

  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      dropDownHandle(e);
    });
    return () => {
      document.body.removeEventListener("click", dropDownHandle);
    };
  }, []);

  useEffect(() => {
    setOffset(0);
    if (debouncedSearchValue?.length >= 3) {
      getCandidateList((res) => {
        if (res?.length) {
          setIsCandidateFound(true);
          setCandidateListRes(res, false);
        } else {
          setIsCandidateFound(false);
          setIsErrorMessage("No Candidates Found");
        }
      }, {string: debouncedSearchValue});
    }
  }, [debouncedSearchValue]);

  useEffect(() => {
    if (offset !== 0) {
      getCandidateList(
        (res) => {
          if (res?.length) {
            setIsCandidateFound(true);
            setCandidateListRes(res, true);
          }
        },
        {string: debouncedSearchValue, offset: offset, limit: limit}
      );
    }
  }, [offset]);

  return (
    <div className={`dropdown ml-2`} id="dropdownCandidate">
      <DropDownArrow toggleModal={toggleModal} />
      <div
        id="showDropdown"
        className={`dropdown-menu shadow`}
        aria-labelledby="dropdownMenuLinkAM"
      >
        <SearchBox
          handleChange={handleChange}
          searchValue={searchValue}
          isCandidateFound={isCandidateFound}
        />
        <DropDownContent
          isCandidateFound={isCandidateFound}
          candidateListRes={candidateListRes}
          getCandidateReport={getCandidateReport}
          errorMessage={errorMessage}
          offset={offset}
          setOffset={setOffset}
          limit={limit}
        />
      </div>
    </div>
  );
}
export default AMCandidateListDR;
