import thunk from "redux-thunk";
import { applyMiddleware , createStore } from "redux";
import rootReducer from "../reducers/root.reducer";
import logger from "../middleware/logger";

const middlewares = [thunk , logger]
const store = createStore(rootReducer , applyMiddleware(...middlewares))
export default store;


