const mainState = {
    report: {
        data:{
            reportVars: {
                REPORT_NAME: "",
                CHAPTER_HEADERS: [],
                DEFAULT_CHAPTER: "",
                CHAPTER_SPACE: {
                    candidatedetailsheaderchapter: {
                        formData: {
                            fullName: {
                                label: "",
                                value: ""
                            },
                            emailId: {
                                label: "",
                                value: ""
                            },
                            mobileNumber: {
                                label: "",
                                value: ""
                            }
                        },
                        amcatId: {
                            label: "",
                            value: ""
                        },
                        testDate: {
                            label: "",
                            value: ""
                        },
                        iCardImage: "",
                        cutoffStatus: {
                            raw: [],
                            final: [],
                            disableCutoffStatus: false
                        },
                        assessmentDetails: {},
                        hideDownloadButton: 0,
                        moduleDetails: {},
                        reportName: "",
                        companyLogo: "",
                        reportLocales: {},
                        chapterConstants: {
                            CDH_HEADER: "",
                            CDH_CANDIDATE_DETAILS: "",
                            CDH_ROLE: "",
                            CDH_HIRABILITY: "",
                            CDH_CUTOFF_REASON: "",
                            DOWNLOAD_REPORT: "",
                            ASSESSMENT_DETAILS: "",
                            COMMENTS: "",
                            CANDIDATE_DROPDOWN_PLACEHOLDER: ""
                        },
                        chapterDescription: [],
                        colorScale: {
                            details: [],
                            showColorScale: 1
                        },
                        pdfChapterHeader: "",
                        featureOrder: []
                    },
                    totalscorechapter: {
                        totalScores: [],
                        elementTextMapping: {
                            OS_ScoringPending: "",
                            OS_NotCompleted: "",
                            OS_NotAttempted: "",
                            OS_NoScore: "",
                            OS_ScoringInReview: "",
                            OS_ScoringPercentile: ""
                        },
                        linkingIcons: {
                            chapterMapping: {},
                            chapterTexts: {
                                CandidateDetailsHeaderChapter: "",
                                TotalScoreChapter: "",
                                IntroductionChapter: "",
                                ScoreSummaryChapter: "",
                                CandidateProfileChapter: "",
                                CandidateResponseChapter: "",
                            }
                        },
                        chapterConstants: {
                            SCORE_UNIT_PERCENTILE: ""
                        },
                        chapterDescription: [],
                        colorScale: {
                            details: [],
                            showColorScale: 0
                        },
                        pdfChapterHeader: "",
                        featureOrder: []
                    },
                    introductionchapter: 1,
                    scoresummarychapter: 2,
                    candidateprofilechapter: 3,
                    candidateresponsechapter: 4,
                    proctoringchapter: 5
                },
                REPORT_TEMPLATE: ""
            },
            globalVars: {
                iAssessBaseUrl: "",
                getChapterUrl: "",
                assetsBaseUrl: "",
                pdfDownloadUrl: "",
                reportId: 1,
                locale: "",
                amcatId: "",
                branding: [],
                additionalParams: {
                    uniqueRefId: 0
                }
            }
        },
        loading: false,
        error: false,
        success: false
    },
    sessionData:{
        amcatId:"",
        candidateListKey:"",
        data:"",
        defaultChapterId:"",
        isAdminRequest:true,
        isSvar:"",
        locale:"",
        outputFormat:"",
        reportId:"",
        source:"",
        sulId:"",
        testId:"",
        viewMode:""
    }
}

export default mainState