import React from 'react';
/**
 * passing the default value for initial state, although not mandatory.
 */
const GlobalContext = React.createContext(
    {
        outputFormat:'json',
        amcatId:null,
        locale:'en-IN',
        reportId:1,
        crossDomain:true,
        xhrFields:{
          withCredentials:true
        }
      }
);

export default GlobalContext;