import React, { useEffect, useState } from 'react'
import {NavLink , useHistory} from 'react-router-dom'
import { CHAPTER_MAPPING } from '../../common/utils/mapping'

const ChapterList=(props)=>{
    
        let chapterdetails = props.chapterdetails
        let DEFAULT_CHAPTER = props.defaultChapter
        let listItems =[]
        let isMappedEvaluator = props?.isMappedEvaluator;
        const COMPONENT_MAP = {...CHAPTER_MAPPING}
        Object.keys(chapterdetails).forEach((key)=>{
            let header = chapterdetails[key]
            listItems.push(<>
            { header.value == 'YourFeedbackChapter' && !isMappedEvaluator
             ? null
             :
             <li className="nav-item" key={header.value}>
              <NavLink  className='nav-link chapterName'
              id={'pills-'+header.value+'-tab'}
              data-chapterid={header.value}
              to={'/'+COMPONENT_MAP[header.value]['url']}>
              {header.label}
              </NavLink>
            </li>
            }
            </>)
        })    

        const [ locationKeys, setLocationKeys ] = useState([])
    const history = useHistory()    
    useEffect(() => {
        return history.listen(location => {
          if (history.action === 'PUSH') {
            setLocationKeys([ location.key ])
          }
      
          if (history.action === 'POP') {
            if (locationKeys[1] === location.key) {
              setLocationKeys(([ _, ...keys ]) => keys)
              let list = document.querySelector('.chapterList')
              if(!list.querySelector('a.active')){
                document.querySelector("#pills-"+DEFAULT_CHAPTER+"-tab").click()
              }
            } else {
              setLocationKeys((keys) => [ location.key, ...keys ])
              let list = document.querySelector('.chapterList')
              if(!list.querySelector('a.active')){
                document.querySelector("#pills-"+DEFAULT_CHAPTER+"-tab").click()
              }
            }
          }
        })
      }, [ locationKeys, ])
      document.querySelectorAll(".nav-item").forEach(function(item){
        item.addEventListener('click', e => {
          var b=document.getElementById('pills-tab');
          var x=item.offsetLeft;
          var c=item.offsetWidth;
          var d=x+((b.scrollHeight/2)-(c));
           document.getElementById('pills-tab').scrollTo({left:d,behavior:'smooth'});
        })
      }) 
        return (
            <React.Fragment>
              {Object.keys(chapterdetails).length?
                <ul className = {` nav nav-pills mb-3 chapterList marginTop10 ${Object.keys(chapterdetails).length==1?"d-none":null} `} id="pills-tab" role="tablist">
                  {listItems}
              </ul>
              :null
              }
            </React.Fragment>
        )
    }

export default ChapterList
