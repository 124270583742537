import React from 'react';
import { createRoot } from 'react-dom/client';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n"

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import './assets/css/codeplayer.css'

import './assets/css/variables.css'
import './assets/css/helperClasses.css'
import './assets/css/utility.css'
import './assets/css/common.css'
import './assets/css/graph.css'
import './chapters/chapterlist/css/chapterlist_dr.css'
import './chapters/chapterlist/css/chapterlist.css'
import store from './store/store';

const container = document.getElementById('root')
const root = createRoot(container);
root.render(
<Provider store={store}>
    <I18nextProvider i18n={i18n}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </I18nextProvider>
</Provider>);

serviceWorker.unregister();
