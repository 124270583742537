import React,{Component}from 'react'
import { wavingpotgraph, displayThreshold, wavingpotsvg } from '../../common/utils/macros'
import * as UrlConstants from '../../../constants/urls'
import {animatePots} from '../../common/utils/common'
import Slider from "react-slick";
import {connect} from 'react-redux'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Trans} from 'react-i18next';

class TotalScoreDetails extends Component {
    constructor(props){
        super(props)
        
        /**
         * keeping a track of manual and automatic module, while updating from feeback chapter,
         *  we only need to update the manual modules 
         */
        this.moduleTypes={manual:[], automatic:[]}
        this.chapterList = this.props.chapterList.map(i=>i.value)
        this.props.chapterdetails.totalScores.forEach(module=>Object.keys(module.manualScoreObj).length?this.moduleTypes.manual.push(module.moduleId):this.moduleTypes.automatic.push(module.moduleId))
    }
    navigateToChapterHandler=(chapterid,moduleid)=>{
        if(chapterid){
            window.externalLinks[chapterid] =  moduleid
        document.querySelector("ul.chapterList li.nav-item a#pills-"+chapterid+"-tab").click()
        }
    }
    render(){
        let chapterdetails = this.props.chapterdetails
        let totalModuleScores = this.props.totalModuleScores
        let chapterConstants = chapterdetails.chapterConstants
        let totalScoresRender=[]
        let settings = {
                dots: false,
                infinite: false,
                slidesToShow: 4.5
        }
        const renderExtraScores = (data)=>{
            return(
                <div className="threshold font-semibold">
                    <span className="threshold-header font-regular manual-header-pot">{data?data.header:null}: </span>
                    <span className="manual-score-pot-value">{data?data.value:null}</span>
                </div>
            )
        }
        totalModuleScores.forEach((module)=>{
            let chapterMappingArr =chapterdetails.linkingIcons['chapterMapping'][module.moduleId]?chapterdetails.linkingIcons['chapterMapping'][module.moduleId]:[]
            let mappedChapter=""
            if(chapterMappingArr.length && chapterMappingArr.indexOf('CandidateResponseChapter')!==-1 && this.chapterList.indexOf('CandidateResponseChapter')!=-1){
                mappedChapter = 'CandidateResponseChapter'
            }else if(chapterMappingArr.length && chapterMappingArr.indexOf('CandidateProfileChapter')!==-1 && this.chapterList.indexOf('CandidateProfileChapter')!=-1){
                mappedChapter = 'CandidateProfileChapter'
            }
            totalScoresRender.push(
                <div onClick={()=>this.navigateToChapterHandler(mappedChapter,module.moduleId)} key={module.moduleId} className={chapterdetails.linkingIcons['chapterMapping'][module.moduleId]?'hasLink potBox' :'potBox'} 
                data-moduleid={module.moduleId}
                data-chapterid={mappedChapter} data-scorescale={module.scoreScale || ''}>
                        <div className="modName" data-toggle="tooltip" data-placement="top" title={module.moduleName}>
                            {module.moduleName}
                        </div>
                        {module.elementID ==='OS_TotalScore'?
                            <div className="scoreBox" data-colorscale={JSON.stringify(chapterdetails.colorScale.details)}>
                                <div className="scoreVal">
                                    <span className="score">{Math.round(module.moduleScore)}</span>
                                    <span className="max-score">{module.scoreScale?'/'+module.scoreScale:chapterConstants?.SCORE_UNIT_PERCENTILE}</span>
                                </div>
                                <div className="potVal">
                                    {wavingpotgraph(module.percent,chapterdetails.colorScale)}
                                </div>
                                {Object.keys(module.manualScoreObj).length && this.moduleTypes.manual.indexOf(module.moduleId)!=-1 ?
                                    renderExtraScores(module.manualScoreObj)
                                    :Object.keys(module.automatedScoreObj).length?
                                        renderExtraScores(module.automatedScoreObj)
                                        :displayThreshold(module, "Threshold")
                                }
                            </div>
                        :
                            <React.Fragment>    
                                <div className="it-cont">
                                    <div className="icon">
                                        <img src= {require('../../../assets/images/'+module.elementID+'.svg')} alt="img"/>
                                    </div>
                                    <div className="text">
                                        {chapterdetails.elementTextMapping[module.elementID]}
                                    </div>
                                </div>
                                {Object.keys(module.manualScoreObj).length && this.moduleTypes.manual.indexOf(module.moduleId)!=-1 ?
                                    renderExtraScores(module.manualScoreObj)
                                :null}    
                            </React.Fragment>
                        }
                </div>
            )
        })
        return (
            <div className="pot">
                {wavingpotsvg()}
                <Slider {...settings} className="d-none d-lg-block d-xl-block">{totalScoresRender}</Slider>
                <div className="d-block d-sm-block d-md-block d-lg-none" style={{width:"100%"}}>
                    <div className="pots-mobile flexContainer">
                            {totalScoresRender}
                    </div>
                </div>
            </div>
        )   
    } 
    componentDidMount(){
        this.boxAnimate();
    }
    componentDidUpdate(){
        this.boxAnimate();
    }
    boxAnimate=()=>{
        document.querySelectorAll(".box").forEach(function(item,key){
            let max_percent = item.querySelector(".percent").getAttribute('data-percent')
            animatePots(item, max_percent)
        })
    }
}

const mapStateToProps = state =>{
    return {
        totalModuleScores:state.custom.totalModuleScores
    }
}
export default connect(mapStateToProps)(TotalScoreDetails)
