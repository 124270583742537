import React, { useEffect, useState } from 'react';

export const ScrollToTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    // Button is displayed after scrolling for 300 pixels
    const toggleVisibility = () => {
        setIsVisible(window.pageYOffset > 120);
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);

        return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);

    const scrollTop = () => window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
    });

    return (
        <div className={`scrollToTopContainer ${!isVisible ? 'd-none' : ''}`} aria-hidden={isVisible} aria-describedby="scroll to top section">
            <button className="btn btn-primary scrollToTopBtn" aria-label="scroll-up" onClick={scrollTop}>
                <span>&#x276E;</span>
            </button>
        </div>
    )
}
