import React, { Component } from 'react'
import { connect } from 'react-redux'

class HireabilityStatus extends Component {
    constructor(props){
        super(props)
        this.state={
            displayMultipleHirability:false
        }
    }
    toggleMultipleHirabilityHandler = ()=>{
        //let current_view = this.state.displayMultipleHirability
        this.setState({displayMultipleHirability: true})
    }
    render() {
        let multiple_hirability_render =[]
        this.props.hireabilitydata.multipleHirabilityData.forEach((stat)=>{
            let reason = ""
            if(Array.isArray(stat.rejectionReason)){
                 reason = stat.rejectionReason.join(", ")
            }else if(stat.rejectionReason === null){
                reason = ""
            }else if(typeof(stat.rejectionReason) === 'string'){
                reason = stat.rejectionReason
            }
            multiple_hirability_render.push(
                <div className="multi_status_container row" key= {stat.profileName}>
                    <div className="col my-auto p-0">
                        <div className="profileName">
                            {stat.profileName}
                        </div>
                        {/* {reason.length ===0 && stat.statusName ?
                            <div className="statusName d-xl-inline-block d-none">
                                {stat.statusName}
                            </div>
                        :null} */}
                        {reason.length? 
                        <div className="rejectionContainer">
                            <span className="rejectionHeader d-xl-block d-none">{this.props.hireabilitydata.chapterConstants.CDH_CUTOFF_REASON}</span>
                            <span className="rejectionHeader d-xl-none d-inline">{this.props.hireabilitydata.chapterConstants.CDH_CUTOFF_REASON}:
                            </span> <span className="rejectionReason d-xl-block d-inline">{reason}</span>
                        </div>
                        :null }
                    </div>
                    
                    <div className="col-3 my-auto p-0">
                        {stat.statusLevel == 1 || stat.statusLevel == 2 || stat.statusLevel == 3?
                            <div className="stat-block">
                                <span className={`stat-circle ${stat.statusLevel == 1 ? 'high-class' : stat.statusLevel == 2 ? "medium-class" : 'low-class'}`}></span>
                                <small>{stat.statusName}</small> 
                            </div>
                        :null}
                    </div>
                   
                </div>
            )
        })
        return (
                <React.Fragment>
                {this.props.hireabilitydata.shouldRender && !this.props.hireabilitydata.isDisabled?
                    <React.Fragment>  
                        <div id="hirabilityContent" className={this.state.displayMultipleHirability?'displayShow':'displayHide'}>
                            <div className="status_ros">{multiple_hirability_render}</div>
                            <div id="arrow" data-popper-arrow></div>
                        </div>
                        <div id="hirability_ini" className="hirabilityHeader d-xl-inline-block d-none" onClick={this.toggleMultipleHirabilityHandler}>
                            {this.props.hireabilitydata.chapterConstants.CDH_HIRABILITY}:
                        </div>
                        <div className="hirabilityTextWrapper">
                            <div id="hirability_status_ini" className="statusContainer flexContainer" onClick={this.toggleMultipleHirabilityHandler}>
                                <span className={`stat-circle ${this.props.hireabilitydata?.statusLevel === 1 ? 'high-class' : this.props.hireabilitydata?.statusLevel === 2 ? "medium-class" : 'low-class'}`}></span>
                                <div className="header d-inline-block">
                                    {this.props.hireabilitydata.profileName}
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                :null}
                </React.Fragment>
        )
    }
    componentDidMount=()=>{
        document.addEventListener("click", (event)=>{
            if(event.target && event.target!="" && event.target.id!=='hirability_ini' && event.target.id!=='hirability_status_ini' && event.target.parentElement?.id!='hirability_status_ini' && this.state.displayMultipleHirability) {
                event.stopPropagation();
                this.setState({displayMultipleHirability:false})
            }
        });
    }
  
}
const mapStateToProps = state =>{
    return {
        hireabilitydata:state.custom.hireabilitydata
    }
}
export default connect(mapStateToProps)(HireabilityStatus)