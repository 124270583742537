import React from 'react'
import WarningIcon from '../../../assets/images/warningPop.svg'

const confirmFunctionHandler=(props)=>{
    props.okPopup()
    return;
}
const cancelFunctionHandler=(props)=>{
    props.closePopup()
    return;
}
const ConfirmPopup=(props)=>{
    return(
        <div className="modal sessionTimeout" role="dialog" aria-labelledby="details" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">{props.header}</h4>
                        <button type="button" className="close" onClick={() => cancelFunctionHandler(props)}
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="session-body flexContainer">
                        <img src={WarningIcon} alt="icon" className="error-img"/>
                        <div className="modal-body">
                        {props.text}
                        </div>
                    </div>
                    <div className="modal-footer session-footer" >
                        <button type="button" className="bttn-primary" onClick={() => confirmFunctionHandler(props)}>OK</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ConfirmPopup