import React, { Component } from 'react'
import { BrowserRouter } from 'react-router-dom'
import $ from 'jquery'
import { CHAPTER_MAPPING } from './chapters/common/utils/mapping'
import FullScreenLoading from './chapters/common/components/fullscreenloader'
import * as ConfigConstants from './constants/config'
import { connect } from 'react-redux'
import ReportError from './chapters/common/components/reporterror'
import { b64Decode } from './chapters/common/utils/common'
import { makePostRequest, makeGetRequest, getCandidateList } from './chapters/common/utils/apis'
import ConfirmPopup from './chapters/common/components/confirmpopup'
import { Trans } from 'react-i18next';
import { Toaster } from './chapters/common/components/toast'
import { Main } from './Main'
import * as UrlConstants from './constants/urls'
import ReportErrorTC from './chapters/common/components/reporterrorTC'
import { setTotalModuleScore, setChapterParams } from './actions/customAction/customAction'

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isDataLoaded: false,
            isSessionPopup: false,
            isActive:false
        }
        sessionStorage.setItem("session_report_data", JSON.stringify({}))
        window.externalLinks = {}
    }
    closeRedirectHandler = () => {
        this.setState({
            isSessionPopup: false,
            isDataLoaded: true,
            isError: true
        })
    }
    sessionOkHandler = () => {
        let returnUrl = sessionStorage.getItem('returnUrl')
        if (returnUrl) {
            window.location.href = returnUrl;
        } else {
            let redirectUrl = "?redirectUrl=" + encodeURIComponent(window.location.href);
            window.location.href = ConfigConstants.URL_TRANSFORM_MAP[window.location.origin]['LOGIN_PAGE_URL'] + redirectUrl;
        }
    }

    setBranding = (branding) => {
        /*
         This function will be called only for Custom Branding on TCPlus
        */
        let headerColorPrimary, headerColorSecondary, accentColor, scoreBars;

            headerColorPrimary = `${branding.header.color.primary} !important`;
            headerColorSecondary = `${branding.header.color.secondary} !important`;
            accentColor = `${branding.body.color.accent} !important`;
            scoreBars = `${branding.body.color.scoreBars} !important`;

            const sheet = document.styleSheets[0];
            sheet.insertRule(`:root {--header-bg: ${headerColorPrimary}}`, 0);
            sheet.insertRule(`:root {--header-secondary: ${headerColorSecondary}}`, 0);
            sheet.insertRule(`:root {--ur-accent: ${accentColor}}`, 0);          
            sheet.insertRule(`:root {--overall-color-3: ${scoreBars}}`, 0);     
            sheet.insertRule(`:root {--score-color: ${scoreBars}}`, 0);     
    }

    setLocaleToDocument = (language = 'en-us') => {
        document.documentElement.setAttribute('lang', language.toLowerCase());
        document.documentElement.setAttribute('dir', language.toLowerCase() === 'ar' ? 'rtl' : 'ltr');
    }

    updateDigitalReportElements = (reportVars) => {
        document.body.classList.add('container-fluid');
        document.body.classList.add('font_montserrat');
        document.body.classList.add('digital-Reporting');
        document.body.classList.remove('container');
        document.title = reportVars.REPORT_NAME || 'Report';
    }

    getCandidateReport = (amcatId) => {
        let session_request_data = JSON.parse(sessionStorage.session_request_data);
        session_request_data["amcatId"] = amcatId;
        sessionStorage.setItem("session_request_data", JSON.stringify(session_request_data))
        sessionStorage.setItem('session_report_data', JSON.stringify({}));
        window.history.pushState(null, null, '/')
        this.initialLoad(true)
    }

    InitApp = (response, dataToSend, viewMode, candidate_ids) => {
        const COMPONENT_MAP = { ...CHAPTER_MAPPING }
        $(document.body).tooltip({ selector: "[title]" })
        if ((response.data.code === 200 || response.data.code === 801) && response.data.data) {
            let responseData = response.data.data.report;
            let reportData = JSON.parse(b64Decode(btoa(atob(decodeURIComponent(responseData)))))
            console.log(reportData);
            let isTCReport = false;
            let isTCPlus = false;
            let headerData;
            this.setLocaleToDocument(reportData.globalVars.locale);
            if(reportData.globalVars.reportId === 110012 && reportData.globalVars.additionalParams.source == "digital"){
                isTCPlus = true;
            }
            if (reportData.reportVars.REPORT_TEMPLATE === "report.baseTcReport") 
            {
                isTCReport = true;
                // if (reportData.globalVars.branding && (Array.isArray(reportData.globalVars.branding) && reportData.globalVars.branding.length > 0)) {
                //     if (!reportData.reportVars.CHAPTER_SPACE.headerchapter.companyLogo) {
                //         reportData.reportVars.CHAPTER_SPACE.headerchapter.companyLogo = {};
                //     }
                //     reportData.reportVars.CHAPTER_SPACE.headerchapter.companyLogo.path = [reportData.globalVars.branding[0].files.logoFile];
                // }
                headerData = reportData.reportVars.CHAPTER_SPACE.headerchapter
                this.updateDigitalReportElements(reportData.reportVars);
                if(isTCPlus){
                    this.setBranding(reportData.globalVars.branding);
                }
                
            } else {
                headerData = reportData.reportVars.CHAPTER_SPACE.candidatedetailsheaderchapter
            }
            let defaultChapter = COMPONENT_MAP[reportData.reportVars.DEFAULT_CHAPTER]['url']
            let create_session_data = { ...dataToSend, defaultChapterId: reportData.reportVars.DEFAULT_CHAPTER, locale: reportData.globalVars.locale, additionalParams: reportData.globalVars?.additionalParams || {} }
            //delete create_session_data.data //we dont need data key to be sent in next requests ever
            const sessionRequestLocale = dataToSend?.locale
            sessionStorage.setItem('session_request_data', JSON.stringify(create_session_data));
            this.props.setChapterParams(create_session_data)
            //hack
            //reportData.reportVars.CHAPTER_HEADERS.push({label: "Insights", value: "InsightsChapter"})
            if (!reportData.reportVars.CHAPTER_HEADERS.filter((item) => { return item.value === reportData.reportVars.DEFAULT_CHAPTER }).length) {
                if (reportData.reportVars.CHAPTER_HEADERS.length) defaultChapter = COMPONENT_MAP[reportData.reportVars.CHAPTER_HEADERS[0].value]['url']
            }
            window.history.pushState(null, defaultChapter, '/' + defaultChapter)
            if (sessionRequestLocale !== reportData.globalVars.locale) {
                window.location.reload();
            }
            this.setState({
                isTCReport: isTCReport,
                isTCPlus: isTCPlus,
                isDataLoaded: true,
                isViewMode: viewMode === 2 ? true : false,
                candidate_ids: candidate_ids,
                headerChapter: headerData,
                totalScoreChapter: reportData.reportVars.CHAPTER_SPACE.totalscorechapter,
                chapterList: reportData.reportVars.CHAPTER_HEADERS,
                defaultChapter: reportData.reportVars.DEFAULT_CHAPTER,
                globalContextData: { ...dataToSend },
                globalVars: reportData.globalVars,
                isActive:false
            }, () => {
                if (this.state.totalScoreChapter) this.props.setTotalModuleScore(this.state.totalScoreChapter.totalScores)
            })
        } else {
            if (response.data.code === 401) {
                if(dataToSend?.reportRedirectionBehaviour  === "2"){
                    this.sessionOkHandler()
                }
                else{
                    this.setState({ isSessionPopup: true })
                }
            } else {
                this.setState({ isError: true, isDataLoaded: true, amcatId: dataToSend.amcatId })
            }
        }

    }
    initialLoad = (isActive=false) => {
        let queryString = window.location.search
        let params = new URLSearchParams(queryString)
        let viewMode = params.get('viewMode')
        let candidate_ids = params.get('ids')
        let isSvar = params.get('isSvar') || 0
        let candidatelistkey = params.get('candidateListKey') || null
        let data = params.get('data') || null
        let testAttemptId = params.get('testAttemptId') || null
        let amcatId = params.get('amcatId') || null
        let locale = params.get('locale') || 'en_us';
        let outputFormat = 'json'
        let defaultChapterId = params.get('defaultChapterId') || null
        let testId = params.get('testId') || null
        let reportId = params.get('reportId') || 1
        let source = params.get('source') || null
        let sulId = params.get('sulId') || null
        let dataToSend = {}
        let candidateListParams = {}
        let additionalParams = params.get('additionalParams') || null
        let isAdminRequest = true;
        let isMappedEvaluator = true;
        let preferredDefaultLocale = params.get("preferredDefaultLocale");
        let reportRedirectionBehaviour = params.get("reportRedirectionBehaviour");

        if(params.has("isMappedEvaluator") && params.get("isMappedEvaluator") === "false") {
            isMappedEvaluator = false;
        }

        if(params.has("isAdminRequest") && params.get("isAdminRequest") === "false") {
            isAdminRequest = false;
        }
        
        this.setState({
            sourceID: (typeof source === 'string')?source.toUpperCase():source
        })
        if((source!==null && source!==undefined) && source.toUpperCase()==="TC" )
        {
            document.body.classList.add('container-fluid');
            document.body.classList.add('px-0');        
            document.body.classList.remove('container');
        }

        if (queryString) {
            if (params.get('returnUrl') && reportId === '110012') {
                defaultChapterId = 'CandidateResponseChapter';
            }
            dataToSend = {
                outputFormat: outputFormat,
                locale: locale,
                testId: testId,
                reportId: reportId,
                viewMode: viewMode,
                defaultChapterId: defaultChapterId,
                isSvar: isSvar,
                candidateListKey: candidatelistkey,
                data: data,
                source: source,
                sulId: sulId,
                additionalParams: additionalParams,
                isAdminRequest : isAdminRequest,
                isMappedEvaluator: isMappedEvaluator,
                reportRedirectionBehaviour: reportRedirectionBehaviour,
            }

            candidateListParams = {
                testId: testId,
                reportId: reportId
            }
            if(preferredDefaultLocale){
                dataToSend.preferredDefaultLocale = preferredDefaultLocale;
            }
            amcatId ? dataToSend.amcatId = amcatId : dataToSend.testAttemptId = testAttemptId
            params.get('returnUrl') ? sessionStorage.setItem('returnUrl', params.get('returnUrl')) : sessionStorage.setItem('returnUrl', "")
        } else {
            dataToSend = { ...JSON.parse(sessionStorage.session_request_data) }
            dataToSend.outputFormat = "json" // temporary till all the chapters aren't moved to json
            viewMode = dataToSend.viewMode
        }
        if (candidate_ids) {
            let string_candidate_ids = candidate_ids.split(",")
            string_candidate_ids.forEach((item, index) => { string_candidate_ids[index] = Number(item) })
            candidate_ids = [...string_candidate_ids]
            delete localStorage.reportData
        } else if (localStorage.reportData) {
            let string_candidate_ids = JSON.parse(localStorage.reportData)
            string_candidate_ids.forEach((item, index) => { string_candidate_ids[index] = Number(item) })
            if (string_candidate_ids.indexOf(dataToSend.amcatId) > -1) {
                candidate_ids = [...string_candidate_ids]
            } else {
                candidate_ids = []
            }
            delete localStorage.reportData
        } else {
            candidate_ids = []
        }
        this.setState({isActive:isActive})
        if (ConfigConstants.APPLICATION_ENVIRONMENT === 'live') {
            makePostRequest({ language: "en", data: dataToSend }, UrlConstants.GET_REPORT_URL, (response) => { this.InitApp(response, dataToSend, viewMode, candidate_ids) })
            getCandidateList(res => {
                this.setState({candidateListRes : res})
            }, candidateListParams)
        } else {
            makeGetRequest(dataToSend, UrlConstants.GET_REPORT_URL, (response) => { this.InitApp(response, dataToSend, viewMode, candidate_ids) })
            getCandidateList(res => {
                this.setState({candidateListRes : res})
            })
        }
        this.dataToSend = dataToSend;
    }

    render() {
        return (
            <React.Fragment>
                {this.state.isSessionPopup ? <ConfirmPopup header={<Trans>SESSION_EXPIRED_HEADER</Trans>} text={<Trans>SESSION_EXPIRED_BODY</Trans>} okPopup={this.sessionOkHandler} closePopup={this.closeRedirectHandler} /> : null}
                <BrowserRouter>
                    {!this.state.isDataLoaded ?
                        <FullScreenLoading/> :
                        <React.Fragment>
                            {!this.state.isError ?
                                <Main
                                    initialLoad={this.initialLoad}
                                    {...this.state} 
                                    dataToSend = {this.dataToSend}
                                    getCandidateReport={this.getCandidateReport}
                                />:
                                this.state.sourceID==="TC"?
                                    <ReportErrorTC/>
                                    :<ReportError amcatId={this.state.amcatId}/>
                            }
                        </React.Fragment>
                    }
                    {this.props.toastDetails?.isToast && <Toaster {...this.props.toastDetails} />}
                </BrowserRouter>
            </React.Fragment>
        )
    }
    componentDidMount = () => {
        let classThis = this
        classThis.initialLoad()
        document.addEventListener("DOMContentLoaded", function () {
            document.addEventListener("keyup", function (e) {
                if ((e.target.type !== "textarea" && e.target.type !== "text") && (e.keyCode === 37 || e.keyCode === 39)) {
                    if (!!document.querySelector('.chapterList')) {
                        let activeChapter = document.querySelector('.chapterList').querySelector('a.active').getAttribute('data-chapterid')
                        let activeChapterIndex = classThis.state.chapterList.findIndex(e => e.value === activeChapter)
                        let newChapter = classThis.state.defaultChapter
                        if (e.keyCode === 37) {
                            newChapter = classThis.state.chapterList[((classThis.state.chapterList.length + activeChapterIndex - 1) % classThis.state.chapterList.length)].value
                        }
                        else if (e.keyCode === 39) {
                            newChapter = classThis.state.chapterList[((classThis.state.chapterList.length + activeChapterIndex + 1) % classThis.state.chapterList.length)].value
                        }
                        if (!!document.querySelector("#pills-" + newChapter + "-tab"))
                            document.querySelector("#pills-" + newChapter + "-tab").click()
                    }
                }
            })
        })
    }
}
const mapDispatchToProps = dispatch => ({
    setTotalModuleScore: (data)=> {
        dispatch(setTotalModuleScore(data))
    },
    setChapterParams: (data) => {
        dispatch(setChapterParams(data))
    }
})

// MapStateToProps

const mapStateToProps = state => {
    return {
        toastDetails: state.custom.toastDetails
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)