import React, { Component, useState } from 'react';
import { connect } from 'react-redux'
import '../css/header_dr.css'
import '../../../assets/css/DR_Chapters/DR_common.css'
import '../../../assets/css/helperClasses.css'
import LanguageDropDown from './languagedropdownDR'
import Popups from '../../common/components/popups.js'
import CandidateListDR from './candidateListDR';
import AMCandidateListUR from './AMCandidateListUR/AMCandidateListUR.jsx';
import userIcon from '../../../assets/images/user-icon.svg';
import { HeaderPopUp } from './DR_HeaderPopup';
import DownloadReport from './downloadReport';
import HelpMenuDR from './HelpMenuDR';
import { isEmptyObject } from '../../common/utils/common';
import SwitchCandidate from './SwitchCandidate.jsx';

const Header = ({
    initialLoad,
    getCandidateReport,
    isCandidateListActive,
    chapterdetails,
    chapter_headers,
    isTCPlus,
    branding,
    helpMenu,
    candidateListResult,
    isAdminRequest }) => {

    const [candidateListRes, setCandidateListRes] = useState(candidateListResult)
    let additionalDetails = chapterdetails?.candidateList || {};
    let recordsCount = Number(additionalDetails.recordsCount) || 1;
    let currentIndex = Number(additionalDetails.currentIndex) || 1;
    let candidateName = chapterdetails?.formData?.data?.headerCandidateDetails?.data?.fullName?.value || "";

    const changeCandidate = (user) => {
        let session_request_data = JSON.parse(sessionStorage.session_request_data);
        session_request_data["testAttemptId"] = user.sulId;
        sessionStorage.setItem("session_request_data", JSON.stringify(session_request_data))
        sessionStorage.setItem('session_report_data', JSON.stringify({}));
        window.history.pushState(null, null, '/')
        initialLoad(true)
    }

    const getOverAllScoreBandDom = () => {
        if (isEmptyObject(chapterdetails?.bandDetails?.data?.overAllBand)) {
            return (
                <div className="emailid">
                    {chapterdetails?.formData?.data?.headerCandidateDetails?.data?.email?.value}
                </div>
            )
        }
        else return (
            <div className='font-action-medium'>
                <span className='mr-1'>
                    {chapterdetails?.bandDetails?.scoreBandLabel}:
                </span>
                <span>
                    {chapterdetails?.bandDetails?.data?.overAllBand?.benchmarkName}
                </span>
            </div>
        )
    }

    const setCandidateListResult = (res, isInfiniteScroll = false) => {
        if (res) {
            if (isInfiniteScroll) {
                setCandidateListRes((prevState) => [...prevState, ...res])
            } else {
                setCandidateListRes(res);
            }
        } else {
            setCandidateListRes(candidateListRes);
        }
    }
    return (
        <React.Fragment>
            <Popups />
            <div id="snackbar"></div>

            <HeaderPopUp chapterdetails={chapterdetails} />

            <div className={`report-header-container ${recordsCount > 1 ? 'grid-template' : ''}`}>

                <div className="report-header row">
                    <div className="candidateDetailsContainer">
                        {isTCPlus ?
                            <div className="logo-container">
                                {branding.companyLogo ?
                                    <img className="companyLogo" src={branding.companyLogo} alt={chapterdetails?.companyLogo?.altText} />
                                    : null}
                            </div>
                            :
                            <div className="logo-container">
                                <svg className="companyLogo" width="92" height="40" viewBox="0 0 92 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M24.7411 2.97091C24.7411 4.62142 23.4207 5.94182 21.7702 5.94182C20.1197 5.94182 18.7993 4.62142 18.7993 2.97091C18.7993 1.3204 20.1197 0 21.7702 0C23.4207 0 24.7411 1.3204 24.7411 2.97091Z" fill="white" />
                                    <path d="M91.6196 36.7813C91.6196 38.4318 90.2991 39.7522 88.6486 39.7522C86.9981 39.7522 85.6777 38.4318 85.6777 36.7813C85.6777 35.1308 86.9981 33.8104 88.6486 33.8104C90.2991 33.8104 91.6196 35.1308 91.6196 36.7813Z" fill="white" />
                                    <path d="M47.7493 22.2652H40.0002C38.5972 22.2652 37.5244 21.1099 37.5244 19.7895C37.5244 18.3865 38.6798 17.3137 40.0002 17.3137H47.7493C49.1522 17.3137 50.2251 18.4691 50.2251 19.7895C50.3076 21.1924 49.1522 22.2652 47.7493 22.2652Z" fill="white" />
                                    <path d="M33.8107 39.9917C32.4078 39.9917 31.335 38.8363 31.335 37.5159V2.97082C31.335 1.56789 32.4078 0.412537 33.8107 0.412537C35.2136 0.412537 36.369 1.48537 36.369 2.88829V37.5242C36.2865 38.9271 35.1311 39.9999 33.8107 39.9999" fill="white" />
                                    <path d="M53.8478 39.9917C52.4449 39.9917 51.3721 38.8363 51.3721 37.5159V2.97082C51.3721 1.56789 52.4449 0.412537 53.8478 0.412537C55.2508 0.412537 56.4061 1.48537 56.4061 2.88829V37.5242C56.4061 38.9271 55.2508 39.9999 53.8478 39.9999" fill="white" />
                                    <path d="M81.9725 39.2572H75.6263C69.6102 39.2572 64.7412 34.3882 64.7412 28.3721V3.13586C64.7412 1.73293 65.814 0.577576 67.217 0.577576C68.6199 0.577576 69.7753 1.6504 69.7753 3.05333V28.3721C69.7753 31.5906 72.4161 34.2231 75.6263 34.2231H81.9725C83.3754 34.2231 84.4482 35.3785 84.4482 36.6989C84.5308 38.1018 83.3754 39.2572 81.9725 39.2572Z" fill="white" />
                                    <path d="M14.1861 17.2312H12.2055C8.987 17.2312 6.35445 14.5904 6.35445 11.3801C6.35445 8.16165 8.99525 5.52909 12.2055 5.52909H15.589C16.992 5.52909 18.0648 4.37374 18.0648 3.05333C18.0648 1.6504 16.9094 0.577576 15.589 0.577576H12.2055C6.18114 0.577576 1.3204 5.44657 1.3204 11.4627V11.8753C1.56798 17.7263 6.35445 22.3477 12.2055 22.3477H14.1861C17.4046 22.3477 20.0371 24.9885 20.0371 28.1988V28.3638C20.0371 31.5823 17.3963 34.2149 14.1861 34.2149H2.47576C1.07283 34.2149 0 35.3702 0 36.6906C0 38.0936 1.15535 39.1664 2.47576 39.1664H14.1861C19.8721 39.1664 24.576 34.7925 25.0712 29.1066V28.0337C25.1537 22.1827 20.2022 17.2312 14.1861 17.2312Z" fill="white" />
                                </svg>
                            </div>
                        }
                        <div className="detailsContainer" data-toggle="modal" data-target="#personal-details">
                            <div className='d-flex align-items-center'>
                                <img src={userIcon} className='img-fluid' />
                            </div>
                            <div className='ml-2'>
                                <div className="name font-h2-bold">{candidateName}</div>
                                {getOverAllScoreBandDom()}
                            </div>
                        </div>

                        {candidateListRes?.length > 1 ?
                            <div className='justify-content-between d-flex'>
                                <AMCandidateListUR
                                    candidateListRes={candidateListRes}
                                    setCandidateListRes={setCandidateListResult}
                                    getCandidateReport={getCandidateReport}
                                />
                            </div>
                            :
                            null
                        }

                        {recordsCount >= 2 && !isCandidateListActive ?
                            <CandidateListDR
                                recordsCount={recordsCount}
                                currentIndex={currentIndex}
                                selectedUser={changeCandidate}
                            />
                            : null}

                    </div>
                    <div className={(chapterdetails?.reportLocales && Object.keys(chapterdetails?.reportLocales).length > 1) ? 'navigationIcons border-right-0' : 'navigationIcons'}>
                        {chapterdetails?.candidateList?.previousCandidateDetails ?
                            <div className='navigation-arrow arrow-left rotate d-flex align-items-center' onClick={() => changeCandidate(additionalDetails.previousCandidateDetails)}>
                                <svg width="20" height="12" className='arrow-icon' viewBox="0 0 20 12" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.23 0.33L20 2.1L10.1 12L0.200001 2.1L1.97 0.33L10.1 8.46L18.23 0.33Z" />
                                </svg>
                            </div>
                            : null}
                        {chapterdetails?.candidateList?.nextCandidateDetails ?
                            <div className='navigation-arrow arrow-right d-flex align-items-center' onClick={() => changeCandidate(additionalDetails.nextCandidateDetails)} >
                                <svg width="20" height="12" className='arrow-icon' viewBox="0 0 20 12" xmlns="http://www.w3.org/2000/svg"><path d="M18.23 0.33L20 2.1L10.1 12L0.200001 2.1L1.97 0.33L10.1 8.46L18.23 0.33Z" /></svg>
                            </div>
                            : null}
                    </div>
                    <div className="extraDetailsContainerDR flexContainer">
                        {candidateListRes?.length > 1 ?
                            <SwitchCandidate
                                candidateListRes={candidateListRes}
                                getCandidateReport={getCandidateReport}
                            />
                            :
                            null
                        }
                        {(chapterdetails?.reportLocales && Object.keys(chapterdetails?.reportLocales) || {}).length > 0 ? <LanguageDropDown languages={chapterdetails?.reportLocales} /> : null}
                        <div tabIndex="0" aria-describedby="tooltip" className="hirabilityStatus flexContainer"></div>
                        {helpMenu && !Array.isArray(helpMenu) ?
                            <HelpMenuDR branding={branding} helpMenu={helpMenu} />
                            :
                            null
                        }
                        {chapterdetails?.downloadReport?.isEnabled ? <DownloadReport chapter_headers={chapter_headers} chapterdetails={chapterdetails} isAdminRequest={isAdminRequest} /> : null}
                    </div>
                </div>

            </div>

        </React.Fragment>
    )
}

export default Header
