import actionType from "./customActionType";
import store from '../../store/store'
export const updateHireabilityStatus = (data) => {
    return {
        type: actionType.UPDATE_HIREABLITY_STATUS,
        payload: data
    }
}

export const setTotalModuleScore = (data) => {
    return {
        type: actionType.SET_TOTAL_MODULE_SCORES,
        payload: [].concat(data)
    }
}

export const updateTotalModuleScore = (data) => {
    let totalModuleScores = [].concat(store.getState().custom.totalModuleScores)
    totalModuleScores.forEach((currentItem, index) => {
        data.forEach((editItem) => {
            if (currentItem.moduleId == editItem.moduleId) {
                if (editItem.elementID && editItem.elementID === 'OS_TotalScore') {
                    totalModuleScores[index] = { ...currentItem, ...editItem }
                } else {
                    totalModuleScores[index]['manualScoreObj']['value'] = editItem.manualScoreObj.value
                }

            }
        })
    })
    return {
        type: actionType.UPDATE_TOTAL_MODULE_SCORES,
        payload: totalModuleScores
    }
}

export const updateVADCScaleNarratives = (data) => {
    return {
        type: actionType.UPDATE_MANUAL_VADC_SCALE_NARRATIVES,
        payload: data
    }
}

export const showToast = (data) => {
    return {
        type: actionType.TOASTENABLE,
        payload: data
    }
}

export const setChapterParams = (data) => {
    return {
        type : actionType.SET_CHAPTER_PARAMS,
        payload: data
    }
}

