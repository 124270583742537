import axios from 'axios'
import { error, param } from 'jquery'

export class BaseApi {
    get(url ,parameter , requestAction , requestSuccessAction , requestFailureAction ) {
        let axiosConfig = {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        }
        return (dispatch) => {
           dispatch(requestAction())
           axios.get(url ,{params:parameter} , axiosConfig).then((responseData) => {
              if(responseData.status === 200){
                dispatch(requestSuccessAction(responseData.data))
              }
           }).catch((error) => {
                dispatch(requestFailureAction(error))
           })
        }
    }

    post(url , parameter , payload , requestAction , requestSuccessAction , requestFailureAction){
        let axiosConfig = {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        }
        return (dispatch) => {
            dispatch(requestAction())
            axios.post(url , payload ,{params:parameter} , axiosConfig ).then((responseData) => {
                if(responseData.status===200)
                dispatch(requestSuccessAction(responseData.data))
            }).catch((error) => {
                dispatch(requestFailureAction(error))
            })
        }
        
    }

    put(url , parameter , payload , requestAction , requestSuccessAction , requestFailureAction ) {
        let axiosConfig = {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        }
        return (dispatch) => {
            dispatch(requestAction())
            axios.put(url , payload , {params:parameter} ,axiosConfig).then((responseData) => {
                if(responseData.status===200){
                    dispatch(requestSuccessAction(responseData.data))
                }
            }).catch((error)=> {
                dispatch(requestFailureAction(error))
            })
        }
    }
    
    delete(url , payload , requestAction , requestSuccessAction , requestFailureAction ) {
        let axiosConfig = {
            withCredentials:true,
            data:payload,
            headers : {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        }
        return (dispatch) => {
            dispatch(requestAction())
            axios.delete(url , axiosConfig).then((responseData) => {
                if(responseData.status===200){
                    dispatch(requestSuccessAction(responseData.data))
                }
            }).catch((error) => {
                dispatch(requestFailureAction(error))
            })
        }
        
    }
} 

