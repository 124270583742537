import React, { Suspense } from 'react'
import { Route, Redirect } from 'react-router-dom'
import Header from './chapters/header/components/header'
import HeaderDr from './chapters/header/components/header_dr'
import TotalScoreDetails from './chapters/totalscoredetails/components/totalscoredetails'
import ChapterList from './chapters/chapterlist/components/chapterlist'
import ChapterListDr from './chapters/chapterlist/components/chapterlist_dr'
import GlobalContext from './chapters/common/components/globalcontext'
import { ScrollToTop } from './chapters/common/components/scrollToTop';
import { BackDropLoader,ChapterLoader } from './chapters/common/components/fullscreenloader'
import { CHAPTER_MAPPING } from './chapters/common/utils/mapping';
import ErrorBoundary from './chapters/common/components/errorboundary'
import { hiddenChapters } from './constants/chapterConstants'

export const Main = (props) => {
    const DEFAULT_CHAPTER  = props.defaultChapter;
    let HeaderChapter, ChapterListChapter;
    if (props.isTCReport) {
        HeaderChapter = HeaderDr
        ChapterListChapter = ChapterListDr
    } else {
        HeaderChapter = Header
        ChapterListChapter = ChapterList
    }

    const COMPONENT_MAP = { ...CHAPTER_MAPPING }
    const updatedChapterList = props.chapterList.filter(chapter => !hiddenChapters.includes(chapter.value))
    const isAdminRequest = props?.globalContextData?.isAdminRequest
    return (
        <React.Fragment>
            <BackDropLoader isActive={props.isActive}/>
            <HeaderChapter 
                initialLoad={props.initialLoad}
                key = "Header" 
                isCandidateListActive = {props.isActive} 
                chapterdetails = {props.headerChapter} 
                chapter_headers = {props.chapterList} 
                isViewMode = {props.isViewMode} 
                candidate_ids = {props.candidate_ids} 
                isTCPlus = {props.isTCPlus} 
                branding = {props.globalVars.branding} 
                helpMenu = {props.headerChapter.helpMenu} 
                candidateListRes = {props.candidateListRes}
                getCandidateReport = {props.getCandidateReport}
                isAdminRequest = {isAdminRequest} />
            {!props.isActive?
                <>
                    {props.totalScoreChapter && !props.isTCReport ? <TotalScoreDetails key="TotalScoreDetails" chapterdetails={props.totalScoreChapter} chapterList={props.chapterList} /> : null}
                    <GlobalContext.Provider value={{value:{ ...props.globalContextData }, branding:{...props.globalVars.branding}, isTCPlus:props.isTCPlus, chapterList:{...props.chapterList}, bandDetails:{...props.headerChapter.bandDetails}}} key="globalcontext">
                        <ChapterListChapter isTCReport={props.isTCReport} isMappedEvaluator={props.dataToSend.isMappedEvaluator} key="ChapterList" chapterdetails={updatedChapterList} defaultChapter={props.defaultChapter} />
                        {updatedChapterList.map((chapter) => {
                            let ChapterKey = COMPONENT_MAP[chapter.value]['modulePath']
                            return <Route path={"/" + COMPONENT_MAP[chapter.value]['url']}
                                key={chapter.value}
                                render={() => <Suspense fallback={
                                    <div className="chapterContentDr">
                                        <ChapterLoader />
                                    </div>
                                } ><ErrorBoundary><ChapterKey isTCReport={props.isTCReport} chapterLabel={chapter.label} chapterValue={chapter.value} isMappedEvaluator={props.dataToSend.isMappedEvaluator}/></ErrorBoundary></Suspense>} />
                        })
                        }
                        <Redirect to={"/" + COMPONENT_MAP[DEFAULT_CHAPTER]['url']} />
                    </GlobalContext.Provider>
                    {
                        props.isTCReport && <ScrollToTop />
                    }
                </>
                : null}
        </React.Fragment>
    )
}




