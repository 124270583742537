import React from "react";
import { htmlDecode } from "../../common/utils/common";
import "../css/HelpMenuDRPopup.css";

const HelpMenuDRPopup = (props) => {
    const helpMenu = props.helpMenu;
    const helpMenuIndex = props.helpMenuIndex;
    const len = helpMenu?.data[helpMenuIndex]?.data?.length;

    return(
        <div class="modal" tabindex="-1" role="dialog" id="helpMenuModal">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header d-flex align-items-center">
                        <div class="modal-title font-h2-semiBold">{helpMenu?.data[helpMenuIndex]?.label}</div>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        {helpMenu?.data[helpMenuIndex]?.data?.map((item)=>(
                            <div className="mt-4">
                                <div className="font-h4-semiBold mb-1">{len>1?item.competencyName:null}</div>
                                <div className="font-body-os text-justify" dangerouslySetInnerHTML={{__html: htmlDecode(item.competencyDescription)}}></div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HelpMenuDRPopup;
