import React, {useEffect, useState, useRef} from "react";
import CloseIcon from '../../../assets/images/close-grey.svg';
import "../css/HelpMenuDR.css";
import HelpMenuDRPopup from "./HelpMenuDRPopup";

const HelpMenuDR = (props) => {
    const questionMarkColor = props.branding.header.color.primary;
    const helpMenu = props.helpMenu;
    const [helpMenuIndex, setHelpMenuIndex] = useState(0);
    const [openDropdown, setOpenDropdown] = useState(false);
    const dropdownRef = useRef();
    
    const helpMenuIconClickHandler = () => {
      setOpenDropdown(!openDropdown)
    }

    const helpMenuDropDownClickHandler = (index) => {
      setHelpMenuIndex(index) 
    }

    useEffect(() => {
      const handler = e => {
        if(!dropdownRef.current.contains(e.target)){
          setOpenDropdown(false)
        }
      }
      document.addEventListener('mousedown',handler)
    }) 
    
    return( 
      <>
        <HelpMenuDRPopup helpMenuIndex={helpMenuIndex} helpMenu={helpMenu} /> 
        <div ref={dropdownRef} className="dropdown">
          <div className="helpMenuIconContainer">
              <svg className="helpMenuIcon" onClick={helpMenuIconClickHandler}  width="40px" height="40px" viewBox="0 0 23 23" fill="white" data-html="true">
                  <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke-width="0" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9 9C9 5.49997 14.5 5.5 14.5 9C14.5 11.5 12 10.9999 12 13.9999" stroke={questionMarkColor} stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12 18.01L12.01 17.9989" stroke={questionMarkColor} stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
          </div>
          <div className={`helpMenuContentContainer dropdown-menu shadow ${openDropdown? "show" : ''}`} id="helpMenuDropdown">
              <div className="helpMenuHeading d-flex justify-content-between">
                      <div className="font-h3-semiBold">{helpMenu.header}</div>
                      <div className="cross">
                          <img src={CloseIcon} alt="closing icon" onClick={helpMenuIconClickHandler} className="cursor-pointer"/>
                      </div>
              </div>
              {helpMenu.data.map((item,index) => (
                <>
                {item.data.length>0?
                  <div className="helpMenuItemContainer d-flex" data-toggle="modal" data-target="#helpMenuModal" onClick={() => {helpMenuDropDownClickHandler(index)}}>
                      <div className="helpMenuItemIcon">
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="24" cy="24" r="24" fill="#E5F8FF"/>
                          <text x="50%" y="50%" text-anchor="middle" stroke="#5B6770" dy=".3em">{item.shortLable}</text>
                        </svg>
                      </div>
                      <div className="helpMenuItemText">
                        <div className="font-action-semiBold">{item.label}</div>
                        <div className="font-small-os">{item.description}</div>
                      </div>
                  </div>
                  :null
                }
                </>))
              }
          </div>
        </div>
        </>
    )
}

export default HelpMenuDR