import React, { useState, useEffect, useRef } from 'react';
import $, { data } from 'jquery'
import * as UrlConstants from '../../../constants/urls'
import { showSnackbar } from '../../common/utils/common'
import "../css/downloadReport.css"
import { DownloadSelectiveChapter } from './DownloadSelectiveChapterPopup';
import { withNamespaces } from 'react-i18next';
import { downloadPdf, resetHeaderState } from '../../../actions/headerAction/headerAction';
import { connect } from 'react-redux';

const DownloadReport = ({ chapterdetails, chapter_headers, t, isAdminRequest ,chapterParams ,pdf , downloadPdf , reset }) => {
    const [isAllSelected, setAllSelected] = useState(false);
    const [chapterHeaders,setChapterHeaders] = useState(chapter_headers?.filter(chapter => chapter.value !== 'YourFeedbackChapter').map(chapter => ({ ...chapter, active: false})));
    const [openDropdown, setOpenDropdown] = useState(false);
    const pdfAnchorRef = useRef(null)
    const dropdownRef = useRef();
    let clickHandler = {};

    useEffect(() => {
        if(pdf && !pdf.loading && pdf.success){
           onDownloadPdfSuccess()
        }else if(pdf && !pdf.loading && pdf.error){
           onDownloadPdfFailure()
        }
     },[pdf])

    const onDownloadPdfSuccess = () => {
        if (pdf.data) {
            pdfAnchorRef.current.click()
            setOpenDropdown(false)
            const tempIsAllSelected = false;
            let updatedReports = chapterHeaders.map(chapter => ({ ...chapter, active: tempIsAllSelected }));
            setAllSelected(tempIsAllSelected);
            setChapterHeaders(updatedReports);
        } else {
            showSnackbar("Error in generating PDF");
        } 
    }

    const onDownloadPdfFailure = () => {
        showSnackbar("Error in downloading PDF");
    }

    const downloadPdfHandler = () => {
        let selectedChapterNames = chapterHeaders.filter(chapter=>chapter.active).map(chapter => chapter.value);
        let additionalParams = chapterParams?.additionalParams && selectedChapterNames.length > 0 ? { ...chapterParams.additionalParams, chapters: selectedChapterNames } : chapterParams.additionalParams;
        let dataToSend = {
            locale: chapterParams.locale,
            reportId: chapterParams.reportId,
            isSvar: chapterParams.isSvar,
            testAttemptId: chapterParams.testAttemptId,
            additionalParams: additionalParams ? JSON.stringify(additionalParams) : null,
            source: chapterParams.source,
            sulId: chapterParams.sulId,
            outputFormat: 'pdf',
            isAdminRequest: isAdminRequest
        }
        chapterParams.amcatId ? dataToSend.amcatId = Number(chapterParams.amcatId) : dataToSend.testAttemptId = chapterParams.testAttemptId
        downloadPdf(dataToSend)
    }

    const toggleModal = () => {
        setOpenDropdown(!openDropdown)
    }

    useEffect(() => {
        const handler = e => {
          if(!dropdownRef.current.contains(e.target)){
            setOpenDropdown(false)
          }
        }
        document.addEventListener('mousedown',handler)
        return() => {
            document.removeEventListener('mousedown',handler)
            reset()
        }
    },[])

    const selectAll = () => {
        const tempIsAllSelected = !isAllSelected;
        let updatedReports = chapterHeaders.map(chapter => ({ ...chapter, active: tempIsAllSelected }));
        setAllSelected(tempIsAllSelected);
        setChapterHeaders(updatedReports)
    }

    const handleChange = (index) => {
        let updatedReports = [...chapterHeaders];
        let selectedIndex = updatedReports.findIndex(chapter => chapter.value === updatedReports[index].value);
        let isAlreadySelected = updatedReports[selectedIndex].active;
        updatedReports[selectedIndex].active = !isAlreadySelected;
        setChapterHeaders(updatedReports)
        const findInActive = updatedReports.filter(chapter => chapter.active === false);
        setAllSelected(findInActive.length > 0 ? false : true)
    }

    if (chapterdetails.downloadReport?.selectiveChapterDownload) {
        clickHandler['onClick'] = toggleModal;
        clickHandler['title'] = t("DOWNLOAD_REPORT")
        clickHandler['id'] = 'selectiveChapterDownload';
    } else {
        clickHandler['onClick'] = downloadPdfHandler;
    }

    return (
        <div ref={dropdownRef}>
            <div className="downloadReportSection flexContainer">
                <div className='downloadReportLabelDR'>
                </div>
                {
                    chapterdetails.downloadReport &&
                    <div className='downloadIconContainer'>
                        <a ref={pdfAnchorRef} href={pdf.data} target='_blank' className='d-none'></a>
                        <svg className="download-icon d-inline-block img-fluid" {...clickHandler} data-toggle="tooltip" data-placement="bottom" alt="icon" data-html="true" width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" fill='white' clipRule="evenodd" d="M18 36C27.9412 36 36 27.9412 36 18C36 8.05884 27.9412 0 18 0C8.05884 0 0 8.05884 0 18C0 27.9412 8.05884 36 18 36ZM18.5 24.0417L24.2068 18.335L23.1572 17.2854L19.2422 21.2004V8H17.7578V21.2004L13.8428 17.2854L12.7932 18.335L18.5 24.0417ZM9 27V25.5156H28V27H9Z"/>
                        </svg>
                    </div>
                }
            </div>
            {chapterdetails.downloadReport?.selectiveChapterDownload && <DownloadSelectiveChapter chapter_headers={chapterHeaders} toggleModal={toggleModal} selectAll={selectAll} handleChange={handleChange} isAllSelected={isAllSelected} downloadPdfHandler={downloadPdfHandler} openDropdown={openDropdown} isDownloadDisabled={pdf.loading} />}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        chapterParams:state.custom.chapterParams,
        pdf:state.header.pdf
    }
}

const mapDispatchToProps = (dispatch) => ({
    downloadPdf:(data) => {
        dispatch(downloadPdf(data))
    },
    reset:() => {
        dispatch(resetHeaderState())
    }
})

export default withNamespaces()(connect(mapStateToProps , mapDispatchToProps)(DownloadReport));