import React from 'react'
import { useEffect, useRef } from 'react';

export default function SearchBox(props) {
    const {handleChange, searchValue, isCandidateFound} = props;
    const placeholderText = "Ex. name, email"
    const inputRef = useRef(null)

    useEffect (()=>{
        if (!isCandidateFound) {
            inputRef.current.classList.add("input-error");
        } else {
            inputRef.current.classList.remove("input-error");
        }
    }, [isCandidateFound])

    return (
        <div className="inputBox d-flex align-items-center">
            <input 
                type="text"
                ref={inputRef} 
                onChange={handleChange} 
                value={searchValue} 
                id="myInput" 
                placeholder={placeholderText} 
                autoComplete="off"
            />
        </div>
    )
}
