/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import ArrowIcon from '../../../assets/images/arrow_white_down.svg';
import SearchIcon from '../../../assets/images/search.svg'
import NoItemFoundIcon from '../../../assets/images/no_search_item_found.svg'
import { makeGetRequest } from '../../common/utils/apis';
import * as UrlConstants from '../../../constants/urls'
import { connect } from 'react-redux';
import { getCandidateListFromReviewGrid, resetHeaderState } from '../../../actions/headerAction/headerAction';

const CandidateListDR = ({
    chapterParams,
    getCandidateListFromReviewGrid,
    recordsCount,
    selectedUser,
    candidateListFromReviewGrid,
    reset,
    t
}) => {

    const currentUserId = Number(chapterParams.sulId || chapterParams.testAttemptId) || ""
    const dropdownRef = useRef(null)
    const [componentState , setComponentState] = useState({
        usersList:[],
        message:false,
        showDropdown:false,
        searchItem:"",
        displayDropDown:false
    })

    useEffect(() => {
        if(candidateListFromReviewGrid.success) {
            setComponentState(prev => ({
                ...prev,
                showDropdown:true
            }))
            if(candidateListFromReviewGrid.data.length>0){
                setComponentState(prev => ({
                    ...prev,
                    message:false,
                    usersList:candidateListFromReviewGrid.data
                }))
            }else {
                setComponentState(prev => ({
                    ...prev,
                    message:true,
                    usersList:[]
                }))
            }
        }else if(candidateListFromReviewGrid.error) {
            setComponentState(prev => ({
                ...prev,
                showDropdown:false
            }))
        }
    },[candidateListFromReviewGrid])

    const toggleModal = () => {
        setComponentState(prev => ({...prev , displayDropDown:!prev.displayDropDown}));
    }

    const verifyEnter=(event) =>{
        if(event.keyCode === 13)
            getCandidateList(componentState.searchItem)
    }

    const getCandidateList=(searchText="")=>{
        let additionalParams= chapterParams.additionalParams;
        let locale = chapterParams.locale;
        locale = locale.split('-').join('_').toLowerCase();
        let requestBody = {
            uniqueRefId: additionalParams.uniqueRefId || '',
            projectId: additionalParams.projectId || '',
            componentId: additionalParams.componentId || '',
            locale,
            sulId: chapterParams.testAttemptId,
            pageSize: searchText?recordsCount:5,
            pageNumber : 1,
            searchText: searchText, //will search in first name , last name and email if
            url: additionalParams.pipeBaseUrl+UrlConstants.CANDIDATE_LIST_URL_ADDITION || ''
        }
        getCandidateListFromReviewGrid(requestBody)
    }

    const dropDownHandle=(e)=>{
            if(dropdownRef.current!=null && componentState.displayDropDown &&  !dropdownRef.current.contains(e.target)){
                setComponentState(prev => ({...prev , displayDropDown:false }))
            }
    }

    const crossSearch = () => {
        setComponentState(prev => ({...prev , searchItem:""}))
        getCandidateList()
    }

    useEffect(()=>{
        getCandidateList()
        document.body.addEventListener('click', (e)=>{
        dropDownHandle(e)});
        return () =>{
            document.body.removeEventListener("click", dropDownHandle)
            reset()
        }
    },[])

    return (
        componentState.showDropdown?
            <div ref={dropdownRef} className="ml-3 mr-1 d-flex  align-items-center ">
                {/* <span className="pl-3 pr-2 m-0 candidatePosition"><span className="currentIndex m-0">{currentIndex}</span>/{recordsCount}</span> */}
                <div className={`dropdown show dropdown-arrow`} >
                    <div className="dropdown-toggle" id="dropdownMenuLink" aria-haspopup="true" aria-expanded="false" onClick={toggleModal}>
                        <img className={`${componentState.displayDropDown ? 'rotate' :''}`} src={ArrowIcon} alt="down arrow" id="imgToRotate" />
                    </div>

                    <div id="showDropdown" className={`dropdown-menu shadow border-0 rounded userlist-dropdown ${componentState.displayDropDown ? 'show' :'' }`} aria-labelledby="dropdownMenuLink">
                        <div id="inputBox" className="candidate-search-box d-flex align-items-center">
                            <input type="text" onChange={(e)=>setComponentState(prev => ({...prev ,searchItem:e.target.value}))} placeholder={t("SEARCH_CANDIDATE")} value={componentState.searchItem} id="myInput" className="form-control" onKeyUp={(e)=>verifyEnter(e)} />
                            {componentState.searchItem ?
                                <button  type="button" className="close" aria-label="Close" onClick={crossSearch}>
                                    <span id="crossSearch" aria-hidden="true">&times;</span>
                                </button>
                            :null}
                            {!componentState.searchItem ?
                                <img src={SearchIcon} onClick={()=>getCandidateList(componentState.searchItem)} className={componentState.searchItem ? 'search-btn':'search-btn1'} id="searchButton" alt="Search" />
                            :null}
                            </div>
                        <div className="userlist-dropdown-inner " id="myDropdown">
                            {componentState.message?
                                <div className="info-container">
                                    <img src={NoItemFoundIcon} className="info-icon" alt="icon" />
                                </div>
                                :componentState.usersList?.length && componentState.usersList.map((user, index) => (
                                    <button
                                        key={index}
                                        className={`btn rounded-0 dropdown-item d-flex flex-column option ${Number(user.sulId) === currentUserId?" current":""}`}
                                        onClick={()=>selectedUser(user)} >
                                        <span className="name">{user.firstName} {user.lastName}</span>
                                        <span className="email">{user.email}</span>
                                    </button>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        :null
    )
}

const mapStateToProps = (state:any) => ({
    chapterParams:state.customState.chapterParams,
    candidateListFromReviewGrid:state.customState.candidateListFromReviewGrid
})

const mapDispatchToProps = (dispatch:any) => ({
    getCandidateListFromReviewGrid:(data) => {
        dispatch(getCandidateListFromReviewGrid(data))
    },
    reset:() => {
        dispatch(resetHeaderState())
    }
})

export default withNamespaces()(connect(mapStateToProps , mapDispatchToProps)(CandidateListDR))