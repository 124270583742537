import React from 'react'
import { Trans } from 'react-i18next';
import ErrorAM from '../../../assets/images/error-old.png'

const ReportError=(props)=>{
    return(
        <React.Fragment>
            <div className="error-head">
                <div>
                    <span className="text">
                        Candidate Name
                    </span>
                </div>
                <div className="id" style={{marginTop: "3px",fontSize: "12px"}}>
                    {props.AMCAT_ID?
                        <span className="text">
                            AMCAT ID : {props.AMCAT_ID}
                        </span>
                    :null
                    }
                </div>
            </div>
            <center className='error-body'>
                <img src={ErrorAM} alt="icon"/>
                <div className='block1'><Trans>REPORT_NOT_LOADED</Trans>!</div>
            </center>
        </React.Fragment>
    )
}
export default ReportError