import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next';

class Mcq extends Component {
    constructor(props){
        super(props)
    }
    render(){
        let module = this.props.module
        let incorrectIndexes = module?.detailedResponse?.DR_QuestionsReport?.incorrectIndexes
        return(
            module?.detailedResponse &&
            <div className="module_container_detailed_response" style={{display:'block'}}>
                <div className="web_cont d-none d-sm-block">      
                    {module.detailedResponse.DR_QuestionsReport?
                        <div className="mod_data_item detailed_score_report">
                            <div className="gradient_heading">
                                {module.detailedResponse.DR_QuestionsReport.header}
                            </div>
                            <div className="data_section">
                                <div className="data_list flexibleTable">    
                                {Object.entries(module.detailedResponse.DR_QuestionsReport.data).map(([key, item])=>{
                                    return(
                                        <div className="tbl_row flexContainer">
                                            {item.task && item.level?
                                                <React.Fragment>
                                                    <div className="data_col_1 heading_item">{item.index?item.index:key}</div>
                                                    <div className={`data_col_2 heading_item ${(incorrectIndexes.find(e=>e==key) || (key==0 && incorrectIndexes[0]==0))?'incorrect_status':null}`}>{item.status}</div>
                                                    <div className="data_col_3 heading_item">{item.task}</div>
                                                    <div className="data_col_4 heading_item">{item.topic}</div>
                                                    <div className="data_col_5 heading_item">{item.level}</div>
                                                    <div className="data_col_6 heading_item">{item.time}</div>
                                                </React.Fragment>
                                                :!item.task?
                                                    <React.Fragment>
                                                        <div className="dataCol1 heading_item">{item.index?item.index:key}</div>
                                                        <div className={`dataCol2 heading_item ${(incorrectIndexes.find(e=>e==key) || (key==0 && incorrectIndexes[0]==0))?'incorrect_status':null}`}>{item.status}</div>
                                                        <div className="dataCol3 heading_item">{item.topic}</div>
                                                        <div className="dataCol4 heading_item">{item.level}</div>
                                                        <div className="dataCol5 heading_item">{item.time}</div>
                                                    </React.Fragment>
                                                    :!item.level?
                                                        <React.Fragment>
                                                            <div className="dataCol1 heading_item">{item.index?item.index:key}</div>
                                                            <div className={`dataCol2 heading_item ${(incorrectIndexes.find(e=>e==key) || (key==0 && incorrectIndexes[0]==0))?'incorrect_status':null}`}>{item.status}</div>
                                                            <div className="dataCol4 heading_item">{item.task}</div>
                                                            <div className="dataCol3 heading_item">{item.topic}</div>
                                                            <div className="dataCol5 heading_item">{item.time}</div>
                                                        </React.Fragment>
                                                        :null
                                            }
                                        </div>
                                    )
                                })}    
                                </div>
                            </div>
                        </div>  
                    :null}
                    {module.detailedResponse.DR_McqQuestionStats?
                        <div className="mod_data_item question_level_stats">
                            <div className="gradient_heading">
                                {module.detailedResponse.DR_McqQuestionStats.header}
                            </div>
                            <div className="data_section">
                                <div className="data_list ">    
                                    {Object.values(module.detailedResponse.DR_McqQuestionStats.data).map((item)=>{
                                        return(
                                            <div className="tbl_row flexContainer">
                                                <div className="level">{item.level}</div>
                                                <div className="total">{item.total}</div>
                                                <div className="correct">{item.correct}</div>
                                                <div className="percent">{item.percent}</div>
                                            </div>
                                        )
                                    })}    
                                </div>
                            </div>
                        </div>  
                    :null}
                    {module.detailedResponse.DR_McqTopicStats?
                        <div className="mod_data_item question_topic_stats">
                            <div className="gradient_heading">
                                {module.detailedResponse.DR_McqTopicStats.header}
                            </div>
                            <div className="data_section">
                                <div className="data_list">    
                                    {Object.values(module.detailedResponse.DR_McqTopicStats.data).map((item)=>{
                                        return(                                    
                                            <div className="tbl_row flexContainer">
                                                <div className="topic">{item.topic}</div>
                                                <div className="total">{item.total}</div>
                                                <div className="correct">{item.correct}</div>
                                                <div className="percent">{item.percent}</div>
                                            </div>
                                        )
                                    })}    
                                </div>
                            </div>
                        </div> 
                    :null}
                </div>
                <div className="mobile_cont d-block d-sm-none">
                    {module.detailedResponse.DR_QuestionsReport?
                        <div className="mod_data_item_mobile detailed_score_report">
                            <div className="gradient_heading_mobile">
                                {module.detailedResponse.DR_QuestionsReport.header}
                            </div>
                            <div className="data_section_rows_mobile">
                                {Object.entries(module.detailedResponse.DR_QuestionsReport.data).map(([key, item])=>{
                                    return(
                                        key > 0?
                                            <div className="tbl_row_mobile row">
                                                <div className="col-6 tbl_row_item_box flexContainer">
                                                    <div className="label">
                                                        {module.detailedResponse.DR_QuestionsReport.data[0].index}<span>:</span>
                                                    </div>
                                                    <div className="value">
                                                        {key}
                                                    </div>
                                                </div>
                                                <div className="col-6 tbl_row_item_box flexContainer">
                                                    <div className="label">
                                                        {module.detailedResponse.DR_QuestionsReport.data[0].status}<span>:</span>
                                                    </div>
                                                    <div className={`value ${module.detailedResponse.DR_QuestionsReport.incorrectIndexes.find(e=>e==key)?'incorrect_status':null}`}>
                                                        {item.status}
                                                    </div>
                                                </div>
                                                {module.detailedResponse.DR_QuestionsReport.data[0].task?
                                                    <div className="col-6 tbl_row_item_box flexContainer">
                                                        <div className="label">
                                                            {module.detailedResponse.DR_QuestionsReport.data[0].task}<span>:</span>
                                                        </div>
                                                        <div className="value">
                                                            {item.task}
                                                        </div>
                                                    </div>
                                                :null}
                                                <div className="col-6 tbl_row_item_box flexContainer">
                                                    <div className="label">
                                                        {module.detailedResponse.DR_QuestionsReport.data[0].topic}<span>:</span>
                                                    </div>
                                                    <div className="value">
                                                        {item.topic}
                                                    </div>
                                                </div>
                                                {module.detailedResponse.DR_QuestionsReport.data[0].level?
                                                    <div className="col-6 tbl_row_item_box flexContainer">
                                                        <div className="label">
                                                            {module.detailedResponse.DR_QuestionsReport.data[0].level}<span>:</span>
                                                        </div>
                                                        <div className="value">
                                                            {item.level}
                                                        </div>
                                                    </div>
                                                :null}
                                                <div className="col-6 tbl_row_item_box flexContainer">
                                                    <div className="label">
                                                        {module.detailedResponse.DR_QuestionsReport.data[0].time}<span>:</span>
                                                    </div>
                                                    <div className="value">
                                                        {item.time}
                                                    </div>
                                                </div>
                                            </div>
                                        :null
                                    )  
                                })} 
                            </div>
                        </div> 
                    :null}
                    {module.detailedResponse.DR_McqQuestionStats?
                        <div className="mod_data_item_mobile question_level_stats">
                            <div className="gradient_heading_mobile">
                                {module.detailedResponse.DR_McqQuestionStats.header}
                            </div>
                            <div className="data_section_rows_mobile">
                                {Object.entries(module.detailedResponse.DR_McqQuestionStats.data).map(([key, item])=>{
                                    return(
                                        key>0?
                                            <div className="tbl_row_mobile row">
                                                <div className="col-6 tbl_row_item_box flexContainer">
                                                    <div className="label">{module.detailedResponse.DR_McqQuestionStats.data[0].level}<span>:</span></div>
                                                    <div className="value">{item.level}</div>
                                                </div>
                                                <div className="col-6 tbl_row_item_box flexContainer">
                                                    <div className="label">{module.detailedResponse.DR_McqQuestionStats.data[0].total}<span>:</span></div>
                                                    <div className="value">{item.total}</div>
                                                </div>
                                                <div className="col-6 tbl_row_item_box flexContainer">
                                                    <div className="label">{module.detailedResponse.DR_McqQuestionStats.data[0].correct}<span>:</span></div>
                                                    <div className="value">{item.correct}</div>
                                                </div>
                                                <div className="col-6 tbl_row_item_box flexContainer">
                                                    <div className="label">{module.detailedResponse.DR_McqQuestionStats.data[0].percent}<span>:</span></div>
                                                    <div className="value">{item.percent}</div>
                                                </div>
                                            </div>   
                                        :null
                                    )    
                                })}    
                            </div>
                        </div>  
                    :null}
                    {module.detailedResponse.DR_McqTopicStats?
                        <div className="mod_data_item_mobile question_topic_stats">
                            <div className="gradient_heading_mobile">
                                {module.detailedResponse.DR_McqTopicStats.header}
                            </div>
                            <div className="data_section_rows_mobile">
                                {Object.entries(module.detailedResponse.DR_McqTopicStats.data).map(([key, item])=>{
                                    return(
                                        key>0?
                                            <div className="tbl_row_mobile row">
                                                <div className="col-6 tbl_row_item_box flexContainer">
                                                    <div className="label">{module.detailedResponse.DR_McqTopicStats.data[0].topic}<span>:</span></div>
                                                    <div className="value">{item.topic}</div>
                                                </div>
                                                <div className="col-6 tbl_row_item_box flexContainer">
                                                    <div className="label">{module.detailedResponse.DR_McqTopicStats.data[0].total}<span>:</span></div>
                                                    <div className="value">{item.total}</div>
                                                </div>
                                                <div className="col-6 tbl_row_item_box flexContainer">
                                                    <div className="label">{module.detailedResponse.DR_McqTopicStats.data[0].correct}<span>:</span></div>
                                                    <div className="value">{item.correct}</div>
                                                </div>
                                                <div className="col-6 tbl_row_item_box flexContainer">
                                                    <div className="label">{module.detailedResponse.DR_McqTopicStats.data[0].percent}<span>:</span></div>
                                                    <div className="value">{item.percent}</div>
                                                </div>
                                            </div>
                                        :null  
                                    )
                                })}    
                            </div>
                        </div>   
                    :null}
                </div>
            </div>
        )
    }
}
export default withNamespaces()(Mcq)