const initialState = {
  chapterData: {
    data: {
      header: "",
      mainSection: {
        header: {
          title: "",
          description: ""
        },
        toolTip: {},
        narratives: {},
        score: {}
      },
      ucfSection: {},
      overallSection: {
        header: {
          title: ""
        },
        description: "",
        scoreRating: {},
        mainScore: {}
      }
    },
    loading: false,
    success: false,
    error: false
  }
}

export default initialState;