//following value can be live or dev only
//export const ASSETS_BASE_URL= 'https://tcplusstg-report-frontend.shl.zone/'

// iAssess backend base URL
//export const IASSESS_BASE_URL='https://stagingreport.myamcat.com/'
export const IASSESS_BASE_URL='https://backendemployer.myamcat.com/'

// Reportica backend url
export const REPORTICA_BASE_URL=''

// iAssess login page (home page)
export const LOGIN_PAGE_URL= 'https://myamcat.com/assessments/'

export const APPLICATION_ENVIRONMENT='live'

// Following value includes the default locale / langauge
export const DEFAULT_LANGUAGE='en-US'

export const PARTICIPANT_NOT_MATCHED_FLAG = 0;

export const URL_TRANSFORM_MAP = {
	"https://report.myamcat.com" : {
		"IASSESS_BASE_URL" : "https://backendemployer.myamcat.com/",
		"LOGIN_PAGE_URL" : "https://myamcat.com/assessments/",
		"REPORTICA_BASE_URL" : "https://reportbackend.myamcat.com"
	},
	"https://report.talentcentral.us.shl.com" : {
		"IASSESS_BASE_URL" : "https://admin-api.talentcentral.us.shl.com/",
		"LOGIN_PAGE_URL" : "https://admin.talentcentral.us.shl.com/",
		"REPORTICA_BASE_URL" : "https://report-api.talentcentral.us.shl.com"
	}
}