import { HeaderApi } from "../../apis/headerApi";
import actionType from "./headerActionType";
const headerApi = new HeaderApi()

export const downloadPdf = (data) => {
    return headerApi.downloadPdf(data, _downloadPdf, downloadPdfSuccess, downloadPdfFailure)
}

export const _downloadPdf = () => {
    return {
        type: actionType.DOWNLOAD_PDF
    }
}

export const downloadPdfSuccess = (data) => {
    let payload=data?.data?.report
    return {
        type: actionType.DOWNLOAD_PDF_SUCCESS,
        payload: payload
    }
}

export const downloadPdfFailure = (data) => {
    return {
        type: actionType.DOWNLOAD_PDF_FAILURE,
        payload: data
    }
}

export const getCandidateList = (parameter) => {
    return headerApi.getCandidateList(parameter, _getCandidateList, getCandidateListSuccess, getCandidateListFailure)
}

export const _getCandidateList = () => {
    return {
        type: actionType.GET_CANDIDATE_LIST
    }
}

export const getCandidateListSuccess = (data) => {
    return {
        type: actionType.GET_CANDIDATE_LIST_SUCCESS,
        payload: data
    }
}

export const getCandidateListFailure = (data) => {
    return {
        type: actionType.GET_CANDIDATE_LIST_FAILURE,
        payload: data
    }
}

export const getCandidateListByEvaluator = (data) => {
    return headerApi.getCandidateListByEvaluator(data, _getCandidateListByEvaluator, getCandidateListByEvaluatorSuccess, getCandidateListByEvaluatorFailure)
}

export const _getCandidateListByEvaluator = () => {
    return {
        type: actionType.GET_CANDIDATE_LIST_BY_EVALUATOR
    }
}

export const getCandidateListByEvaluatorSuccess = (data) => {
    let payload = Object.keys(data)
    payload.forEach((item,index)=>payload[index] = Number(payload[index]))
    return {
        type: actionType.GET_CANDIDATE_LIST_BY_EVALUATOR_SUCCESS,
        payload: payload
    }
}

export const getCandidateListByEvaluatorFailure = (data) => {
    return {
        type: actionType.GET_CANDIDATE_LIST_BY_EVALUATOR_FAILURE,
        payload: data
    }
}

export const getNeighboursList = (data) => {
    return headerApi.getNeighboursList(data , _getNeighboursList , getNeighboursListSuccess , getNeighboursListFailure)
}

export const _getNeighboursList = () => {
     return {
        type:actionType.GET_NEIGHBOURS_LIST
     }
}

export const getNeighboursListSuccess = (data) => {
    return {
       type:actionType.GET_NEIGHBOURS_LIST_SUCCESS,
       payload:data
    }
}

export const getNeighboursListFailure = (data) => {
    return {
       type:actionType.GET_NEIGHBOURS_LIST_FAILURE,
       payload:data
    }
}

export const updateCandidateListByEvaluator=(data) => {
    return {
        type:actionType.UPDATE_CANDIDATE_LIST_BY_EVALUATOR,
        payload:data
    }
}

export const getCandidateListFromReviewGrid = (parameter) => {
    return headerApi.getCandidateListFromReviewGrid(parameter, _getCandidateListFromReviewGrid, getCandidateListFromReviewGridSuccess, getCandidateListFromReviewGridFailure)
}

export const _getCandidateListFromReviewGrid = () => {
    return {
        type: actionType.GET_CANDIDATE_LIST
    }
}

export const getCandidateListFromReviewGridSuccess = (data) => {
    return {
        type: actionType.GET_CANDIDATE_LIST_SUCCESS,
        payload: data.data
    }
}

export const getCandidateListFromReviewGridFailure = (data) => {
    return {
        type: actionType.GET_CANDIDATE_LIST_FAILURE,
        payload: data
    }
}

export const resetHeaderState = () => {
    return {
        type:actionType.RESET_HEADER_STATE
    }
}