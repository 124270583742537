import React from 'react';
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

export default function ScoreBand ({arr, id, productBandInfo, maxScore, chapterValue, scoreType = ''}) {
    const productBandObj =productBandInfo ? productBandInfo : arr.filter(item => item.productAssociationId === id)[0];
    function scoreBandLabelHandler (arr, id, maxScore) {
        const bandArr =  bandArrHandler(arr, id);        
        const bandObj = bandArr?.find(item => item.benchmarkId === productBandObj?.finalBenchMarkId)
        let label='-'
        if(bandObj) {
            label=bandObj.benchmarkName
        }else {
            console.error('benchmark id -',productBandObj?.finalBenchMarkId ,' is missing in the product band array -' , bandArr)
        }
        return label;
    }

    function bandArrHandler (arr, id) {
        const bandArr = productBandObj?.bands;
        if(!bandArr){
            return bandArr
        }
        bandArr.sort((a,b) => a.threshold - b.threshold)

        for (let i = 0; i < bandArr.length; i++) {
            if(i === 0) {
                bandArr[i].scoreRange = `0-${bandArr[i+1].threshold}`;
            } else if(i !== 0 && i < bandArr.length - 1) {
                bandArr[i].scoreRange = `${bandArr[i].threshold + 1}-${bandArr[i+1].threshold}`;
            } else if(i !== 0 && i === bandArr.length - 1) {
                bandArr[i].scoreRange = `${bandArr[i].threshold + 1}-99`;
            }
        }

        return bandArr;
    }

    function getScoreBandTooltip () {
        const bandArr = bandArrHandler(arr,id);
        const toolTipDom = bandArr?.map(item=>{
            return (<div class="band-tooltip-container">{ item.bandColor ?  <span class="band-bullet" style={{backgroundColor:item.bandColor}} ></span> : null }<b>{item?.scoreRange}</b> {scoreType} : {item?.benchmarkName||'-'}</div>)
        })
        return toolTipDom
    }

  return (
        <OverlayTrigger
             placement='top'
             overlay={(props) => (
              <Tooltip id="band-tooltip" {...props} >
                 <div>
                   {getScoreBandTooltip()}
                 </div>
              </Tooltip>
              )}
            >
            <div className={chapterValue === "SummaryChapter" ? 'scoreBand-container-inline d-inline' : 'scoreBand-container'}>
              <span className='font-small-os'>{scoreBandLabelHandler(arr, id, maxScore)}</span>
            </div>
        </OverlayTrigger>
  )
}
