import actionType from "../../actions/customAction/customActionType"
import initialState  from "./customState"

export const customReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.UPDATE_HIREABLITY_STATUS: {
            return {
                ...state,
                hireabilitydata: { ...state.hireabilitydata, ...action.payload }
            }
        }
        case actionType.SET_TOTAL_MODULE_SCORES: {
            return {
                ...state,
                totalModuleScores: action.payload
            }
        }
        case actionType.UPDATE_TOTAL_MODULE_SCORES: {
            return {
                ...state,
                totalModuleScores: action.payload
            }
        }
        case actionType.UPDATE_MANUAL_VADC_SCALE_NARRATIVES: {
            return {
                ...state,
                vadcScaleNarratives: action.payload
            }
        }
        case actionType.TOASTENABLE: {
            return {
                ...state,
                toastDetails: action.payload
            }
        }
        case actionType.SET_CHAPTER_PARAMS: {
            return {
                ...state,
                chapterParams : action.payload
            }
        }
        default:
            return state
    }

}

export default customReducer;