import React,{useState,useEffect} from 'react'
import * as UrlConstants from '../../../constants/urls'
import {htmlDecode} from './common'
import { Trans} from 'react-i18next';
import ScoreBand from '../components/ScoreBand';

export const getHexCode=(score, colorScale, codeType = 0)=>{
    score = score === null ? 0 : Math.round(score)
    let colorCode=''
    let colorScaleDetails= colorScale['details']
    for (let details of colorScaleDetails){
        if (score >= details['min'] && score <= details['max'] ){
            if (codeType === 0){
                colorCode = details.primary
            }else{
                colorCode = details.secondary
            }
            
        }
    }
    return colorCode
}
export const wavingpotgraph=(perc,colorScale)=> {
    let percent = perc > 95 ? 95 : perc
    let hexCode = getHexCode(Math.round(percent),colorScale)
    let secondaryHexCode = getHexCode(Math.round(percent), colorScale,1)
    return (
        <div className="box">
            <div className="percent" data-percent={Number(percent)}></div>
            <div id="water" className="water" style={{background:hexCode}}>
                
                <div className="water_wave water_wave_back">
                    <svg height="4.64" width="130" viewBox="0 0 560 20" fill={secondaryHexCode}>
                        <use xlinkHref="#wave"></use>
                    </svg>
                </div>
                <div className="water_wave water_wave_front">
                    <svg height="4.64" width="130" viewBox="0 0 560 20" fill={hexCode}>
                        <use xlinkHref="#wave"></use>
                    </svg>
                </div>
            </div>
        </div>
    )
}
export const displayThreshold=(data,header)=> {
    return (
        <div>
            {data.thresholdScore && data.status && data.thresholdScore && data.status?
                <div className="threshold font-semibold">
                    <span className="threshold-header font-regular">{header}: </span>
                    {data.thresholdScore}
                    <span className="font-regular"> | </span> 
                    {data.status}
                </div>    
            :null}
        </div>
    )
}
export const wavingpotsvg =()=>{
    return(
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" style={{display: 'none'}}>
            <symbol id="wave">
                <path d="M420,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C514,6.5,518,4.7,528.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H420z"></path>
                <path d="M420,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C326,6.5,322,4.7,311.5,2.7C304.3,1.4,293.6-0.1,280,0c0,0,0,0,0,0v20H420z"></path>
                <path d="M140,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C234,6.5,238,4.7,248.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H140z"></path>
                <path d="M140,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C46,6.5,42,4.7,31.5,2.7C24.3,1.4,13.6-0.1,0,0c0,0,0,0,0,0l0,20H140z"></path>
            </symbol>
        </svg>
    )
}
export const circleIcon=(percent,colorScale)=>{
    let hexCode = getHexCode(Math.round(percent),colorScale)
    return(
        <svg fill={hexCode} xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13.5 13.5">
            <path id="Path_1098" data-name="Path 1098" d="M320.036,385.291a6.384,6.384,0,1,1,6.384-6.384A6.391,6.391,0,0,1,320.036,385.291Zm0-9.595a3.211,3.211,0,1,0,3.211,3.211A3.215,3.215,0,0,0,320.036,375.7Z" transform="translate(-313.652 -372.523)" />
        </svg>
    )
}
export const CEFRheader = (CEFR, color)=>{
    if (CEFR.infoText) {
        return (
            <>
            {CEFR.plagPercentage!= null && CEFR.plagPercentage?.length ? 
            <div className="CEFRheader flexContainer">
                <div className="similarity-detected">
                    {CEFR?.infoText}
                </div>
                <div className="plag-value">
                    {CEFR?.plagPercentage}
                </div>
            </div>
            : null}
            </>
        )
    }
    //else if(CEFR.value === null){
    //     return null
    // }
    else {
        return (
            <div className="CEFRheader flexContainer">
                <span className="CEFRlabel">{CEFR?.label}: </span>
                {!color? 
                    <span className="CEFRname">{CEFR?.displayName}</span>:
                    <span className="CEFRname" style={{color:color}}>{" "+CEFR?.displayName}</span>
                }
            </div>
        )
    }
}
export const horgraph=(percent, colorScale, hsl, circleIconVar = true, subcompetency)=>{
    let hexCode = getHexCode(Math.round(percent),colorScale)
    return(
        <>
        {subcompetency != "Typing Speed"?
            <div className="progress prog-con d-flex align-items-center">
                <div className="progress-bar hpertge" style={{background: hsl ? hsl : hexCode, width:percent+"%"}} data-percent={percent+"%"}></div>
                {percent >= 0 && circleIconVar?circleIcon(percent,colorScale):null}
            </div>:null
        }
        </>       
    )
}
export const cutoffScores=(colorScale)=>{
    return(
        <div className="colorScale flexContainer" key='cutOffScores'>
            {colorScale.details.reverse().map((details)=>{
                return(
                    <div className="details" key={details.max}>
                        <div className="color" style={{backgroundColor:details.primary}}></div>
                        <div className="text font-regular">{details.name} ({details.min} - {details.max})</div>
                    </div>
                )
            })}
        </div>
    )
}
const scoreSuffix = (score) => {
    return["st","nd","rd"][((score+90)%100-10)%10-1]||"th";
}
export const subSectionCategory = (subsectionScores, colorScale, header = "", NA="NA" , subSectionBandInfo)=>{
    let subSectionData =[]
    let percent=0    
    const getSubSectionBand = (subSection) => {
         let bandObj=subSectionBandInfo.find(item => item.subSectionName==subSection.label.trim())
         return (
            <div className='ml-2'>
                <ScoreBand arr={[]} id={""} productBandInfo={bandObj} scoreType={bandObj?.scoreType}  />
            </div>
         )
    }
    function displayTextWidth(text) {
        let canvas = displayTextWidth.canvas || (displayTextWidth.canvas = document.createElement("canvas"));
        let context = canvas.getContext("2d");
        context.textSize = "9px";
        let metrics = context.measureText(text);
        return Math.round(metrics.width);
      }

    if (subsectionScores.data){
        for (let subSection of subsectionScores.data){
            let isProgressBar = (subSection.progressBar !== undefined && subSection.progressBar !== null)? subSection.progressBar : 1
            {subSection.maxScore>0?
                percent = subSection.value / subSection.maxScore * 100
                :percent = subSection.value
            }
            subSectionData.push(
                <div className={isProgressBar === 0?'subsec flexed':'subsec'}>
                    <div className="name">{subSection.label}</div>
                    {isProgressBar === 0 ?
                        subSection.value < 0?
                            <div className="value">{NA}</div>
                        :     
                            <div className="scoreNum"> 
                                <span className="value">{subSection.value}</span>
                                { 
                                displayTextWidth(subSection.unit) > 50 ? 
                                <div className="max-score" style={{marginLeft: "8px",width: "90px",textAlign: "left"}}>{subSection.unit}</div> 
                                : 
                                <span className="max-score">{subSection.unit}</span>
                                }
                                { !!subSectionBandInfo ? getSubSectionBand(subSection) : null}
                            </div>
                    :
                    <div className="score flexContainer">                        
                        <div className="pgraph">{horgraph(percent,colorScale,subSection.hsl)}</div>
                        <div className="scoreNum">
                            {subSection.value < 0 ?
                                <span className="obtained-score font-h4-semiBold">{NA}</span>
                            :
                                <span>
                                    <span className="obtained-score font-h4-semiBold">{subSection.value}</span>{subsectionScores.value=='NA'?
                                        <span className="obtained-score-percentile font-h4-semiBold"> <Trans>SHORT_PERCENTILE</Trans>
                                        </span>
                                    :
                                        ''
                                    }
                                    {subSection.unit?
                                        <span className="max-score font-h4">{subSection.unit}</span>
                                    :
                                    subsectionScores.value!='NA'?<span className="max-score font-h4">/{subSection.maxScore}</span>:''
                                    } 
                                </span>
                            }
                          {!!subSectionBandInfo ? getSubSectionBand(subSection) : null}
                        </div>
                    </div>
                    }
                    {displayThreshold(subSection, header)}
                </div>
            )
            
            
        }
    }
        
    return subSectionData
}
export const urSubSectionCategory = (subsectionScores, colorScale, header = "", NA="NA", urSubSectionCategoryData)=>{
    let subSectionData =[]
    let percent=0
    if (subsectionScores){
        for (let subSection of subsectionScores){
            let isProgressBar = (subSection.progressBar !== undefined && subSection.progressBar !== null)? subSection.progressBar : 1
            percent = subSection.score
            
            subSectionData.push(
                <div className={isProgressBar === 0?'subsec flexed':'subsec'}>
                    <div className="name">{subSection.scoreName}</div>
                    <div className="score flexContainer">                        
                        <div className="pgraph">{horgraph(percent, colorScale, "", false)}</div>
                        <div className="scoreNum">
                            {subSection.score < 0 ?
                                <span className="obtained-score font-h4-semiBold">{NA}</span>
                            :
                                <span>
                                    <span className="obtained-score font-h4-semiBold">{subSection.score}</span>{subSection.scoreUnit?<span className='score-super-suffix'>{scoreSuffix(subSection.score)}</span>:null}
                                    {subSection.scoreUnit?
                                        <span className="max-score font-h4">{' '+subSection.scoreUnit}</span>
                                    :
                                    <span className="max-score font-h4">/{subSection.maxScore}</span>
                                    }
                                </span>
                            }
                        </div>
                    </div>
                    {displayThreshold(subSection, header)}
                </div>
            )
            
            
        }
    }
        
    return subSectionData
}
/*This section returns the color and min/max values for radar graph */
export const createSeriesRange=(colorScale)=>{
}
export const temperatureGraphSolid=(fill,total, colorScale)=>{
    let hexCode = getHexCode(Math.round((fill*100)/total) - 1, colorScale)
    let rederMarkedTemperature =[]
    let rederUnmarkedTemperature =[]
    for(let i=0; i<fill;i++ ){
        rederMarkedTemperature.push(<div key={"temp-block-"+i} className="temprature-block-marked"></div>)
    }
    for(let i=fill; i<total;i++ ){
        rederUnmarkedTemperature.push(<div key={"temp-block-unmarked-"+i} className="temprature-block-marked temprature-block-unmarked"></div>)
    }
    return(
        <div className="temp-graph-container">
            <div className="solid-temp-fill" style={{background:hexCode}}>
            {rederMarkedTemperature}
            {rederUnmarkedTemperature}
            </div>
        </div>
    )
    
}
export const generateCustomTraitsSection=(dataVars, colorScale)=>{
    let renderData=[]    
    dataVars.data.forEach((item)=>{
        renderData.push(
            <div className="item">
                <div className="name">                    
                    {item.label}
                </div>
                <div className="scoreGraph">
                    {temperatureGraphSolid(item.value,item.maxScore, colorScale)}
                </div>
            </div>
        )
    })
    
    return(<div className="additionalInfo">
        <div className="itemList">
            {renderData}
        </div>
    </div>
    )
}
export const bubbleIcon=(percent, colorScale)=>{
    let hexCode = getHexCode(Math.round(percent), colorScale)
    return(
        <svg fill={hexCode} xmlns="http://www.w3.org/2000/svg" width="18.999" height="23.514" viewBox="0 0 18.999 23.514" >
            <path id="Path_1062" data-name="Path 1062" d="M1758.83,743.538c0,5.247-9.5,14.015-9.5,14.015s-9.5-8.768-9.5-14.015a9.5,9.5,0,0,1,19,0Z" transform="translate(-1739.831 -734.039)" />
        </svg>
    )
}
export const generateProgressBar=(data, colorScale, NA = 'NA')=>{
    let progressBar = []
    for(let key in data.data){
        let item = data.data[key]
        let percent = item.value / item.maxScore * 100
        progressBar.push(
            <div className="item">
                <div className="name">
                    {item.label}
                </div>
                <div className="score flexContainer">
                    <div className="pgraph">{horgraph(percent ,colorScale, item.hsl)}</div>
                    <div className="scoreNum">
                        {item.value < 0?
                            <span className="obtained-score font-h4-semiBold">{NA}</span>
                            :
                            <React.Fragment>
                                <span className="obtained-score font-h4-semiBold">{item.value}</span>
                                <span className="max-score font-h4">/{item.maxScore}</span>
                            </React.Fragment>
                        }    
                    </div>    
                </div>
            </div>
        )
    }
    return(
        <div className="progressBar-container">{progressBar}</div>
    )
}
export const completeGraph=(graphData, colorScale)=>{
    let  NUMBER_OF_LABELS = 5 
    let barChartWidth = 75 + (graphData.data.length - 1) * 85
    let renderYaxisLabels=[]
    let renderXLabels=[]
    let renderGraphData =[]
    for(let i=NUMBER_OF_LABELS;i>=1;i--){
        renderYaxisLabels.push(
            <div className="dotted-xAxis">
                <div className="yAxis-Marker"></div>
                <div className="yAxis-Label">{(graphData.range.max/NUMBER_OF_LABELS)*i}</div>
            </div>
        )
    }
    for (let subSec of graphData.data){
        renderXLabels.push(
            <div className="item">
                <div className="markerLine"></div>
                <div className="markerLabel">{subSec.label}</div>
            </div>
        )
    }
    for (let subSec of graphData.data){
        renderGraphData.push(
            <div className="progress progress-vertical active">
                {subSec.value > 0 ?
                    <div className="bar" data-percent={subSec.value+"%"}></div>
                :
                    <div className="bar" data-percent="0.2%"></div>
                }
                {subSec.thresholdScore?
                    [<div className="threshold-flag" style={{bottom: Number(subSec.thresholdScore - 13)+"px"}}>
                        <img src={UrlConstants.ASSETS_BASE_URL+'/assets/images/threshold.svg'} alt="threshold"/>
                    </div>,
                    <div className="threshold-value" style={{bottom:Number(subSec.thresholdScore - 11)+"px"}}>
                        {subSec.thresholdScore}
                    </div>]
                :null}
                {subSec.value >= 0?
                    <div className="balloon" data-percent={subSec.value+"%"}>
                        <span>{ bubbleIcon(subSec.value, colorScale) }</span>
                        <div className={subSec.value.length === 3?'val fontSize7':'val'}>
                            {subSec.value}
                        </div>
                    </div>
                :   
                    <div className="balloon" data-percent="0%">
                        <span>{bubbleIcon(0, colorScale)}</span> 
                        <div className="val na-val">NA</div>
                    </div>
                }   
            </div>
        )
    }
    
    return(
        <div className="bar-chart" style={{width: barChartWidth+"px"}}>
            <div className="yAxisLine"></div>
            <div className="yAxis">
                {renderYaxisLabels}
                <div className="dotted-xAxis last">
                    <div className="yAxis-Marker"></div>
                    <div className="yAxis-Label">{graphData.range.min}</div>
                    <div className="markingLabelsCon">
                        {renderXLabels}
                    </div>
                </div>
                <div className="p-bars">
                    {renderGraphData}
                </div>
            </div>
        </div>
    )
}
export const thumbsDownSVG=(fillColor)=>{
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21.727" height="20.856" viewBox="0 0 21.727 20.856">
        <g id="Group_1165" data-name="Group 1165" transform="translate(-1284.131 -892.731)">
            <g id="Group_160" data-name="Group 160">
            <path id="Path_183" data-name="Path 183" d="M1284.877,902.005a2.978,2.978,0,0,0-.746,2.023,2.642,2.642,0,0,0,2.606,2.608h3.761c-.036.126-.072.235-.108.326a2.258,2.258,0,0,1-.15.3c-.062.108-.108.189-.135.244-.163.307-.288.561-.373.76a5.491,5.491,0,0,0-.258.815,4.192,4.192,0,0,0-.13,1.032c0,.218,0,.394.008.529a5.547,5.547,0,0,0,.067.611,3.476,3.476,0,0,0,.163.679,3.171,3.171,0,0,0,.326.611,1.9,1.9,0,0,0,.543.55,2.974,2.974,0,0,0,.816.354,4.046,4.046,0,0,0,1.119.142.835.835,0,0,0,.612-.258,2.375,2.375,0,0,0,.462-.679,4.029,4.029,0,0,0,.264-.706c.05-.2.107-.475.17-.829.082-.38.143-.653.184-.821a4.322,4.322,0,0,1,.237-.658,2.065,2.065,0,0,1,.422-.652,20.463,20.463,0,0,0,1.371-1.629q.666-.87,1.371-1.643c.472-.517.815-.784,1.032-.8a.9.9,0,0,0,.585-.278.825.825,0,0,0,.244-.591v-8.705a.818.818,0,0,0-.258-.6.9.9,0,0,0-.611-.264,9.979,9.979,0,0,1-2.146-.6q-1.045-.352-1.636-.537t-1.65-.393a10.091,10.091,0,0,0-1.955-.21h-1.752a3.377,3.377,0,0,0-2.675,1.058,3.265,3.265,0,0,0-.665,2.458,2.637,2.637,0,0,0-.733,1.276,2.788,2.788,0,0,0,0,1.589,2.816,2.816,0,0,0-.584,1.86,3.264,3.264,0,0,0,.2,1.033Zm0,0" fill={fillColor}/>
            <path id="Path_184" data-name="Path 184" d="M1301.078,904.9h3.91a.881.881,0,0,0,.87-.869v-8.691a.881.881,0,0,0-.87-.869h-3.91a.881.881,0,0,0-.869.869v8.691a.881.881,0,0,0,.869.869Zm1.562-8.439a.865.865,0,0,1,1.479.618.853.853,0,0,1-.25.611.869.869,0,1,1-1.229-1.229Zm0,0" fill={fillColor}/>
            </g>
        </g>
        </svg>
    )
}
export const thumbsSideSVG=(fillColor)=>{
    return(
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Group_2905" data-name="Group 2905" transform="translate(4099 -6337)">
        <rect id="Rectangle_2485" data-name="Rectangle 2485" width="24" height="24" transform="translate(-4099 6337)" fill="#fff" opacity="0"/>
        <g id="Group_2904" data-name="Group 2904" transform="translate(-4097.5 6359.924) rotate(-90)">
        <g id="Group_135" data-name="Group 135">
            <path id="Path_157" data-name="Path 157" d="M663,442.37a2.976,2.976,0,0,0,.747-2.023,2.643,2.643,0,0,0-2.606-2.608h-3.761c.036-.126.073-.235.108-.326a2.369,2.369,0,0,1,.15-.3c.063-.108.108-.189.135-.244.164-.308.288-.561.374-.76a5.461,5.461,0,0,0,.258-.815,4.237,4.237,0,0,0,.129-1.032c0-.218,0-.394-.007-.529a5.609,5.609,0,0,0-.068-.611,3.4,3.4,0,0,0-.162-.679,3.149,3.149,0,0,0-.327-.611,1.887,1.887,0,0,0-.543-.55,2.951,2.951,0,0,0-.815-.354,4.049,4.049,0,0,0-1.12-.142.834.834,0,0,0-.611.258,2.353,2.353,0,0,0-.462.679,4.121,4.121,0,0,0-.265.706c-.049.2-.106.475-.17.828q-.121.57-.183.822a4.35,4.35,0,0,1-.238.658,2.082,2.082,0,0,1-.421.652,20.219,20.219,0,0,0-1.371,1.629q-.666.87-1.372,1.643t-1.032.8a.891.891,0,0,0-.584.278.821.821,0,0,0-.244.591v8.7a.81.81,0,0,0,.258.6.9.9,0,0,0,.611.265,9.955,9.955,0,0,1,2.145.6q1.047.352,1.637.536c.393.123.943.253,1.649.394a10.105,10.105,0,0,0,1.955.21h1.752a3.37,3.37,0,0,0,2.675-1.059,3.26,3.26,0,0,0,.666-2.457,2.642,2.642,0,0,0,.733-1.276,5.838,5.838,0,0,1,.584-3.449,3.241,3.241,0,0,0-.2-1.033Zm0,0" transform="translate(-642.027 -430.789)" fill={fillColor}/>
            <path id="Path_158" data-name="Path 158" d="M646.805,439.48h-3.91a.881.881,0,0,0-.869.869v8.689a.881.881,0,0,0,.869.87h3.91a.881.881,0,0,0,.869-.87v-8.689a.881.881,0,0,0-.869-.869Zm-1.562,8.438a.869.869,0,0,1-1.229-1.229.869.869,0,1,1,1.229,1.229Zm0,0" transform="translate(-642.026 -430.79)" fill={fillColor}/>
        </g>
        </g>
    </g>
    </svg>)
}

export const thumbsUpSVG=(fillColor)=>{
    return(
    <svg xmlns="http://www.w3.org/2000/svg" width="21.727" height="20.857" viewBox="0 0 21.727 20.857">
    <g id="Group_1164" data-name="Group 1164" transform="translate(-642.026 -430.789)">
        <g id="Group_135" data-name="Group 135">
        <path id="Path_157" data-name="Path 157" d="M663.006,442.372a2.976,2.976,0,0,0,.747-2.023,2.643,2.643,0,0,0-2.606-2.608h-3.762c.036-.126.073-.235.108-.326a2.386,2.386,0,0,1,.15-.3c.063-.108.108-.189.135-.244.164-.308.288-.561.374-.76a5.494,5.494,0,0,0,.258-.815,4.241,4.241,0,0,0,.129-1.032c0-.218,0-.394-.007-.529a5.6,5.6,0,0,0-.068-.611,3.408,3.408,0,0,0-.162-.679,3.149,3.149,0,0,0-.327-.611,1.886,1.886,0,0,0-.543-.55,2.951,2.951,0,0,0-.815-.354,4.049,4.049,0,0,0-1.12-.142.834.834,0,0,0-.611.258,2.354,2.354,0,0,0-.462.679,4.132,4.132,0,0,0-.265.706c-.049.2-.106.475-.17.828q-.121.57-.183.822a4.341,4.341,0,0,1-.238.658,2.08,2.08,0,0,1-.421.652,20.241,20.241,0,0,0-1.371,1.629q-.666.87-1.372,1.643c-.471.517-.814.784-1.032.8a.891.891,0,0,0-.584.278.821.821,0,0,0-.244.591v8.7a.811.811,0,0,0,.258.6.9.9,0,0,0,.611.265,9.959,9.959,0,0,1,2.145.6q1.047.352,1.637.536c.393.123.943.253,1.649.394a10.106,10.106,0,0,0,1.955.21h1.752a3.371,3.371,0,0,0,2.675-1.059,3.262,3.262,0,0,0,.666-2.457,2.641,2.641,0,0,0,.733-1.276,5.839,5.839,0,0,1,.584-3.449,3.242,3.242,0,0,0-.2-1.033Zm0,0" fill={fillColor}/>
        <path id="Path_158" data-name="Path 158" d="M646.806,439.48H642.9a.881.881,0,0,0-.869.869v8.69a.881.881,0,0,0,.869.87h3.911a.881.881,0,0,0,.869-.87v-8.69a.881.881,0,0,0-.869-.869Zm-1.562,8.439a.869.869,0,0,1-1.229-1.229.869.869,0,1,1,1.229,1.229Zm0,0" fill={fillColor}/>
        </g>
    </g>
    </svg>)
}

export const gradientMarking = () => {
    let markings = []
    for(let i=0; i < 10; i++) {
        markings.push (
            <div className={`vLine ${(i===0 || i===9)?'':'hiddenVisibility'}`}></div>
        )
    }
    return markings
}

export const gradientGraph = (min,max,val) => {
    let percent = (val / max)* 100;
    console.log(min,max,val, percent);
    return (
        <div className="gradientGraph">
            <div className="gradient-container">
                <div className="gradient-bar"></div>
            </div>
            <div className="marker" style={{left:'calc('+percent+'% - 17px)'}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="35.375" height="50" viewBox="0 0 39.375 54">
                    <path key="Path_1526" data-name="Path 1526" d="M2740.188,366.687c0,10.874-20.25,34.313-19.688,34.313s-19.687-23.439-19.687-34.313a19.688,19.688,0,0,1,39.375,0Z" transform="translate(-2700.813 -347)" fill="#1f699d"/>
                </svg>
            </div>
            <div className="marker-text" style={{left:'calc('+percent+'% - 17px)'}}>
                {val}
            </div>
            <div className="markings flexContainer">
                {gradientMarking()}
            </div>       
        </div>
    )
}
export const errorSummary = (code,isTCReport) => {
    let errorHex=[];
    const errorCode = code.split('_')[2];
    if(isTCReport){
        errorHex = ['#827CE0','#FF8300', '#DE350B', '#00AEEF', '#48D6AF', '#337AB7', '#EE3D96'];
    }
    else{
        errorHex = ['#AC6BAD', '#FEB300', '#F44236', '#00AFF0', '#45AD4E', '#556BB7', '#EE3D96']
    }
    return errorHex[errorCode];
}
export const renderErrorSummaryIcon = (errorCode, isTCReport)=>{
    if (isTCReport) {
        const color = errorSummary(errorCode,isTCReport);
        return(
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="12" fill={color} fillOpacity="0.15"/>
                <circle cx="12" cy="12" r="8" fill={color}/>
            </svg>
        )
    } else {
        const color = errorSummary(errorCode,isTCReport)
        return(
            <svg xmlns="http://www.w3.org/2000/svg" width="15.962" height="15.962" viewBox="0 0 15.962 15.962">
                <g id="Group_1041" data-name="Group 1041" transform="translate(-1045.518 -389.482)">
                    <circle id="Ellipse_216" data-name="Ellipse 216" cx="5.679" cy="5.679" r="5.679" transform="translate(1047.819 391.784)" fill={color}/>
                    <circle id="Ellipse_217" data-name="Ellipse 217" cx="7.481" cy="7.481" r="7.481" transform="translate(1046.018 389.982)" fill="none" stroke={color} strokewiterlimit="10" strokeWidth="1"/>
                </g>
            </svg>
        )
    }
}
export const displayError = (text) =>{
    if(text === 'The Responses chapter is not applicable to this assessment'){
        return(<div className="genericError" style={{color:"black"}}>
            {text}
        </div>)
    }
    else{
        return(<div className="genericError">
            {text}
        </div>)
    }
}
export const colorizeText =(text, errorArr, removeBR, errorIndex = -1, isTCReport = false)=>{
    let new_string =''
    let start = 0
    for (let i in errorArr){
        let error = errorArr[i];
        const color = errorSummary(error.elementID,isTCReport);
        if ((error.offset >= start && error.offset >= 0 && error.length > 0 && errorIndex === -1) ||
        (errorIndex !== -1 && errorIndex === error.index)
        ) {
            let length_sub_string = error.offset - start
            new_string = new_string + text.substring(start,start+length_sub_string)
            if (error.name){
                new_string = new_string + '<span class="highlightTextBold" style="color:'+ color +'" data-toggle="tooltip" data-placement="bottom" title="'+ error.message +'">' + text.substring(error.offset,error.offset+error.length) +'</span>'
            }    
            else{
                new_string = new_string + '<span class="highlightTextBold" style="color:'+ color +'">' + text.substring(error.offset,error.offset+error.length) +'</span>'
            }
            start = error.offset + error.length
        }
    
    }
        
    new_string = new_string + text.substring(start,start+text.length)
    if (removeBR === true )
        return nl2br(new_string,true,true)
    else   
        return new_string
}
export const nl2br = (str, replaceMode, isXhtml) => {
    var breakTag = (isXhtml) ? '<br />' : '<br>';
    var replaceStr = (replaceMode) ? '$1'+ breakTag : '$1'+ breakTag +'$2';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
}

export const errorSummarySection=(errorSummary, isTCReport = false)=>{
    return(
        <React.Fragment>
            <div className="header flexContainer">
                <div className="heading font-semibold">
                    {errorSummary.header}
                </div>
            </div>
            <div className="data">
                {errorSummary.summary.map((summary,index)=>{
                    return(
                        <div className="sum-elem flexContainer" key={"errorSum"+index}>
                            <div className="icon">
                                {renderErrorSummaryIcon(summary.elementID, isTCReport)}
                            </div>
                            <div className="value flexContainer">
                                <div className="name font-regular">
                                    {summary.name}
                                </div>
                                <div className="count font-semibold font-action-semiBold-os">
                                    {summary.count}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </React.Fragment>
    )
}
export const errorDetailsSection=(errorAnalysis, isTCReport = false)=>{
    return(
        <div className="error-details" >
        <div className="header font-semibold">
            {errorAnalysis.header}
            <div className="error-details-border"></div>
        </div>
        <div className="data">
            {errorAnalysis.analysis.map((error,index)=>{
                const color = errorSummary(error.elementID,isTCReport);
                return(
                    <React.Fragment key={index}>
                        <div className="errorHeading font-semibold" style={{color: color}}>
                            {error.name}
                        </div>
                        <div className="errorData">
                            {error.errors.map((errorValue,index)=>{
                                return(
                                    <>
                                        {isTCReport?
                                        <div className='error-holder-container'>
                                            <div className="error-holder flexContainer" key={"errorHolder"+index}>
                                                <div className="text" dangerouslySetInnerHTML={{__html: htmlDecode(colorizeText(errorValue.text,[{
                                                    offset:errorValue.offset,
                                                    length:errorValue.length,
                                                    elementID:error.elementID
                                                    }],false, -1, isTCReport))}}> 
                                                </div>
                                                <div className="correction">
                                                {errorValue.message}
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="error-holder flexContainer" key={"errorHolder"+index}>
                                            <div className="text" dangerouslySetInnerHTML={{__html: htmlDecode(colorizeText(errorValue.text,[{
                                                    offset:errorValue.offset,
                                                    length:errorValue.length,
                                                    elementID:error.elementID
                                            }],false, -1, isTCReport))}}> 
                                            </div>
                                            <div className="correction">
                                            {errorValue.message}
                                            </div>
                                        </div>}
                                    </>
                                )
                            })}
                        </div>
                    </React.Fragment>
                )
            })}    
        </div>
    </div>
    )
    
}
export const colorizeTextWithSuper = (text, highlightArr, removeBR, color) =>{
    let new_string =''
    let offset_difference = 0
    let current_position = 0
    highlightArr.forEach((highlightText)=>{        
        if (highlightText.offset >= 0 && highlightText.length > 0){
            if (highlightText.offset >= current_position){
                let length_sub_string = highlightText.offset - current_position
                new_string = new_string + text.substring(current_position,current_position+length_sub_string)       
                offset_difference = 0
            }else{
                offset_difference = current_position - highlightText.offset
            }
            new_string = new_string + '<span style="color:'+ color +';text-decoration:underline ' + color + '">' + text.substring(highlightText.offset + offset_difference,highlightText.offset + offset_difference+(highlightText.length - offset_difference)) + '</span><sup style="color:' + color +'">' + highlightText.superScript + '</sup>'
            current_position = highlightText.offset + highlightText.length
        }
    })
    new_string = new_string + text.substring(current_position,current_position+text.length)
    return new_string
}
export const statsDetails=(stats,trim=0)=>{
    return(
        <React.Fragment>
            <div className="header font-semibold">
                {stats.header}
            </div>
            <div className='writeX-container'>
                <div className="data flexContainer">
                {stats.stats.map((stat,index)=>{
                let stat_val = String(stat.value)
                return(
                    <div className="stat flexContainer" key={"stat"+index}>
                        <div className="value font-action-semiBold font-action-extraBold">
                            {stat_val.split(':').length > 1?stat.value.split(':')[1]+":"+stat.value.split(':')[2]:stat.value}
                        </div>
                        <div className="label">
                            {stat.name}
                        </div>
                    </div>
                )
                })}
                </div>
                </div>
                
        </React.Fragment>
    )
    
}
export const CodeMeethorgraph=(percent, colorScale, circleIconVar = true)=>{
    let hexCode = getHexCode(Math.round(percent),colorScale)
    return(
        <div className="progress prog-con d-flex align-items-center">
            {percent >0 ?
            <div className="progress-bar hpertge" style={{background:hexCode, width:percent+"%"}} data-percent={percent+"%"}></div>
            :null}
            {percent >= 0 && circleIconVar?circleIcon(percent,colorScale):null}
        </div>
    )
}
export const codeMeetBar=(score,maxScore,colorScale,NA)=>{
    let percent=0;
    {score>0?
        percent = score / maxScore * 100
        :percent = score
    }
    return(
        <React.Fragment>
            <div className="score flexContainer">                        
                <div className="pgraph">{CodeMeethorgraph(percent,colorScale)}</div>
                <div className="scoreNum">
                    {score < 0  ?
                        <span className="obtained-score">{NA}</span>
                    :
                        <span>
                            <span className="obtained-score font-h4-semiBold">{score}</span>
                            <span className="max-score font-h4">/{maxScore}</span>
                        </span>
                    }
                </div>
            </div>

    </React.Fragment>
    )
}



// New helper for Circular Shape SVG_Circle

export const circularSVG = (fillColor) => {
    return(
        <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
        <circle cx="20" cy="20" r="15" fill={fillColor}/>
        </svg>
    )
}

export const feedbackHeader = (finalEvaluatorStatus,finalText,label) => {
    return(
        <div className="feedbackHeader flexContainer">
            <div className="ratingLabel">{label}:</div>
            <div className="status">
                {/* <span id="status_icon_current_evalutaor">
                    <span className={`stat-circle ${finalEvaluatorStatus === 1 ? 'high-class' : finalEvaluatorStatus === 2 ? "medium-class" : 'low-class'}`}></span>
                </span> */}
                <span id="status_text_current_evalutaor">{finalText}</span>
            </div>
    </div>
    )
}
export const Bar = ({data, colorScale,fillComplete=false,chapterConstants}) => {
    let sortedCS = Object.entries(colorScale.details)
    .sort((a, b) => {
        return a[1].min - b[1].min;
    })
    .map((item) => item[1]);
    let band = getHexCode(data.value, colorScale);
    const [maxWidth,setMaxWidth]=useState(null);
    useEffect(() => {
        let scoreContainer = document.getElementsByClassName("score-bar-container");
        let maxWidth = 0;
        for (let i = 0; i < scoreContainer.length; i++) {
        maxWidth = scoreContainer[i].offsetWidth > maxWidth ? scoreContainer[i].offsetWidth : maxWidth;
        }
        setTimeout(() => {
        setMaxWidth(() => maxWidth);
        }, 10);
    }, [])
    return (
        <div className="score-bar-container d-flex align-items-center pr-3" style={{width:maxWidth}}>
            <div className="barContainer">
                <div className="barPart" style={{width: `${100}%`}}>
                    <div className="barSubPart" style={{width: data.value, backgroundColor: band}}></div>
                </div>
                {/* Below Code breaks the Scorebar into 3 parts and fills color according to API Response
                 {sortedCS.map((e, index) => {
                    let width = e.max - e.min + "%";
                    let innerWidth = ((data.value - e.min) * 100) / (e.max - e.min);
                    if (innerWidth >= 0 && innerWidth <= 100){
                        innerWidth = (fillComplete?100:innerWidth) + "%";
                    } else if (innerWidth > 100) {
                        innerWidth = "100%";
                    } else {
                        innerWidth = 0;
                    }
                    return (
                        <div
                            className="barPart"
                            style={{
                            width: width
                            }}
                            key={index}
                        >
                            <div
                            className="barSubPart"
                            style={{
                                width: innerWidth,
                                minWidth: innerWidth,
                                backgroundColor: band
                            }}
                            ></div>
                        </div>
                    );
                })} */}
            </div>
            <div className="legend">
                {!!data.scores.unit ? (
                    <div className="unit">{data.value} {data.scores.unit}</div>
                ) : (
                    <div className="scores d-flex">
                    <span className="value">{data.value}</span>
                    {data.isPercentile ?
                    <span className="maxScore">{chapterConstants.SCORE_UNIT_PERCENTILE}</span>
                    :
                    <span className="maxScore">/{data.scores.max}</span>
                    }
                    </div>
                )}
            </div>
        </div>
    );
  }
  