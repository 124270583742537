import { combineReducers } from "redux";
import customReducer from "./custom/custom.reducer";
import mainReducer from "./main/main.reducer";
import headerReducer from "./header/header.reducer";
import interviewQuestionsReducer from './interviewQuestions/reducer'
import proctoringReducer from "./proctoring/reducer";
import conciseReducer from './concise/reducer'

const rootReducer = combineReducers({
    custom:customReducer,
    main:mainReducer,
    header:headerReducer,
    interviewQuestions:interviewQuestionsReducer,
    proctoring:proctoringReducer,
    concise: conciseReducer
})

export default rootReducer;