import React from 'react';
import { Trans } from 'react-i18next';
import CloseIcon from '../../../assets/images/close-grey.svg';


export const DownloadSelectiveChapter = ({ toggleModal, selectAll, handleChange, isAllSelected, chapter_headers, downloadPdfHandler, openDropdown , isDownloadDisabled }) => {
    return (
        <div className="ml-3 mr-1 border-left d-flex  align-items-center">
            <div className={`dropdown show dropdown-arrow downloadDropdownContainer`} >
                <div id="showSelectiveChapterList" className={`dropdown-menu shadow border-0 rounded download-dropdown downloadDropdown ${openDropdown ? 'show' : ''}`}>
                    <div className="userlist-dropdown-inner-download" id="myDropdown">
                        <div className='d-flex justify-content-between align-items-center heading-container'>
                        <div className="heading">
                            <p><Trans>DOWNLOAD_REPORT</Trans></p>
                            <p className='subheading font-body-os'><Trans>SELECT_YOUR_SHARING_OPTIONS_BELOW</Trans></p>
                            {/* <p className='competencies'><Trans>COMPETENCIES</Trans></p> */}
                        </div>
                            <div className="cross">
                                <img src={CloseIcon} alt="closing icon" onClick={toggleModal} className="cursor-pointer" />
                            </div>
                        </div>
                        <div className={`mb-2 selectiveDownloadListSection ${ isDownloadDisabled ? 'disabled' :'' }`}>
                            <p className='heading heading-chapter font-h4-os'><Trans>CHAPTERS</Trans></p>
                            <div className='heading heading-option d-flex align-items-left'>
                                <div className="chapter-options">
                                <p className='chapter-options-option'> <input className='checkbox' type="checkbox" value={isAllSelected} checked={isAllSelected} id="selectAllPDFChapters" onChange={selectAll} />
                                <label  htmlFor='selectAllPDFChapters' className='pl-2 align-middle'> <Trans>SELECT_ALL</Trans> </label>
                                </p>
                                </div>
                                {chapter_headers.map((chapter, index) => (
                                    <div className="chapter-options" key={index}>
                                    <p className='chapter-options-option'>
                                        <input className='checkbox' type="checkbox" id={`chapter-option-${index}`} data-originalvalue={chapter.value} checked={chapter.active} value={chapter.active} onChange={() => handleChange(index)} />
                                        <label htmlFor={`chapter-option-${index}`} className='pl-2 align-middle'>
                                            {chapter.label}
                                        </label>
                                    </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='d-flex justify-content-end align-items-center'>
                            <button
                                className={`btn btn-${chapter_headers.filter(chapterselected => chapterselected.active).length === 0 ? 'secondary' : 'primary'} downloadPDFButton`}
                                disabled={chapter_headers.filter(chapterselected => chapterselected.active).length === 0} onClick={downloadPdfHandler}>
                                <Trans>DOWNLOAD_PDF</Trans>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}