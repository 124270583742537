import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

function DropDownContent(props) {
  const {
    isCandidateFound,
    candidateListRes,
    getCandidateReport,
    errorMessage,
    offset,
    setOffset,
    limit
  } = props;

  return (
    <div className="dropdownContent" id="myDropdown">
      {isCandidateFound ? (
        <InfiniteScroll
          dataLength={candidateListRes?.length}
          next={() => {
            setOffset(offset + limit);
          }}
          hasMore={true}
          scrollableTarget="myDropdown"
        >
          {candidateListRes.map((candidate, index) => (
            <button
              key={index}
              className={`dropdown-item d-flex flex-column option`}
              onClick={() => getCandidateReport(candidate?.amcatId)}
            >
              <div className="d-flex justify-content-between">
                <div className="name font-action-bold">{candidate?.name}</div>
                <div className="font-small-medium">{candidate?.band}</div>
              </div>
              <div className="email font-small-medium">
                {candidate?.emailId}
              </div>
            </button>
          ))}
        </InfiniteScroll>
      ) : (
        <div className="errorContainer font-small-medium">{errorMessage}</div>
      )}
    </div>
  );
}

export default DropDownContent;
