import React from 'react'
const Popups =()=>{
    return (
        <React.Fragment>
            <div className="modal fade common-popup-large" id="common-popup-large" role="dialog" aria-labelledby="details" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content"
                        style={{margin:'10% auto', width:'960px'}}>
                        <div className="modal-header" style={{padding: '7px 20px'}}>
                            <h5 className="modal-title heading" id="heading">Heading</h5>
                            <button type="button" className="close" data-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body pop-up-details"
                            style={{padding: '10px 30px',minHeight: '70px', maxHeight: '460px', overflow: 'auto'}}>
                        </div>
                    </div>
                </div>
            </div>
            <button style={{display: 'none'}} data-toggle="modal" data-target="#common-popup-large" id="openPopupActionLarge"></button>
            
            <div className="modal fade common-popup-medium" id="common-popup-medium"
                role="dialog" aria-labelledby="details"
                aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content"
                        style={{margin: '150px auto', width: '628px'}}>
                        <div className="modal-header" style={{padding: '7px 20px'}}>
                            <h5 className="modal-title heading" id="heading">Heading</h5>
                            <button type="button" className="close" data-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body pop-up-details"
                            style={{padding: '15px 30px 30px'}}></div>
                    </div>
                </div>
            </div>
            <button style={{display: 'none'}} data-toggle="modal" data-target="#common-popup-medium" id="openPopupActionMedium"></button>
            
            <div className="modal fade common-popup-extralarge" id="common-popup-extralarge"
                tabIndex="-1" role="dialog" aria-labelledby="details"
                aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content" style={{margin: "10% auto", width: "1200px"}}>
                        <div className="modal-header" style={{padding: "7px 20px"}}>
                            <h5 className="modal-title heading" id="heading">Heading</h5>
                            <button type="button" className="close" data-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body pop-up-details"
                            style={{padding: "10px 30px", minHeight: "70px",  overflow: "auto", maxHeight:"800px"}}>
                        </div>
                    </div>
                </div>
            </div>
            <button style={{display: 'none'}} data-toggle="modal" data-target="#common-popup-extralarge" id="openPopupActionExtraLarge"></button>
        </React.Fragment>
    )
}
export default Popups

