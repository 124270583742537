import React from 'react';
import '../css/SwitchCandidate.css'
import { getCandidateList, getNeighbours } from '../../common/utils/apis';
import { useEffect, useState, useRef } from 'react';
import { DEFAULT_CACHED_CANDIDATE_COUNT } from '../../../constants/urls';

function SwitchCandidate(props) {
    const { getCandidateReport } = props;
    const [neighboursList, setNeighbourList] = useState({
        data: [],
        isPrevExist: false,
        isNextExist: false
    })
    const [currentIndex, setCurrentIndex] = useState(-1)

    const getCurrentAmcatId = () => {
        const session_request_data = JSON.parse(sessionStorage?.session_request_data);
        let currentAmcatId = session_request_data["amcatId"];
        return currentAmcatId
    }

    const setNeighbours = (res, fetchMode = 1) => {
        let isPrevExist = false, isNextExist = false, index, data = [], currentAmcatId = getCurrentAmcatId()
        switch (fetchMode) {
            case 1: {
                data = res
                index = res.indexOf(currentAmcatId)
                if (index >= DEFAULT_CACHED_CANDIDATE_COUNT) {
                    isPrevExist = true
                }
                if (res.length - index - 1 >= DEFAULT_CACHED_CANDIDATE_COUNT) {
                    isNextExist = true
                }
                break;
            }
            case 2: {
                data = [...res, ...neighboursList.data]
                index = currentIndex + res.length
                if (res.length >= DEFAULT_CACHED_CANDIDATE_COUNT) {
                    isPrevExist = true
                }
                isNextExist = neighboursList.isNextExist
                break;
            }
            default: {
                data = [...neighboursList.data, ...res]
                index = currentIndex
                if (res.length >= DEFAULT_CACHED_CANDIDATE_COUNT) {
                    isNextExist = true
                }
                isPrevExist = neighboursList.isPrevExist
            }
        }
        setNeighbourList({
            data: data,
            isPrevExist: isPrevExist,
            isNextExist: res.isNextExist
        })
        setCurrentIndex(index)
    }

    function handleSwitchCandidate(str) {
        let _currentIndex = currentIndex;
        if (str == 'prev') {
            getCandidateReport(neighboursList.data[_currentIndex - 1])
            setCurrentIndex(_currentIndex - 1)
            if (_currentIndex == 2 && neighboursList.isPrevExist) {
                getNeighbours(neighboursList.data[0], res => setNeighbours(res), 2)
            }
        }
        else if (str == 'next') {
            getCandidateReport(neighboursList.data[_currentIndex + 1])
            setCurrentIndex(_currentIndex + 1)
            if (_currentIndex == neighboursList.data.length - 3 && neighboursList.isNextExist) {
                getNeighbours(neighboursList.data[neighboursList.data.length - 1], res => setNeighbours(res), 3)
            }
        }
    }

    useEffect(() => {
        getNeighbours(getCurrentAmcatId(), res => { setNeighbours(res) });
    }, [])

    return (
        <div className='switchCandidateIcon'>
            <div className={`previousCandidateIcon ${currentIndex <= 0 ? 'inactive' : ''}`}>
                <svg width="20" height="12" viewBox="0 0 20 12" xmlns="http://www.w3.org/2000/svg" onClick={() => { handleSwitchCandidate("prev") }}>
                    <path d="M18.23 0.33L20 2.1L10.1 12L0.200001 2.1L1.97 0.33L10.1 8.46L18.23 0.33Z" />
                </svg>
            </div>
            <div className={`nextCandidateIcon ${currentIndex == neighboursList.data.length - 1 ? 'inactive' : ''}`}>
                <svg width="20" height="12" viewBox="0 0 20 12" xmlns="http://www.w3.org/2000/svg" onClick={() => { handleSwitchCandidate("next") }}>
                    <path d="M18.23 0.33L20 2.1L10.1 12L0.200001 2.1L1.97 0.33L10.1 8.46L18.23 0.33Z" />
                </svg>
            </div>
        </div>
    )
}

export default SwitchCandidate;
