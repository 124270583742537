import React from 'react'
import LoadingImg from '../../../assets/images/loader.gif'
import LoadingImgOld from '../../../assets/images/loader-old.gif'
import { UR_REPORT_ID } from '../../../constants/urls'

export const BackDropLoader = (props) => {
    let userData=JSON.parse(sessionStorage.getItem("session_request_data"));
    return (
        <div className={props.isActive ? "backDrop-wrapper":"d-none"} key="loading_key">
            <img src={userData.reportId===UR_REPORT_ID?LoadingImg:LoadingImgOld} alt={props.chapterName || 'Loading'} />
        </div>
    )
}
export const ChapterLoader = (props) => {
    let userData=JSON.parse(sessionStorage.getItem("session_request_data"));
    return (
        <div className="loading" key="loading_key">
            <img src={userData.reportId===UR_REPORT_ID?LoadingImg:LoadingImgOld} alt={props.chapterName || 'Loading'} />
        </div>
    )
}
export const IsUrReport = () => {
    let userData=JSON.parse(sessionStorage.getItem("session_request_data"));
    return userData.reportId===UR_REPORT_ID;
}

const FullScreenLoading =(val)=> {
    let userData=JSON.parse(sessionStorage.getItem("session_request_data"));
    return (
        userData?.reportId===UR_REPORT_ID?<div id="fullScreenLoading" className="UR_loading">
            <img src={LoadingImg} alt="Loading" />
        </div>:<div id="fullScreenLoading" className="">
            <img src={LoadingImgOld} alt="Loading" />
        </div>

    )
}
export default FullScreenLoading
