import actionType from "../../actions/proctoring/actionType";
import { initialState } from "./initialState";

const proctoringReducer = (state=initialState , action) => {
    switch (action.type) {
        case actionType.GET_PROCTORING_CHAPTER: {
            return {
                ...state,
                chapterData: {
                    ...state.chapterData,
                    loading: true,
                    success: false,
                    error: false
                }
            }
        }
        case actionType.GET_PROCTORING_CHAPTER_SUCCESS: {
            return {
                ...state,
                chapterData: {
                    data: action.payload,
                    loading: false,
                    success: true,
                    error: false
                }
            }
        }
        case actionType.GET_PROCTORING_CHAPTER_FAILURE: {
            return {
                ...state,
                chapterData : {
                    ...state.chapterData,
                    loading: false,
                    success: false,
                    error: true
                }
            }
        }
        default:
            return state;
    }
}

export default proctoringReducer