import React, { useEffect } from "react";
import { Toast } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Close from "../../../assets/images/toast/close.png";
import Success from "../../../assets/images/toast/success.png";
import Error from "../../../assets/images/toast/Error.png";
import { showToast } from "../../../actions/customAction/customAction";

export const Toaster = ({ isToast, type, content }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(showToast({ isToast: false, content: null, type: null }));
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  const CloseToast = () => {
    dispatch(showToast({ isToast: false, content: null, type: null }));
  };

  return (
    <Toast show={isToast} className={type}>
      <Toast.Body className="d-flex justify-content-between align-items-center p-0">
        <div className="d-flex p-0 align-items-center">
          {type !== "normal" ? (
            <img
              src={
                type === "error" ? Error : type === "success" ? Success : null
              }
              width="20px"
              height="20px"
              alt="status_icon"
            />
          ) : null}
          <p className="px-2 my-0">{content}</p>
        </div>
        <img
          src={Close}
          className="cursor-pointer"
          onClick={CloseToast}
          width="15px"
          height="15px"
          alt="close"
        />
      </Toast.Body>
    </Toast>
  );
};
