import React from 'react'
import { htmlDecode } from '../../common/utils/common'
import { isEmptyObjectValue } from '../../common/utils/common';
import UserDefault from '../../../assets/images/user-default-img.png'

export const HeaderPopUp = (props) => {
    const chapterdetails = props?.chapterdetails;
    const headerPopupDetails = chapterdetails?.formData?.data;
    let candidateDetails = headerPopupDetails?.headerCandidateDetails?.data;
    let candidateDetailsHeader = headerPopupDetails?.headerCandidateDetails?.header;
    let candidateDetailsArr = [];
    let projectDetails = headerPopupDetails?.headerProjectDetails?.data;
    let projectDetailsHeader = headerPopupDetails?.headerProjectDetails?.header;
    let projectDetailsArr = [];

        for(let key in candidateDetails){
            if(key !== "appFormDetails" && candidateDetails[key]?.value !== ''){
                candidateDetailsArr.push(<div className="item flexContainer" key={candidateDetails[key]?.label}>
                    <div className="itemName font-action-bold">
                        {candidateDetails[key]?.label}
                    </div>
                    <div className="itemValue font-action">
                        {candidateDetails[key]?.value}
                    </div>
                </div>)
            }
        }

        for(let key in projectDetails){
            if(projectDetails[key]?.value !== ''){
                projectDetailsArr.push(<div className="item flexContainer" key={projectDetails[key]?.label}>
                <div className="itemName font-action-bold">
                    {projectDetails[key]?.label}
                </div>
                <div className="itemValue font-action">
                    {projectDetails[key]?.value}
                </div>
            </div>)
            }
        }

    const str = chapterdetails?.formData?.header;
    const candidatePopupHeader = str[0].toUpperCase() + str.slice(1).toLowerCase()

    return (
            <div className="modal fade" id="personal-details" role="dialog" aria-labelledby="details" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title font-h2-semiBold" id="details">
                                {candidatePopupHeader}
                            </h5>
                            <button type="button" className="close" data-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="detail-container" id = "participantDetails">
                                <div className="detail-container__item mb-3 mt-4" id="personalDetails">
                                    <div className='font-h4-bold-os mb-3'>
                                        {candidateDetailsHeader}
                                    </div>
                                    <div className='personalDetailsFlex details-img-holder'>
                                        {chapterdetails?.iCardImage? 
                                            <img src={chapterdetails.iCardImage} alt="img" />
                                            :
                                            <img src={UserDefault} alt="img"/> 
                                        }
                                        <div className="participantDetailsFlex">
                                            {candidateDetailsArr}
                                        </div>
                                    </div>
                                </div>

                                {!isEmptyObjectValue(Object.values(projectDetails)) ? 
                                    <div className="detail-container__item" id="projectDetails">
                                        <div className='font-h4-bold-os mb-3'>
                                            {projectDetailsHeader}
                                        </div>
                                        <div className='participantDetailsFlex'>
                                            {projectDetailsArr}
                                        </div>
                                    </div> 
                                    : 
                                    null
                                }
                        
                            {candidateDetails.appFormDetails && candidateDetails?.appFormDetails?.value?.length > 0 && <div className="col-12 order-3 px-0 application-formdata">
                            <div className="itemName">{headerPopupDetails?.appFormDetails?.label}</div>
                                    {candidateDetails?.appFormDetails?.value?.length > 0 && candidateDetails.appFormDetails?.value?.map(({ label, value }, index) =>
                                        <div className="p-3 my-3 form-data" key={index}>
                                            <h6 className="itemName" dangerouslySetInnerHTML={{ __html: htmlDecode(label) }}></h6>
                                            <div className="itemValue" dangerouslySetInnerHTML={{ __html: htmlDecode(value) }}></div>
                                        </div>
                                    )}
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}