import React,{ useEffect } from 'react'
import '../css/languagedropdownDR.css'
import LanguageDropDownImg from '../../../assets/images/language_mobile.svg'

const LanguageDropDown = (props)=>{
    let languages_obj  = props.languages || {}
    const queryParams = JSON.parse(sessionStorage.getItem("session_request_data"))
    const changeLanguageHandler=(obj)=>{
        let new_selected_language = obj.getAttribute("data-value")
        queryParams.locale = new_selected_language
        sessionStorage.setItem("session_request_data",JSON.stringify(queryParams))
        console.log(queryParams)
        window.history.pushState(null, null, '/')
        window.location.reload()
    }
    const triggerLangDropDownMobile=()=>{
        let elem = document.querySelector("#select-selected")
        elem.click()
    }

    languages_obj = Object.fromEntries(
        Object.entries(languages_obj)
        .sort(([key1, val1], [key2, val2]) => val1.localeCompare(val2))
    )

    useEffect(() => {
        var x, i, j, l, ll, selElmnt, a, b, c, imgElement;
        /*look for any elements with the class "custom-select":*/
        x = document.getElementsByClassName("custom-select-dropdown");
        l = x.length;
        for (i = 0; i < l; i++) {
        selElmnt = x[i].getElementsByTagName("select")[0];
        ll = selElmnt.length;
        /*for each element, create a new DIV that will act as the selected item:*/
        a = document.createElement("DIV");
        a.setAttribute("class", "select-selected");
        a.setAttribute("id", "select-selected");
        a.innerHTML = languages_obj[queryParams.locale];
        x[i].appendChild(a);
        /*for each element, create a new DIV that will contain the option list:*/
        b = document.createElement("DIV");
        b.setAttribute("class", "select-items select-hide ");
        
        for (j = 0; j < ll; j++) {
            /*for each option in the original select element,
            create a new DIV that will act as an option item:*/
            c = document.createElement("DIV");
            c.innerHTML = selElmnt.options[j].innerHTML;
            c.setAttribute('data-value', selElmnt.options[j].value);
            if(a.innerHTML===c.innerHTML)  {
                c.setAttribute('class', 'selected-language')
            }
            
            c.addEventListener("click", function(e) {
                /*when an item is clicked, update the original select box,
                and the selected item:*/
                var y, i, k, s, h, sl, yl;
                s = this.parentNode.parentNode.getElementsByTagName("select")[0];
                sl = s.length;
                h = this.parentNode.previousSibling;
                for (i = 0; i < sl; i++) {
                if (s.options[i].innerHTML == this.innerHTML) {
                    s.selectedIndex = i;
                    changeLanguageHandler(this)
                    h.innerHTML = this.innerHTML;
                    y = this.parentNode.getElementsByClassName("same-as-selected");
                    yl = y.length;
                    for (k = 0; k < yl; k++) {
                    y[k].removeAttribute("class");
                    }
                    this.setAttribute("class", "same-as-selected");
                    break;
                }
                }
                h.click();
            });
            b.appendChild(c);
        }
        x[i].appendChild(b);
        a.addEventListener("click", function(e) {
            /*when the select box is clicked, close any other select boxes,
            and open/close the current select box:*/
            e.stopPropagation();
            closeAllSelect(this);
            this.nextSibling.classList.toggle("select-hide");
            this.classList.toggle("select-arrow-active");
            });
        }
        function closeAllSelect(elmnt) {
        /*a function that will close all select boxes in the document,
        except the current select box:*/
        if(elmnt && elmnt.target && elmnt.target.id && elmnt.target.id === 'languageDropDownImg'){
            
            
        }else{
            var x, y, i, xl, yl, arrNo = [];
            x = document.getElementsByClassName("select-items");
            y = document.getElementsByClassName("select-selected");
            xl = x.length;
            yl = y.length;
            for (i = 0; i < yl; i++) {
                if (elmnt == y[i]) {
                arrNo.push(i)
                } else {
                y[i].classList.remove("select-arrow-active");
                }
            }
            for (i = 0; i < xl; i++) {
                if (arrNo.indexOf(i)) {
                x[i].classList.add("select-hide");
                }
            }
        }
     
        }
        /*if the user clicks anywhere outside the select box,
        then close all select boxes:*/
        document.addEventListener("click", closeAllSelect);
        
    }, []);
    return(
     <>
     {Object.keys(languages_obj).length?
        <div className="languageChangeContainer">
            <div className="custom-select-dropdown">
                <select id="langDropDown" >
                    {Object.keys(languages_obj).map((item,index)=>{
                        return <option key={`langDropDown_${index}`} value={item}>{languages_obj[item]}</option>
                    })}
                </select>
            </div>
            <img src={LanguageDropDownImg} className="" id="languageDropDownImg" onClick={triggerLangDropDownMobile}/>
        </div>
        :null   
     }   
    
    </>
    )
}

export default LanguageDropDown