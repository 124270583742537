import React, { Component, useEffect } from 'react'
import * as UrlConstants from '../../../constants/urls'
import $ from 'jquery'
import HireabilityStatus from '../../common/components/hireabilitystatus'
import Popups from '../../common/components/popups.js'
import { connect } from 'react-redux'
import {STATUS_ICON_MAPPING}  from '../../common/utils/mapping'
import {showSnackbar} from '../../common/utils/common'
import '../css/header.css'
import LanguageDropDown from './languagedropdown'
import Down from '../../../assets/images/down.svg';
import DownloadIcon from '../../../assets/images/downloadIcon-old.svg'
import DownloadingIcon from '../../../assets/images/downloading.gif';
import Incorrect from '../../../assets/images/incorrect.svg'
import Questions from '../../../assets/images/questions.svg'
import Correct from '../../../assets/images/correct.svg'
import NotAttempted from '../../../assets/images/not-attempted.svg'
import UserDefault from '../../../assets/images/user-default-img.png'
import { updateHireabilityStatus } from '../../../actions/customAction/customAction'
import { useState } from 'react'
import { downloadPdf, getCandidateListByEvaluator, updateCandidateListByEvaluator } from '../../../actions/headerAction/headerAction.js'
import { useRef } from 'react'

const Header = (
    {initialLoad,
    getCandidateReport,
    getCandidateListByEvaluator,
    updateCandidateListByEvaluator,
    isCandidateListActive,
    chapterdetails,
    chapter_headers,
    isTCPlus,
    branding,
    helpMenu,
    isViewMode,
    candidateListResult,
    candidate_ids,
    downloadPdf,
    updateHireabilityStatus,
    candidateListByEvaluator,
    pdf,
    }) => {
    let candidatePosition=-1
    let sessionData=JSON.parse(sessionStorage.session_request_data)
    const [showPrev , setShowPrev] = useState(false)
    const [showNext , setShowNext] = useState(false)
    const pdfAnchorRef = useRef(null)
    
    ////this snippet will get executed when pdf api gets completed
    useEffect(() => {
       if(pdf && !pdf.loading && pdf.success){
          onDownloadPdfSuccess()
       }else if(pdf && !pdf.loading && pdf.error){
          onDownloadPdfFailure()
       }
    },[pdf])
    
    //this snippet will get executed when evaluator api gets completed
    useEffect(() => {
       if(candidateListByEvaluator && !candidateListByEvaluator.loading && candidateListByEvaluator.success) {
          if(candidateListByEvaluator.data.indexOf(Number(sessionData.amcatId)) ==-1){
            let candidateList=candidateListByEvaluator.data
            candidateList.unshift(Number(sessionData.amcatId))
            updateCandidateListByEvaluator(candidateList)
          }
       }
    },[candidateListByEvaluator])

    //this snippet will get executed when component will mount for the first time
    useEffect(() => {
        _updateHireabilityStatus()
        if(isViewMode){
            getCandidateList()
        }else if(candidate_ids?.length>0){
            let id_arr=candidate_ids
            id_arr.forEach((id,index)=>id_arr[index]=Number(id))
            updateCandidateListByEvaluator(id_arr)
            renderNextPreviousHandler(id_arr)
        }
    },[])

    const _updateHireabilityStatus = () => {
        if(chapterdetails.cutoffStatus && 
            Object.keys(chapterdetails.cutoffStatus).length && 
            chapterdetails.cutoffStatus.final &&
            chapterdetails.cutoffStatus.final.length &&
            chapterdetails.cutoffStatus.final[0].statusLevel){   
                updateHireabilityStatus({
                    chapterConstants: {...chapterdetails.chapterConstants},
                    profileName: chapterdetails.cutoffStatus.final[0].profileName,
                    statusLevel: chapterdetails.cutoffStatus.final[0].statusLevel,
                    multipleHirabilityData: chapterdetails.cutoffStatus.final,
                    shouldRender:true,
                    isDisabled: !!chapterdetails.cutoffStatus?.disableCutoffStatus
                 })
        }else{
            updateHireabilityStatus({
                chapterConstants: {...chapterdetails.chapterConstants},
                shouldRender:false,
                isDisabled: !!chapterdetails.cutoffStatus?.disableCutoffStatus
             })
        }
    }

    const  renderNextPreviousHandler = ()=>{
        let AMCAT_ID = Number(JSON.parse(sessionStorage.getItem("session_request_data"))['amcatId'])
        localStorage.setItem("reportData",JSON.stringify(candidateListByEvaluator.data))
        candidatePosition = candidateListByEvaluator.data.indexOf(AMCAT_ID)
        if (typeof (candidatePosition) != 'undefined' && candidatePosition >= 0){
            if (candidateListByEvaluator.data.length - 1 == candidatePosition) {
                setShowPrev(true)
            }else if (candidatePosition == 0) {
                setShowNext(true)
            }else {
                setShowNext(true)
                setShowPrev(true);
            }
        }
    }

    const candidateListHandler = (pos)=>{
        candidatePosition = candidatePosition+pos
        let queryParams = JSON.parse(sessionStorage.getItem("session_request_data"))
        queryParams.amcatId = candidateListByEvaluator[candidatePosition]
        sessionStorage.setItem("session_request_data",JSON.stringify(queryParams))
        window.history.pushState(null, null, '/')
        window.location.reload()
    }

    const getCandidateList = (searchText) => {
        let AMCAT_ID = Number(sessionData.amcatId)
        let candidateListKey = sessionData.candidateListKey || ""
        let filter = searchText?searchText:""
        let payload = {
            data:{
                language:"en",
                data:{
                    candidatePublicID:AMCAT_ID,
                    searchText:""+filter,
                    candidateListKey:candidateListKey
                }
            }
        }
        getCandidateListByEvaluator(payload) 
    }

    const downloadPdfHandler = () => {
        let dataToSend = {
            locale: sessionData.locale,
            reportId: sessionData.reportId,
            isSvar: sessionData.isSvar,
            outputFormat: 'pdf'
        }
        if(sessionData.amcatId){
            dataToSend.amcatId=Number(sessionData.amcatId)
        }else {
            dataToSend.testAttemptId=sessionData.testAttemptId
        }
        downloadPdf(dataToSend)
    }
    
    //this snippet will be executed when download pdf api gets successfully executed
    const onDownloadPdfSuccess = () => {             
        if (pdf.data) {
            pdfAnchorRef.current.click()
        } else {
            showSnackbar("Error in generating PDF");
        } 
    }
    
    //this snippet will be executed when download pdf api gets some error
    const onDownloadPdfFailure = () => {
        showSnackbar("Error in downloading PDF");
    }


    //create dom for candidate detail 
    const renderCandidateDetails = () => {
        let candidateDetails=[]
        for (let key in chapterdetails?.formData){
            if (key !== "fullName" && chapterdetails?.formData[key].value){
                candidateDetails.push(
                <div className="item flexContainer" key={chapterdetails?.formData[key].label}>
                    <div className="itemName">
                        {chapterdetails.formData[key].label}
                    </div>
                <div className="colon" key={chapterdetails.formData[key].value}>:</div>
                    <div className="itemValue">
                        {chapterdetails.formData[key].value}
                    </div>
                </div>
                )
            }
        }
        return candidateDetails
    }

    //create dom for assessment detail
    const renderAssessmentDetails = () => {
        let assessmentDetails=[]

        if ((chapterdetails?.assessmentDetails && !Array.isArray(chapterdetails.assessmentDetails)) || (chapterdetails?.moduleDetails &&  !Array.isArray(chapterdetails.moduleDetails))){
            assessmentDetails.push(
            <div className="extra-details-container" key={'extradetailspopup'}>
                {chapterdetails.assessmentDetails?
                    <div className="assessment-details-container">
                        <div className="assessment-header">
                            {chapterdetails.chapterConstants.ASSESSMENT_DETAILS}
                        </div>
                        {Object.keys(chapterdetails.assessmentDetails).map((details,index)=>{
                            return(chapterdetails.assessmentDetails[details].value?
                                    <div className="item flexContainer" key={'assess'+index}>
                                        <div className="itemName">
                                            {chapterdetails.assessmentDetails[details].label}
                                        </div>
                                        <div className="colon">:</div>
                                        <div className="itemValue">
                                            {chapterdetails.assessmentDetails[details].value}
                                        </div>
                                    </div>
                                    :null
                            )
                        })}
                    </div>
                :null}
                {chapterdetails?.moduleDetails?.data?.length ? 
                    <div className="module-details-container">
                        <div className="module-details-header">
                            {chapterdetails.moduleDetails.header}
                        </div>
                        {chapterdetails.moduleDetails.data.map((details,index)=>{
                            return(
                                <React.Fragment key={'moduledetailpopup'+index}>
                                    <div className="module-header">
                                        {details.header}
                                    </div>
                                    <div className="module-items-container flexContainer">
                                        {details.totalQuestions && details.totalQuestions.value?
                                            <div className="module-item flexContainer">
                                                <img src={Questions} alt="icon"/>
                                                <span className="module-itemBold">{details.totalQuestions.label+':'}</span>
                                                <span className="module-itemBold">{details.totalQuestions.value}</span>
                                            </div>
                                        :null
                                        }
                                        {details.correctQuestions && details.correctQuestions.value?
                                            <div className="module-item flexContainer">
                                                <img src={Correct} alt="icon"/>
                                                <span className="module-itemNormal">{details.correctQuestions.label+':'}</span>
                                                <span className="module-itemBold">{details.correctQuestions.value}</span>
                                            </div>
                                        :null}
                                        {details.incorrectQuestions && details.incorrectQuestions.value?
                                            <div className="module-item flexContainer">
                                                <img src={Incorrect} alt="icon"/>
                                                <span className="module-itemNormal">{details.incorrectQuestions.label+':'}</span>
                                                <span className="module-itemBold">{details.incorrectQuestions.value}</span>
                                            </div>
                                        :null}
                                        {details.remainingQuestions && details.remainingQuestions.value?
                                            <div className="module-item flexContainer">
                                                <img src={NotAttempted} alt="icon"/>
                                                <span className="module-itemNormal">{details.incorrectQuestions.label+':'}</span>
                                                <span className="module-itemBold">{details.incorrectQuestions.value}</span>
                                            </div>
                                        :null}
                                    </div>
                                </React.Fragment>
                            )
                        })}  
                    </div> 
                :null}
            
        </div>)
        }

        return assessmentDetails
    }

        return(
            <React.Fragment>
                <div id="snackbar"></div>
                <div className="modal fade" id="candidate-details" role="dialog" aria-labelledby="details" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="details">
                                    {chapterdetails?.chapterConstants.CDH_HEADER}
                                </h5>
                                <button type="button" className="close" data-dismiss="modal"
                                    aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="detail-container row">
                                    <div className="candidate-header col-12">
                                        {chapterdetails?.chapterConstants.CDH_CANDIDATE_DETAILS}
                                    </div>
                                    <div className="txt-holder col-12 order-2 col-sm-8 order-sm-1">
                                        {chapterdetails?.formData.fullName?
                                            <div className="item flexContainer">
                                                <div className="itemName">
                                                    {chapterdetails?.formData.fullName.label}
                                                </div>
                                                <div className="colon">:</div>
                                                <div className="itemValue">
                                                    {chapterdetails?.formData.fullName.value}
                                                </div>
                                            </div>
                                        :null}
                                        {chapterdetails?.amcatId?
                                            <div className="item flexContainer">
                                                <div className="itemName">
                                                    {chapterdetails?.amcatId.label}
                                                </div>
                                                <div className="colon">:</div>
                                                <div className="itemValue">
                                                    {chapterdetails?.amcatId.value}
                                                </div>
                                            </div>
                                        :null}
                                        {renderCandidateDetails()}
                                    
                                        {chapterdetails?.testDate?
                                            <div className="item flexContainer">
                                                <div className="itemName">
                                                    {chapterdetails?.testDate.label}
                                                </div>
                                                <div className="colon">:</div>
                                                <div className="itemValue">
                                                    {chapterdetails?.testDate.value}
                                                </div>
                                            </div>
                                        :null}
                                    </div>
                                    <div className="details-img-holder col-12 order-1 col-sm-4 order-sm-2 text-center m-auto">
                                        {chapterdetails?.iCardImage?
                                        <img src={chapterdetails?.iCardImage} style={{width: 'inherit'}} alt="img"/>
                                        :<img src={UserDefault} alt="img"/>
                                        }
                                    </div>
                                </div>
                                {renderAssessmentDetails()}
                            </div>
                        </div>
                    </div>
                </div>
                <Popups />
                
                {showPrev?<div id="prev-btn" onClick={() => candidateListHandler(-1)} className="action-btn" data-toggle="tooltip" data-placement="top" title="Previous" ><svg xmlns="http://www.w3.org/2000/svg" width="9.083" height="17.152" viewBox="0 0 9.083 17.152">  <path id="Path_105" data-name="Path 105" d="M1269.475,313.813l-7.083-7.576,7.083-7.576" transform="translate(-1261.392 -297.662)" fill="none" stroke="#666" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/></svg></div>:null}
                {showNext?<div id="next-btn" onClick={() => candidateListHandler(1)} className="action-btn" data-toggle="tooltip" data-placement="top" title="Next" ><svg xmlns="http://www.w3.org/2000/svg" width="9.083" height="17.152" viewBox="0 0 9.083 17.152">  <path id="Path_104" data-name="Path 104" d="M1293.982,313.813l7.083-7.576-7.083-7.576" transform="translate(-1292.982 -297.662)" fill="none" stroke="#666" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/></svg></div>:null}
                <div className="row basic">
                    <div className="col-12 col-sm-8 candidateDetailsContainer">
                        {chapterdetails?.companyLogo && chapterdetails?.companyLogo.source?
                            <span>
                                <img className="companyLogo" src={chapterdetails?.companyLogo.source} alt={chapterdetails.companyLogo.altText} />
                                <div className="longseperator"></div>
                            </span>
                        :null
                        }
                        {chapterdetails?.amcatId.value?
                        <div className="detailsContainer" data-toggle="modal"
                            data-target="#candidate-details">
                            {chapterdetails?.formData.fullName && chapterdetails?.formData.fullName?
                            <div className="name">
                                {chapterdetails?.formData.fullName.value}
                            </div>
                            :null}
                            {chapterdetails?.amcatId.value?
                            <div className="id">
                                <span className="text"> {chapterdetails?.amcatId.label} : </span> 
                                <span className="value" id="candidateAMCAT_ID"> {chapterdetails?.amcatId.value} </span>
                            </div>
                            :
                            null
                            }
                        </div>
                        :
                        <div className="detailsContainer" style={{cursor: "default"}}>
                            {chapterdetails?.formData.fullName && chapterdetails?.formData.fullName?
                            <div className="name">
                                {chapterdetails?.formData.fullName.value}
                            </div>
                            :null}
                            {chapterdetails?.formData.emailId.value?
                            <div className="id">
                                <span className="value" id="candidateEmail_ID"> {chapterdetails?.formData.emailId.value} </span>
                            </div>
                            :
                            null
                            }
                        </div>
                        }
                    </div>
                    <div className="col-12 col-sm-4 my-auto extraDetailsContainer flexContainer">
                        <LanguageDropDown languages={chapterdetails?.reportLocales || {}}/>
                        <div tabIndex="0"  aria-describedby="tooltip" className="hirabilityStatus flexContainer">
                            <HireabilityStatus/>
                        </div>
                        {chapterdetails?.hideDownloadButton != 1?
                        <>
                        <img className="download-icon d-inline-block"
                        src={pdf.loading ? DownloadingIcon : DownloadIcon}
                        data-toggle="tooltip" data-placement="bottom"
                        title={chapterdetails?.chapterConstants.DOWNLOAD_REPORT} alt="icon"
                        onClick={downloadPdfHandler}
                        />
                        <a ref={pdfAnchorRef} href={pdf.data} target='_blank' className='d-none'></a>
                        </>
                        :null
                        }
                    </div>
                </div>
            </React.Fragment>
        )
}


const mapStateToProps = (state) => ({
    // sessionData:state.main.sessionData,
    pdf:state.header.pdf,
    candidateListByEvaluator:state.header.candidateListByEvaluator
})

const mapDispatchToProps = dispatch => ({
    updateHireabilityStatus:(data) => {
        dispatch(updateHireabilityStatus(data))
    },
    getCandidateListByEvaluator:(data) => {
        dispatch(getCandidateListByEvaluator(data))
    },
    updateCandidateListByEvaluator:(data) => {
        dispatch(updateCandidateListByEvaluator(data))
    },
    downloadPdf:(data) => {
        dispatch(downloadPdf(data))
    }

})

export default connect(mapStateToProps,mapDispatchToProps)(Header)
