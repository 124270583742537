const initialState = {
    chapterData: {
        data: {
            overallSection: [],
            groups: [],
            filters: {sortFilter: []},
            colorScale: {},
            info: null,
            sectionHeader: "",
            chapterConstants: {}
        },
        loading: false,
        success: false,
        error: false
    }
};

export default initialState;
