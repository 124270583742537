import React from 'react'
import { Trans } from 'react-i18next';
import ErrorTC from '../../../assets/images/errorTC.svg'

const ReportErrorTC=(props)=>{
    return(
        <div className='error-bodyTC'>
            <div className="error-headTC">
                <div className="error-header-textTC">Name</div>
            </div>
            <div className='error-containerTC flexContainer'>
                <div className='error-rectTC flexContainer'>
                    <img className='error-ImgTC' src={ErrorTC} alt="Error"/>
                    <div className='error-textTC'><Trans>REPORT_NOT_LOADED_TC</Trans>!</div>
                </div>
            </div>
        </div>
    )
}
export default ReportErrorTC