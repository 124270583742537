import actionType from "../../actions/headerAction/headerActionType";
import initialState from "./headerState";

const headerReducer= (state=initialState,action) => {
    switch(action.type) {
        case actionType.GET_CANDIDATE_LIST_BY_EVALUATOR:{
            return {
                ...state,
                candidateListByEvaluator:{
                    ...state.candidateListByEvaluator,
                    loading:true,
                    success:false,
                    error:false
                }
            }
        }
        case actionType.GET_CANDIDATE_LIST_BY_EVALUATOR_SUCCESS:{
            return {
                ...state,
                candidateListByEvaluator:{
                    loading:false,
                    success:true,
                    error:false,
                    data:action.payload
                }
            }
        }
        case actionType.GET_CANDIDATE_LIST_BY_EVALUATOR_FAILURE:{
            return {
                ...state,
                candidateListByEvaluator:{
                    ...state.candidateListByEvaluator,
                    loading:false,
                    success:false,
                    error:true
                }
            }
        }
        case actionType.UPDATE_CANDIDATE_LIST_BY_EVALUATOR:{
            return {
                ...state,
                candidateListByEvaluator:{
                    ...state.candidateListByEvaluator,
                    data:action.payload
                }
            }
        }
        case actionType.DOWNLOAD_PDF: {
            return {
                ...state,
                pdf:{
                    ...state.pdf,
                    loading:true,
                    success:false,
                    error:false
                }
            }
        }
        case actionType.DOWNLOAD_PDF_SUCCESS: {
            return {
                ...state,
                pdf:{
                    loading:false,
                    success:true,
                    error:false,
                    data:action.payload
                }
            }
        }
        case actionType.DOWNLOAD_PDF_FAILURE: {
            return {
                ...state,
                pdf:{
                    ...state.pdf,
                    loading:false,
                    success:false,
                    error:true
                }
            }
        }
        case actionType.GET_CANDIDATE_LIST_FROM_REVIEW_GRID:{
            return {
                ...state,
                candidateListFromReviewGrid:{
                    ...state.candidateListFromReviewGrid,
                    loading:true,
                    success:false,
                    error:false
                }
            }
        }
        case actionType.GET_CANDIDATE_LIST_FROM_REVIEW_GRID_SUCCESS:{
            return {
                ...state,
                candidateListFromReviewGrid:{
                    ...state.candidateListFromReviewGrid,
                    loading:false,
                    success:true,
                    error:false,
                    data:action.payload
                }
            }
        }
        case actionType.GET_CANDIDATE_LIST_FROM_REVIEW_GRID_FAILURE:{
            return {
                ...state,
                candidateListFromReviewGrid:{
                    ...state.candidateListFromReviewGrid,
                    loading:false,
                    success:false,
                    error:true,
                    data:[]
                }
            }
        }
        case actionType.RESET_HEADER_STATE: {
            return initialState
        }
        default: return state
    }
}

export default headerReducer