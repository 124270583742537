import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";
import * as ConfigConstants from './../constants/config'

import translationENUS from './translations/en-us.json'
import translationZHCH from './translations/zh-cn.json'
import translationESES from './translations/es-es.json'
import translationESMX from './translations/es-mx.json'
import translationPTPT from './translations/pt-pt.json'
import translationNLNL from './translations/nl-nl.json'
import translationENGB from './translations/en-gb.json'
import translationFRFR from './translations/fr-fr.json'
import translationDEDE from './translations/de-de.json'
import translationITIT from './translations/it-it.json'
import translationFRCA from './translations/fr-ca.json'
import translationPTBR from './translations/pt-br.json'
import translationFIFI from './translations/fi-fi.json'
import translationNBNO from './translations/nb-no.json'
import translationARSA from './translations/ar-sa.json'
import translationDADK from './translations/da-dk.json'
import translationINID from './translations/in-id.json'
import translationRORO from './translations/ro-ro.json'
import translationSVSE from './translations/sv-se.json'
import translationSRSR from './translations/sr-sr.json'
import translationHRHR from './translations/hr-hr.json'
import translationMKMK from './translations/mk-mk.json'
import translationSQAL from './translations/sq-al.json'
import translationSLSL from './translations/sl-sl.json'
import translationBSBS from './translations/bs-bs.json'
import translationTRTR from './translations/tr-tr.json'
import translationRURU from './translations/ru-ru.json'
import translationCSCZ from './translations/cs-cz.json'
import translationJAJP from './translations/ja-jp.json'
import translationPLPL from './translations/pl-pl.json'
import translationZHHK from './translations/zh-hk.json'
import translationTHTH from './translations/th-th.json'

const resources = {
  ENUS: {
    translation: translationENUS
  },
  ZHCN:{
    translation: translationZHCH
  },
  ESES: {
    translation: translationESES
  },
  ESMX: {
    translation: translationESMX
  },
  PTPT:{
    translation: translationPTPT
  },
  NLNL: {
    translation: translationNLNL
  },
  ENGB:{
    translation: translationENGB
  },
  FRFR: {
    translation: translationFRFR
  },
  DEDE:{
    translation: translationDEDE
  },
  ITIT: {
    translation: translationITIT
  },
  FRCA: {
    translation: translationFRCA
  },
  PTBR:{
    translation: translationPTBR
  },
  FIFI:{
    translation: translationFIFI
  },
  NBNO:{
    translation: translationNBNO
  },
  ARSA:{
    translation: translationARSA
  },
  DADK:{
    translation: translationDADK
  },
  INID:{
    translation: translationINID
  },
  RORO:{
    translation: translationRORO
  },
  SVSE:{
    translation: translationSVSE
  },
  SRSR:{
    translation: translationSRSR
  },
  HRHR:{
    translation: translationHRHR
  },
  MKMK:{
    translation: translationMKMK
  },
  SQAL:{
    translation: translationSQAL
  },
  SLSL:{
    translation: translationSLSL
  },
  BSBS:{
    translation: translationBSBS
  },
  TRTR:{
    translation: translationTRTR
  },
  RURU:{
    translation: translationRURU
  },
  CSCZ:{
    translation: translationCSCZ
  },
  JAJP:{
    translation: translationJAJP
  },
  PLPL:{
    translation: translationPLPL
  },
  ZHHK:{
    translation: translationZHHK
  },
  THTH:{
    translation: translationTHTH
  }
};
let current_locale = ""
let queryString = window.location.search
let params = new URLSearchParams(queryString)
if(queryString){
  current_locale = (params.get('locale')).toUpperCase().replace(/-|_/g,"")
}else{
  current_locale = (JSON.parse(sessionStorage.session_request_data).locale).toUpperCase().replace(/-|_/g,"")
} 
i18n
  .use(reactI18nextModule) 
  .init({
    resources,
    lng: current_locale,
    fallbackLng: ConfigConstants.DEFAULT_LANGUAGE.toUpperCase().replace(/-|_/g,""),
    keySeparator: false, 

    interpolation: {
      escapeValue: false 
    }
  });

export default i18n;