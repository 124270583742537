import $ from 'jquery'
import * as UrlConstants from '../../../constants/urls';
import {localeMap} from '../../../constants/locales';

export function b64Decode(data) {
    return decodeURIComponent(atob(data).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}
export function isObjectKeyExists(obj, level, ...rest) {
    if (obj === undefined) return false
    if (rest.length === 0 && obj.hasOwnProperty(level)) return true
    return isObjectKeyExists(obj[level], ...rest)
}
export function IsJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
export function htmlDecode(html, response = false) {
    if (!html || typeof html !== "string") {
        return html;
    }
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    const htmlMatchingRegex = new RegExp(/(<[a-zA-Z]{1,}>)|(<\/[a-zA-Z]{1,}>)|(<[a-zA-Z]{1,} \/>)/g);
    const isActualHtml = html.match(htmlMatchingRegex);
    if (isActualHtml !== null) {
        return txt.value;
    }
    txt.value = response ? html : html.replaceAll('<', '&lt;').replaceAll('>', '&gt;');
    return `<span class="font-weight-normal">${txt.value}</span>`;
}
export function clickPopUp(event) {
    event.preventDefault();
    var text = event.target.dataset.popuptext;
    var heading = event.target.dataset.popupheading;
    $(".pop-up-details").empty().append(text)
    $(".modal-header .heading").empty().append(heading)
    $("#openPopupActionLarge").click()
}

export function animatePots(box, max_percent) {
    var cnt = $(box).find(".count")
    var water = $(box).find(".water")
    var percent = Number(cnt.text())
    var interval
    var stopInterval = false
    interval = setInterval(function () {
        if (percent < max_percent) {
            percent++
        }
        else {
            stopInterval = true
        }
        cnt.text(percent)
        water.css("transform", 'translate(0' + ',' + (100 - percent) + '%)')
        if (stopInterval) {
            clearInterval(interval);
        }
    }, 50)
}
export function rightLeftShadow(className) {
    $(className).each(function () {
        $(this).removeClass("shadowRight");
        if (this.scrollWidth > this.offsetWidth)
            $(this).addClass("shadowRight");
    });

    $(className).on('scroll', function () {
        var graphScroller = $(this).scrollLeft();
        if (graphScroller > 0) {
            if (this.scrollWidth > this.offsetWidth) {
                $(this).addClass("shadowRightLeft");
                $(this).removeClass("shadowRight");
            }
            if (this.scrollWidth <= graphScroller + this.offsetWidth) {
                $(this).removeClass("shadowRightLeft");
                $(this).addClass("shadowLeft");
            }
        } else {
            $(this).removeClass("shadowRightLeft");
            $(this).removeClass("shadowLeft");
            $(this).addClass("shadowRight");
        }
    });
}
export function hideTooltip(element) {
    if (element.offsetWidth === element.scrollWidth) {
        $(element)[0].dataset.toggle = ""
        $(element)[0].title = ""
    }
    if ($(window).width() <= 992) {
        $(element)[0].dataset.toggle = ""
        $(element)[0].title = ""
    }
}
export function hideOutline(chapter) {
    if ($('#pills-' + chapter + '.chapterContent').children('.genericError').length) {
        $('#pills-' + chapter + '.chapterContent').css('outline', 'none')
    }
}
export function hideChapterOutline(chapter) {
    $('#pills-' + chapter + '.chapterContent').css('outline', 'none')
}
export function accordionIconFlip() {
    $('.accordion').on('shown.bs.collapse', function () {
        if ($(this).find('.card > .collapse.show')) {
            var card = $(this).find('.card > .collapse.show').siblings();
            $(card).find('.drop-down-icon').addClass('flipIcon');
        }
    });
    $('.accordion').on('hidden.bs.collapse', function () {
        $(this).find('.drop-down-icon').removeClass('flipIcon');
        if ($(this).find('.card > .collapse.show')) {
            var card = $(this).find('.card > .collapse.show').siblings();
            $(card).find('.drop-down-icon').addClass('flipIcon');
        }
    });
}
export function updateTotalModuleScoresPots(automated_scores, module_scores) {
    let changed_modules_scores = []
    for (let moduleid in module_scores) {
        let scoreOb = module_scores[moduleid].candidateScore;
        let scoreMax = module_scores[moduleid].maxScore;
        if (scoreOb !== "" || scoreOb === 0) {
            let item = {}
            if (automated_scores.hasOwnProperty(moduleid)) {
                item = {
                    moduleId: moduleid,
                    manualScoreObj: {
                        value: "" + scoreOb + "/" + scoreMax
                    }
                }
            } else {
                const scoreScaleElement = document.querySelector(`.potBox[data-moduleid="${moduleid}"]`);
                let scoreScale = scoreMax;
                if (scoreScaleElement) {
                    const scoreScaleDataset = scoreScaleElement.dataset.scorescale;
                    if (scoreScaleDataset !== '' & !Number.isNaN(Number(scoreScaleDataset))) {
                        scoreScale = Number(scoreScaleDataset);
                    }
                }
                item = {
                    moduleId: moduleid,
                    percent: scoreOb * 100 / scoreMax,
                    moduleScore: scoreScale < 100 ? scoreOb : scoreOb * 100 / scoreMax,
                    scoreScale: scoreScale,
                    elementID: 'OS_TotalScore',
                    manualScoreObj: {}
                }
            }
            changed_modules_scores.push(item)
        }
    }
    return changed_modules_scores
}
export function topBottomShadow(classname) {
    $(classname).each(function () {
        $(this).removeClass("shadowBottom");
        if (this.scrollHeight > this.offsetHeight)
            $(this).addClass("shadowBottom");
    });
    $(classname).on('scroll', function () {
        var subjectListScroller = $(this).scrollTop();
        if (subjectListScroller > 0) {
            if (this.scrollHeight > this.offsetHeight) {
                $(this).addClass("shadowTopBottom");
                $(this).removeClass("shadowBottom");
            }
            if (this.scrollHeight <= subjectListScroller + this.offsetHeight) {
                $(this).removeClass("shadowTopBottom");
                $(this).addClass("shadowTop");
            }
        } else {
            $(this).removeClass("shadowTopBottom");
            $(this).removeClass("shadowTop");
            $(this).addClass("shadowBottom");
        }
    })
}
export function accordionExpandCollapse() {
    $(".rightEndContainer .accordionIcon").click(function () {
        let id = $(this).attr('id').split('-')[1];
        if ($(this).hasClass('collapseAccordions')) {
            $(this).removeClass('collapseAccordions');
            $(this).addClass('expandAccordions');
            $(this).attr("src", UrlConstants.ASSETS_BASE_URL + "/assets/images/expand-all.svg");
            $(this).parents().find('#moddata-' + id + ' .accordion').each(function () {
                $(this).find('.card > .collapse.show').removeClass('show');
                $(this).find('.drop-down-icon').removeClass('flipIcon');
                $("video").each(function (index, value) {
                    let video = document.getElementById(value['id'])
                    video.pause()
                });
            })
        }
        else if ($(this).hasClass('expandAccordions')) {
            $(this).removeClass('expandAccordions');
            $(this).addClass('collapseAccordions');
            $(this).attr("src", UrlConstants.ASSETS_BASE_URL + "/assets/images/collapse-all.svg");
            $(this).parents().find('#moddata-' + id + ' .accordion').each(function () {
                $(this).find('.card > .collapse').addClass('show');
                $(this).find('.drop-down-icon').addClass('flipIcon');
            })
        }
    })
}
export function accordionExpandCollapseIcon() {
    $('.accordion').click(function () {
        let id = $(this).closest('.moduleData').attr('id').split('-')[1];
        let openedAccordion = 0;
        setTimeout(function () {
            $('#moddata-' + id + ' .accordion .card').each(function () {
                if ($(this).find('.collapse.show').length > 0) {
                    openedAccordion = openedAccordion + 1;
                }
            })
            if (openedAccordion === 0) {
                let img = $('.rightEndContainer #collapseAccordions-' + id)
                $(img).removeClass('collapseAccordions');
                $(img).addClass('expandAccordions');
                $(img).attr("src", "../../assets/images/expand-all.svg");
            }
        }, 600);
    });
}
export function accordionFocus() {
    $('.accordion').on('shown.bs.collapse', function () {
        var card = $(this).find('.card > .collapse.show').siblings('.card-header');
        $('html,body').animate({
            scrollTop: card.offset().top
        }, 500);
    });
}
export function showSnackbar(message, type, isTCReport) {
    $("#snackbar").text(message);
    if (!isTCReport) {
        $("#snackbar").addClass("show");
        if (type == "success") {
            $("#snackbar").addClass("successSnack");
        } else if (type == "error") {
            $("#snackbar").addClass("errorSnack");
        } else {
            $("#snackbar").addClass("defaultSnack");
        }
        setTimeout(function () {
            $("#snackbar").removeClass("show")
            $("#snackbar").removeClass("successSnack")
            $("#snackbar").removeClass("errorSnack")
            $("#snackbar").removeClass("defaultSnack")
        }, 3000)
    }
    else {
        if (type == "error") {
            $("#snackbar").addClass("show");
            $("#snackbar").addClass("errorSnack");
        }
        setTimeout(function () {
            $("#snackbar").removeClass("show")
            $("#snackbar").removeClass("errorSnack")
        }, 3000)

    }
}

export function scrollInPage(chapterid, subroute = []) {
    /**
     * if subroute is present that means, it is not local page surfing, the page has
     * to be sub routed to some feature, currently only in response chapter only
     * it returns the state index if index>-1 (route exists) else would return 0 for default state
     */
    if (window.externalLinks && window.externalLinks.hasOwnProperty(chapterid)) {
        let moduleid = window.externalLinks[chapterid]
        if (Object.keys(subroute).length) {
            let module_route_index = subroute.indexOf(Number(moduleid))
            delete window.externalLinks[chapterid]
            return module_route_index === -1 ? 0 : module_route_index
        } else {
            let focusid = "module-" + moduleid
            let elem = document.getElementById(focusid) || document.getElementById("root")
            elem.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
            delete window.externalLinks[chapterid]
            return 0
        }
    } else {
        return 0
    }
}
export function animateGraphs() {
    $(".progress").each(function (index) {
        let percentage = $(this).find(".progress-bar").data('percent')
        $(this).find(".progress-bar").animate({
            width: percentage
        }, 100);

    })
    $(".progress-vertical").each(function (index) {
        var percent = $(this).find(".bar").data("percent")
        $(this).find(".bar").animate({
            height: percent
        })
        $(this).find(".balloon").animate({
            bottom: percent
        })
    })

}

export function isEmptyObject(object) {
    if (object && Object.keys(object).length !== 0) {
        return false
    }
    else return true
}

// returns true if all values in an Object are empty
export function isEmptyObjectValue(arr) {
    for (let i = 0; i < arr.length; i++) {
        const item = arr[i];
        if (item.value !== "" && item.value !== null && item.value !== 0) {
            return false
        }
    }
    return true
}

export function convertDropDownOptions(data, labelKey, valueKey) {
    if (labelKey && valueKey) {
        return data.map((item) => {
            return {
                label: item[labelKey],
                value: item[valueKey]
            }
        })
    }
    else return data.map((item) => { return { label: item, value: item } })
}

export function sortByAsc(field) {
    return function (a, b) {
        return (a[field] > b[field]) - (a[field] < b[field])
    };
}

export function sortByDesc(field) {
    return function (a, b) {
        return (a[field] < b[field]) - (a[field] > b[field])
    };
}

export function sortByAlpAsc(field) {
    return function (a, b) {
        return a[field]?.text?.trim()?.localeCompare(b[field]?.text?.trim()) || a[field]?.trim()?.localeCompare(b[field]?.trim());
    };
}

export function sortByAlpDesc(field) {
    return function (a, b) {
        return -a[field]?.text?.trim()?.localeCompare(b[field]?.text?.trim()) || -a[field]?.trim()?.localeCompare(b[field]?.trim());
    };
}

export const getMappedLocale = (locale) => {
    if(locale in localeMap) {
        return localeMap[locale];
    } 
    return locale;
} 
