import * as APP_URLS from './config'

const FEURL = window.location.origin ;

export const ASSETS_BASE_URL = `${FEURL}/`;

export const IASSESS_BASE_URL = APP_URLS.URL_TRANSFORM_MAP[FEURL]['IASSESS_BASE_URL']

export const ENVIRONMENT_SPECIFIC_URLS={
    dev:{
        GET_REPORT_URL:APP_URLS.URL_TRANSFORM_MAP[FEURL]['REPORTICA_BASE_URL']+'/v1/report/generate-report',
        PDF_REPORT_URL: APP_URLS.URL_TRANSFORM_MAP[FEURL]['REPORTICA_BASE_URL']+'/v1/report/generate-report',
        GET_CHAPTER_URL: APP_URLS.URL_TRANSFORM_MAP[FEURL]['REPORTICA_BASE_URL']+'/v1/report/generate-chapter',
        LOG_FRONTEND_ERRORS_URL: APP_URLS.URL_TRANSFORM_MAP[FEURL]['REPORTICA_BASE_URL']+'/v1/service/save-frontend-logs'
    },
    live:{
        GET_REPORT_URL: APP_URLS.URL_TRANSFORM_MAP[FEURL]['IASSESS_BASE_URL']+'/assessmentmanagement/newReport/getReport',
        PDF_REPORT_URL: APP_URLS.URL_TRANSFORM_MAP[FEURL]['IASSESS_BASE_URL']+'/assessmentmanagement/newReport/getReportPdf',
        GET_CHAPTER_URL: APP_URLS.URL_TRANSFORM_MAP[FEURL]['IASSESS_BASE_URL']+'/assessmentmanagement/newReport/getReportChapter',
        LOG_FRONTEND_ERRORS_URL: APP_URLS.URL_TRANSFORM_MAP[FEURL]['IASSESS_BASE_URL']+'/url/to/log/api'
    }
}
export const GET_REPORT_URL= ENVIRONMENT_SPECIFIC_URLS[APP_URLS.APPLICATION_ENVIRONMENT]['GET_REPORT_URL']

export const PDF_REPORT_URL = ENVIRONMENT_SPECIFIC_URLS[APP_URLS.APPLICATION_ENVIRONMENT]['PDF_REPORT_URL']

export const GET_CHAPTER_URL=  ENVIRONMENT_SPECIFIC_URLS[APP_URLS.APPLICATION_ENVIRONMENT]['GET_CHAPTER_URL']

export const LOG_FRONTEND_ERRORS_URL = ENVIRONMENT_SPECIFIC_URLS[APP_URLS.APPLICATION_ENVIRONMENT]['LOG_FRONTEND_ERRORS_URL']

export const GET_CANDIDATE_LIST_BY_EVALUATOR = APP_URLS.URL_TRANSFORM_MAP[FEURL]['IASSESS_BASE_URL']+'/assessmentmanagement/newReport/getCandidateListByEvaluator'

export const GET_EVALUATOR_STATUS = APP_URLS.URL_TRANSFORM_MAP[FEURL]['IASSESS_BASE_URL']+'/assessmentmanagement/newReport/getAssessmentStatus'

export const SAVE_EVALUATOR_STATUS= APP_URLS.URL_TRANSFORM_MAP[FEURL]['IASSESS_BASE_URL']+'/assessmentmanagement/newReport/saveAssessmentStatus'

export const GET_CANDIDATE_EVAL_SCORES = APP_URLS.URL_TRANSFORM_MAP[FEURL]['IASSESS_BASE_URL']+'/assessmentmanagement/newReport/getCandidateEvalScores'

export const ADD_EDIT_CANDIDATE_EVAL_SCORES = APP_URLS.URL_TRANSFORM_MAP[FEURL]['IASSESS_BASE_URL']+'/assessmentmanagement/newReport/addEditCandidateEvalScores'

export const GET_CODEPLAYER_DATA = APP_URLS.URL_TRANSFORM_MAP[FEURL]['IASSESS_BASE_URL']+"/assessmentmanagement/playback/getCodePlayerData"

export const EDIT_ON_AMCAT = APP_URLS.URL_TRANSFORM_MAP[FEURL]['IASSESS_BASE_URL']+"/assessmentmanagement/newReport/getTryOnAmcatURL"

export const GET_THEIA_URL = APP_URLS.URL_TRANSFORM_MAP[FEURL]['IASSESS_BASE_URL']+"/assessmentmanagement/newReport/getImageUrl"

export const UPDATE_VADC_SCORE_NARRATIVES = APP_URLS.URL_TRANSFORM_MAP[FEURL]['IASSESS_BASE_URL'] + '/assessmentmanagement/newReport/saveManualNarratives';

export const GET_CANDIDATE_LIST_FROM_REVIEW_GRID = APP_URLS.URL_TRANSFORM_MAP[FEURL]['IASSESS_BASE_URL'] + 'assessmentmanagement/newReport/getCandidateList';

export const GET_CANDIDATE_LIST_FROM_IASSESS = "/assessmentmanagement/NewReport/getCandidateTestData";

export const GET_NEIGHBOURING_CANDIDATES_FROM_IASSESS = "/assessmentmanagement/NewReport/getNeighbours";

export const CANDIDATE_LIST_URL_ADDITION = '/am/v1/candidate-list'
export const UR_REPORT_ID="110012"
export const DEUTSCHE_BANK_COMPANY_ID=[
    '11644','36866','27569','15510','15662','15511', //old IDs
    '40185','36867', // New Prod IDS [US,AU]
    ]//["17135"]//Deutsche Bank Company IDs
export const DEFAULT_CACHED_CANDIDATE_COUNT=50;
export const COLOR_LIGHTNESS_MAX_LIMIT = 70;