import React from 'react'
import {logError} from '../utils/apis'

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      let session_request_data = JSON.parse(sessionStorage.getItem("session_request_data"));
      try {
        logError({
          url: window.location.href,
          errorMsg: error.message,
          errorStack:errorInfo.componentStack,
          requestParams:{...session_request_data}
        });
      } catch(err) {
        console.log(err);
      }
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (
        <div className="errorBoundary">
          <div className="genericError">Something went wrong</div>
        </div>
        )  
      }
  
      return this.props.children; 
    }
  }
export default ErrorBoundary;  
