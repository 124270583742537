import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next';
import {htmlDecode} from '../../common/utils/common'

class DataEntry extends Component {
    constructor(props){
        super(props)
    }
    labelTable=(item, key, q_num)=>{
        let item_length = item['label'].length
        let rows = []
        for (let i = 0; i < item_length; i++){
            rows.push(
                <div className="flexContainer item_row">
                    <div className="width_10 item_row_item">{i == 0 ?q_num: null}</div>
                    <div className="width_25 item_row_item" dangerouslySetInnerHTML={{__html: htmlDecode(item.label[i])}}></div>
                    <div className="width_25 item_row_item"dangerouslySetInnerHTML={{__html: htmlDecode(item.text[i])}}></div>
                    <div className="width_25 item_row_item"dangerouslySetInnerHTML={{__html: htmlDecode(item.input[i])}}></div>
                    <div className={`width_15 item_row_item ${(item["incorrectIndexes"].find(e=> e == i)||(i==0 && item['incorrectIndexes'][0] ==0))?'incorrect_status':null}`} dangerouslySetInnerHTML={{__html: htmlDecode(item.status[i])}}></div>
                </div>
            )
        }
        return rows
    }
    taskTable=(item, key, q_num)=>{
        let item_length = item['task'].length
        let rows = []
        for (let i = 0; i < item_length; i++){
            rows.push(
                <div className="flexContainer item_row">
                    <div className="width_10 item_row_item">{i == 0 ?q_num: null}</div>
                    <div className={`width_15 item_row_item ${(item["incorrectIndexes"].find(e=> e == i)||(i==0 && item['incorrectIndexes'][0] ==0))?'incorrect_status':null}`} dangerouslySetInnerHTML={{__html: htmlDecode(item.status[i])}}></div>
                    <div className="width_25 item_row_item" dangerouslySetInnerHTML={{__html: htmlDecode(item.task[i])}}></div>
                    <div className="width_25 item_row_item" dangerouslySetInnerHTML={{__html: htmlDecode(item.input[i])}}></div>
                    <div className="width_25 item_row_item">{item.correctAnswer[i]}</div>
                </div>
            )
        }
        return rows
    }
    mobileTable=(data)=>{
        let data_entry_items_length = data.length
        let data_entry_list = data
        let mobileTableData = []
        for (let i = 1; i < data_entry_items_length; i++){
            mobileTableData.push(
                <div className="data_entry_item_index row">
                    <div className="col-6 flexContainer data_entry_item">
                        <div className="data_entry_mobile_label">
                            {data_entry_list[0].index}
                        </div>
                        <div className="data_entry_mobile_value">
                            {i}
                        </div>
                    </div>
                </div>    
            )
            let current_item = data_entry_list[i]
            let data_entry_row_length = 0
            if (current_item['label']){ 
                data_entry_row_length = Object.keys(current_item['label']).length
            }else if (current_item['task']){
                data_entry_row_length = Object.keys(current_item['task']).length
            }
            for (let j = 0; j < data_entry_row_length; j++){
                mobileTableData.push(
                    <div className="data_entry_item_mobile_data row">
                        {current_item['label']?
                            <div className="col-6 flexContainer data_entry_item">
                                <div className="data_entry_mobile_label">
                                    {data_entry_list[0].label}
                                </div>
                                <div className="data_entry_mobile_value">
                                    {current_item['label'][j]}
                                </div>
                            </div>
                        :null}
                        {current_item['task']?
                            <div className="col-6 flexContainer data_entry_item">
                                <div className="data_entry_mobile_label">
                                    {data_entry_list[0].task}
                                </div>
                                <div className="data_entry_mobile_value">
                                    {current_item['task'][j]}
                                </div>
                            </div>
                        :null}
                        {current_item['text']?
                            <div className="col-6 flexContainer data_entry_item">
                                <div className="data_entry_mobile_label">
                                    {data_entry_list[0].text}
                                </div>
                                <div className="data_entry_mobile_value">
                                    {current_item['text'][j]}
                                </div>
                            </div>
                        :null}
                        <div className="col-6 flexContainer data_entry_item">
                            <div className="data_entry_mobile_label">
                                {data_entry_list[0].input}
                            </div>
                            <div className="data_entry_mobile_value">
                                {current_item['input'][j]}
                            </div>
                        </div>
                        {current_item['correctAnswer']?
                            <div className="col-6 flexContainer data_entry_item">
                                <div className="data_entry_mobile_label">
                                    {data_entry_list[0].correctAnswer}
                                </div>
                                <div className="data_entry_mobile_value">
                                    {current_item['correctAnswer'][j]}
                                </div>
                            </div>
                        :null}
                        <div className="col-6 flexContainer data_entry_item">
                            <div className="data_entry_mobile_label">
                                {data_entry_list[0].status}
                            </div>
                            <div className={`data_entry_mobile_value ${(current_item["incorrectIndexes"].find(e=> e == j)||(j==0 && current_item['incorrectIndexes'][0] ==0))?'incorrect_status':null} `}>
                                {current_item['status'][j]}
                            </div>
                        </div>
                    </div>    
                )
            }
        }
        return mobileTableData
    }

    render(){
        let module = this.props.module
        return(
            <div className="module_container_detailed_response" style={{display:'block'}}> 
                <div className="mod_data_item detailed_score_report d-none d-sm-block">
                    <div className="gradient_heading">
                        {module.detailedResponse.DR_QuestionsReport.header}
                    </div>
                    <div className="data_section">
                        <div className="data_list flexibleTable">
                            {Object.entries(module.detailedResponse.DR_QuestionsReport.data).map(([key, item])=>{
                                let q_num = key
                                return(
                                    key == 0?
                                        item.label?   
                                            <div className="tbl_row flexContainer">
                                                <div className="width_10 head_row">{item.index}</div>
                                                <div className="width_25 head_row">{item.label}</div>
                                                <div className="width_25 head_row">{item.text}</div>
                                                <div className="width_25 head_row">{item.input}</div>
                                                <div className="width_15 head_row">{item.status}</div>
                                            </div>                                     
                                            :item.task?
                                                <div className="tbl_row flexContainer">
                                                    <div className="width_10 head_row">{item.index}</div>
                                                    <div className="width_15 head_row">{item.status}</div>
                                                    <div className="width_25 head_row">{item.task}</div>
                                                    <div className="width_25 head_row">{item.input}</div>
                                                    <div className="width_25 head_row">{item.correctAnswer}</div>
                                                </div>
                                                :null
                                        :item.label?
                                            <div className="tbl_row_multi_item_row">
                                                {this.labelTable(item, key, q_num)}
                                            </div>
                                            :item.task?
                                                <div className="tbl_row_multi_item_row">
                                                    {this.taskTable(item, key, q_num)}
                                                </div>
                                                :null
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="mod_data_item_mobile d-block d-sm-none">
                    <div className="data_entry_heading">
                        {module.detailedResponse.DR_QuestionsReport.header}
                    </div>
                    {this.mobileTable(module.detailedResponse.DR_QuestionsReport.data)}
                </div>
            </div>
        )
    }
    componentDidMount=()=>{
    }
}
export default withNamespaces()(DataEntry)