
const headerState = {
    pdf:{
       loading:false,
       success:false,
       error:false,
       data:""
    },
    candidateListByEvaluator:{
       loading:false,
       success:false,
       error:false,
       data:[]
    },
    candidateListFromReviewGrid:{
       loading:false,
       success:false,
       error:false,
       data:[]
    }
}

export default headerState