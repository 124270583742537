import React,{ useEffect, useState } from 'react'
import '../css/languagedropdown.css'
import LanguageDropDownImg from '../../../assets/images/language_dropdown_mobile.png'
import Select from '../../common/components/select'

const LanguageDropDown = (props)=>{
    const queryParams = JSON.parse(sessionStorage.getItem("session_request_data"))
    const [ selectedLanguage , setSelectedLangauge ] = useState(queryParams.locale)
    let languages_obj  = props.languages || {}
    languages_obj = Object.fromEntries(
        Object.entries(languages_obj)
        .sort(([key1, val1], [key2, val2]) => val1.localeCompare(val2))
    )
    let languageOptions = Object.entries(languages_obj).map(([value , label]) => ({label :label , value:value}));
    const changeLanguageHandler=(language)=>{
        queryParams.locale = language
        sessionStorage.setItem("session_request_data",JSON.stringify(queryParams))
        setSelectedLangauge(language);
        window.history.pushState(null, null, '/')
        window.location.reload()
    }
    return(
     <>
     { languageOptions.length>1?
        <div className="languageChangeContainer">
            <Select value={selectedLanguage} selectOptions={languageOptions} onUpdate={changeLanguageHandler} className="language-dropdown" panelClass="select-items" ></Select>
        </div>
        :null   
     }   
    
    </>
    )
}

export default LanguageDropDown