import React, {useEffect,useState,useRef} from 'react'
import {NavLink ,useHistory} from 'react-router-dom'
import {CHAPTER_MAPPING} from '../../common/utils/mapping';
import ArrowIcon from '../../../assets/images/down.svg';
import '../../../assets/css/helperClasses.css';

const ChapterListDr=(props)=>{
  const [showRightArrow,setShowRightArrow]=useState(false);
  const [showLeftArrow,setShowLeftArrow]=useState(false);
    let chapterdetails = props.chapterdetails
    let DEFAULT_CHAPTER = props.defaultChapter
    let isMappedEvaluator = props?.isMappedEvaluator;
    const COMPONENT_MAP = {...CHAPTER_MAPPING}
    const navContainerRef = useRef();
    const chapterListsRef = useRef();
    const chapterListDr = document.querySelector('.chapterListDr');

    const [ locationKeys, setLocationKeys ] = useState([])
    const history = useHistory();

    const handleNavgationChange = (e) => {
      const navItemList = document.querySelectorAll('.nav-item');
      const navAnchorItemList = document.querySelectorAll('.nav-link');
      navItemList.forEach(item => item.classList.remove('active'));
      navAnchorItemList.forEach(item => item.classList.remove('active'));
      e.currentTarget.classList.add('active');
      e.currentTarget.children[0].classList.add('active');
      if (e.target === e.currentTarget) {
        history.push(e.currentTarget?.dataset?.url);
      }
      const parentElement = e.currentTarget.parentElement;
      const x = e.currentTarget.offsetLeft;
      const c = e.currentTarget.offsetWidth;
      const d = x + ((parentElement.scrollHeight/2) - c);
      parentElement.scrollTo({left: d, behaviour: 'smooth'});
      if (showRightArrow || showLeftArrow) {
        if (chapterListDr.scrollLeft<=40) {
          navContainerRef.current.scrollLeft-=200;
          enableRightArrow();
        } else if(chapterListDr.offsetWidth + chapterListDr.scrollLeft<=1610 || chapterListDr.offsetWidth + chapterListDr.scrollLeft >= chapterListDr.scrollWidth){
          navContainerRef.current.scrollLeft+=200;
          enableLeftArrow();
        } else{
          enableBothArrow();
        }
      }
    }
    const scrollRight=()=>{
      chapterListsRef.current.scrollLeft+=200;
      if (chapterListDr.offsetWidth + chapterListDr.scrollLeft >= chapterListDr.scrollWidth) {
        enableLeftArrow();
      } else if(chapterListDr.scrollLeft>0){
        setShowLeftArrow(true);
      }
    }
    const scrollLeft=()=>{ 
      chapterListsRef.current.scrollLeft-=200;
      if (chapterListDr.scrollLeft<=40) {
        enableRightArrow();
      } else if(chapterListDr.scrollLeft>0){
        setShowRightArrow(true);
      }
    }
    const enableRightArrow=()=>{
      setShowRightArrow(true);
      setShowLeftArrow(false);
    }
    const enableLeftArrow=()=>{
      setShowRightArrow(false);
      setShowLeftArrow(true);
    }
    const enableBothArrow=()=>{
      setShowRightArrow(true);
      setShowLeftArrow(true);
    }
    const selectOption=()=>{
      let listOffsetWidth = chapterListsRef.current.offsetWidth;
      let navOffsetWidth = navContainerRef.current.offsetWidth;
      if(navOffsetWidth===listOffsetWidth){
        // show right arrow
        setShowRightArrow(true);
        setShowLeftArrow(false);
      } else if(navOffsetWidth > listOffsetWidth){
        // hide arrows
        setShowRightArrow(false);
        setShowLeftArrow(false);
      }
    }

    useEffect(()=>{
      setTimeout(()=>{
        let elem = document.querySelector(".nav-link.chapterName.active")
        if(elem){
          elem.click()
        }
      },1000);
    },[])

    useEffect(() => {
        return history.listen(location => {
          if (history.action === 'PUSH') {
            setLocationKeys([ location.key ])
            selectOption();
          }
      
          if (history.action === 'POP') {
            if (locationKeys[1] === location.key) {
              setLocationKeys(([ _, ...keys ]) => keys)
              let list = document.querySelector('.chapterList')
              if(!list.querySelector('a.active')){
                document.querySelector("#pills-"+DEFAULT_CHAPTER+"-tab").click()
              }
            } else {
              setLocationKeys((keys) => [ location.key, ...keys ])
              let list = document.querySelector('.chapterListDr')
              if(!list.querySelector('a.active')){
                document.querySelector("#pills-"+DEFAULT_CHAPTER+"-tab").click()
              }
            }
          }
        })
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [ locationKeys ])
 
        return (
            <React.Fragment>
              {chapterdetails.length?
              <>
                  <nav className="navbar navbar-toggleable-md navbar-light bg-faded row reportNavContainer" ref={navContainerRef} style={{scrollbarWidth: "none"}}>
                  {showRightArrow && <div className="right-arrow"><button onClick={()=>scrollRight()} className="btn-arrow"><img src={ArrowIcon} className="right-arrow-icon" alt="Right Icon" /></button></div>}
                  {showLeftArrow && <div className="left-arrow"><button onClick={()=>scrollLeft()} className="btn-arrow"><img src={ArrowIcon} className="left-arrow-icon" alt="Left Icon" /></button></div>}
                    <ul className = {`nav navbar-nav chapterListDr ${chapterdetails.length === 1 && false ? "d-none" : ''}`} ref={chapterListsRef} id="pills-tab">
                      {
                        chapterdetails.map((header) => (
                          <>
                          { header.value == 'YourFeedbackChapter' && !isMappedEvaluator ? 
                            null
                            : 
                            <li className="nav-item chapterListLI font-action" key={header.value} onClick={handleNavgationChange} data-url={'/'+COMPONENT_MAP[header.value]['url']}>
                              <NavLink className={`nav-link chapterName `}
                              id={'pills-'+header.value+'-tab'}
                              data-testid={'pills-'+header.value+'-tab'}
                              data-chapterid={header.value}
                              to={'/'+COMPONENT_MAP[header.value]['url']}>
                                {header.label}
                              </NavLink>
                            </li>
                          }
                          </>
                        ))
                      }
                    </ul>
                  </nav>
                  </> : null
              }
            </React.Fragment>
        )
    }

export default ChapterListDr
