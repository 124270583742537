import React from "react";
import '../css/select.css'
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import downIcon from '../../../assets/images/toggleDown.svg';
import { drop } from "underscore";

const Select = ({ value, selectOptions, onUpdate, labelKey='label', valueKey='value', className, panelClass , headerClass }) => {
    const dropdownRef = useRef(null)
    const toggleIconRef = useRef(null);
    const [dropdownOpened, setDropdownOpened] = useState(false)

    const onChange = (e) => {
        toggleIconRef.current.style.transform = 'rotate(0deg)'
        setDropdownOpened(false)
        onUpdate(e.currentTarget.dataset.value)
    }
    
    const hanldeClickOutside = (e) => {
        let isOpended=dropdownOpened
        if (dropdownRef.current!==null && isOpended && !dropdownRef?.current.contains(e.target)) {
            toggleIconRef.current.style.transform = 'rotate(0deg)'
            setDropdownOpened(false)
        }
    }
    
    useEffect(() => {
        document.addEventListener("click",hanldeClickOutside)
    },[dropdownOpened])

    const onDropdownClick = (e) => {
        if (dropdownOpened) {
            toggleIconRef.current.style.transform = 'rotate(0deg)'
        }
        else {
            toggleIconRef.current.style.transform = 'rotate(180deg)'
        }
        setDropdownOpened((prevValue) => !prevValue)
    }

    const getSelectedOption = () => {
       let option = selectOptions?.find(option => option[`${valueKey}`] == value )
       return option ? option[`${labelKey}`] : ''
    }

    return (
        <div ref={dropdownRef} className={`select-dropdown ${className}`} id="select-dropdown">
            <button onClick={onDropdownClick} className={`text-start d-flex justify-content-between align-items-center shadow-none select-dropdown-btn  w-100 ${headerClass}`} id="" >
                <div className="text-truncate">{getSelectedOption()}</div>
                <img className="toggle-icon ml-2" ref={toggleIconRef} src={downIcon} alt="image could not be downloaded" />
            </button>
            {dropdownOpened ? <div className={`shadow select-dropdown-menu ${panelClass}`} id="select-dropdown-menu">
                {selectOptions?.map((option, index) => {
                    return <li className={`p-2 select-dropdown-item text-start text-truncate ${option[`${valueKey}`] == value ? 'font-action-bold selected' : '' }`}  data-value={option[`${valueKey}`]} onClick={onChange} >{option[`${labelKey}`]}</li>
                })}
            </div> : null}
        </div>
    )
}

export default Select;