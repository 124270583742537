import actionType from "../../actions/mainAction/mainActionType"
import initialState from "./mainState"

const mainReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_REPORT: {
            return {
                ...state,
                report: {
                    ...state.report,
                    loading: true,
                    success: false,
                    error: false
                }
            }
        }
        case actionType.GET_REPORT_SUCCESS: {
            return {
                ...state,
                report: {
                    ...state.report,
                    data:action.payload,
                    loading: false,
                    success: true,
                    error: false
                }
            }
        }
        case actionType.GET_REPORT_FAILURE: {
            return {
                ...state,
                report:{
                    ...state.report,
                    loading: false,
                    success: false,
                    error: true
                }
            }
        }
        case actionType.SET_SESSION_DATA: {
            return {
                ...state,
                sessionData:{
                    ...action.payload
                }
            }
        }
        default: return state
    }
}

export default mainReducer