import React from 'react';

export const DETAILED_RESPONSE_FEATURES_MAPPING = {
    'DR_DataEntryResponse' : require('../../detailedresponse/components/dataentry').default,
    'DR_McqResponse' : require('../../detailedresponse/components/mcq').default,
    'DR_MixedResponse' : require('../../detailedresponse/components/mixed').default,
    'DR_WardheadTyping' : require('../../detailedresponse/components/wardheadtyping').default
}

export const RESPONSE_FEATURES_MAPPING = {
    'CR_Automata':{
        url:'automata',
        modulePath:React.lazy(()=>import('../../candidateresponse/components/features/automata'))
    },
    'CR_AutomataDataScience':{
        url:'datascience',
        modulePath:React.lazy(()=>import('../../candidateresponse/components/features/datascience'))
    },
    'CR_AutomataFix':{
        url:'automatafix',
        modulePath:React.lazy(()=>import('../../candidateresponse/components/features/automatafix'))
    },
    'CR_AutomataSql':{
        url:'automatasql',
        modulePath:React.lazy(()=>import('../../candidateresponse/components/features/automatasql'))
    },
    'CR_Autoview':{
        url:'autoview',
        modulePath:React.lazy(()=>import('../../candidateresponse/components/features/autoview'))
    },
    'CR_ChatBot':{
        url:'chatbot',
        modulePath:React.lazy(()=>import('../../candidateresponse/components/features/chatbot'))
    },
    'CR_DevOps':{
        url:'devops',
        modulePath:React.lazy(()=>import('../../candidateresponse/components/features/devops'))
    },
    'CR_Frontend':{
        url:'frontend',
        modulePath:React.lazy(()=>import('../../candidateresponse/components/features/frontend'))
    },
    'CR_OpenResponse':{
        url:'openresponse',
        modulePath:React.lazy(()=>import('../../candidateresponse/components/features/openresponse'))
    },
    'CR_SystemRequirements':{
        url:'systemrequirements',
        modulePath:React.lazy(()=>import('../../candidateresponse/components/features/sysremrequirements'))
    },
    'CR_Writex':{
        url:'writex',
        modulePath:React.lazy(()=>import('../../candidateresponse/components/features/writex'))
    },
    'CR_Email':{
        url:'email',
        modulePath:React.lazy(()=>import('../../candidateresponse/components/features/email'))
    },
    'CR_ProofReading':{
        url:'proofreading',
        modulePath:React.lazy(()=>import('../../candidateresponse/components/features/proofreading'))
    },
    'CR_SvarResponse':{
        url:'svarresponse',
        modulePath:React.lazy(()=>import('../../candidateresponse/components/features/svarresponse'))
    },
    'CR_FullStack':{
        url:'fullstack',
        modulePath:React.lazy(()=>import('../../candidateresponse/components/features/fullstack'))
    },
    'CR_Smartmeet':{
        url:'smartmeet',
        modulePath:React.lazy(()=>import('../../candidateresponse/components/features/smartmeet'))
    }
}

export const CHAPTER_MAPPING={
    'ScoreSummaryChapter' :{
        url:'score-summary',
        modulePath:React.lazy(()=>import('../../scoresummary/components/scoresummary'))
    },
    'IntroductionChapter' :{
        url:'introduction',
        modulePath:React.lazy(()=>import('../../introduction/components/introduction'))
    },
    'CandidateResponseChapter' :{
        url:'response',
        modulePath:React.lazy(()=>import('../../candidateresponse/components/candidateresponse'))
    },
    'ProctoringChapter' :{
        url:'proctoring',
        modulePath:React.lazy(()=>import('../../proctoringchapter/components/ProctoringChapterWrapper'))
    },
    'YourFeedbackChapter' :{
        url:'feedback',
        modulePath:React.lazy(()=>import('../../yourfeedback/components/yourfeedback'))
    },
    'CandidateProfileChapter' :{
        url:'candidate-insight',
        modulePath:React.lazy(()=>import('../../candidateprofile/components/candidateprofile'))
    },
    'ProbesChapter' :{
        url:'interview-questions',
        modulePath:React.lazy(()=>import('../../probes/components/probes'))
    },
    'LearningResourcesChapter' :{
        url:'learning-resources',
        modulePath:React.lazy(()=>import('../../learningresources/components/learningresources'))
    },
    'DetailedResponseChapter' :{
        url:'detailed-response',
        modulePath:React.lazy(()=>import('../../detailedresponse/components/detailedresponse'))
    },
    'ProfileSnapshotChapter' :{
        url:'profile-snapshot',
        modulePath:React.lazy(()=>import('../../profilesnapshot/components/profilesnapshot'))
    },
    'AboutReportChapter' :{
        url:'about',
        modulePath:React.lazy(()=>import('../../about/components/about'))
    },
    'InsightsChapter' :{
        url:'insights',
        modulePath:React.lazy(()=>import('../../insights/components/insights'))
    },
    'ProfileChapter' :{
        url:'profile',
        modulePath:React.lazy(()=>import('../../profile/components/Profile'))
    },
    'ResultsChapter' :{
        url:'results',
        modulePath:React.lazy(()=>import('../../results/components/results'))
    },
    'JobImpactChapter' :{
        url:'job-impact',
        modulePath:React.lazy(()=>import('../../jobimpact/components/jobimpact'))
    },
    'InterviewQuestionsChapter' :{
        url:'ucf-interview-questions',
        modulePath:React.lazy(()=>import('../../universal-interview-questions/components/InterviewQuestionsUR'))
    },
    'DevelopmentChapter' :{
        url:'ucf-development-chapter',
        modulePath:React.lazy(()=>import('../../universal-development-chapter/components/URDevelopmentChapter'))
    },
    'SummaryChapter' :{
      url:'summary',
      modulePath:React.lazy(()=>import('../../summary/components/summary'))
    },
    'CustomOneChapter' :{
        url:'static1',
        modulePath:React.lazy(()=>import('../../static-chapters/components/chapter1'))
    },
    'CustomTwoChapter' :{
        url:'static2',
        modulePath:React.lazy(()=>import('../../static-chapters/components/chapter2'))
    },
    'CustomThreeChapter' :{
        url:'static3',
        modulePath:React.lazy(()=>import('../../static-chapters/components/chapter3'))
    },
    'CustomFourChapter' :{
        url:'static4',
        modulePath:React.lazy(()=>import('../../static-chapters/components/chapter4'))
    },
    'CustomFiveChapter' :{
        url:'static5',
        modulePath:React.lazy(()=>import('../../static-chapters/components/chapter5'))
    },
    'systemdefault':{
        url:'summary',
        modulePath:React.lazy(()=>import('../../scoresummary/components/scoresummary'))
    },
    'ConciseChapter': {
        url: 'concise',
        modulePath:React.lazy(()=>import('../../concise/components/ConciseChapterWrapper'))
    }
}

export const CLIENT_CSS_MAPPING={
    'clien_name_1':{
        css:'client_name_1',
        modulePath:React.lazy(()=>import('../../client/components/ClientName_1'))
    }
}
export const STATUS_ICON_MAPPING={
    '1':'/assets/images/thumbs-up.svg',
    '2':'/assets/images/thumbs-side.svg',
    '3':'/assets/images/thumbs-down.svg'
}
