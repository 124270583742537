import React from 'react';
import ArrowIcon from '../../../../assets/images/arrow_white_down.svg';


function DropDownArrow(props) {
    const {toggleModal} = props;

    return (
        <div className="dropdown-arrow" id="dropdownMenuLinkAM" aria-haspopup="true" aria-expanded="false" onClick={toggleModal}>
            <img src={ArrowIcon} alt="down arrow" id="imgToRotateAM"/>
        </div>
    )
}

export default DropDownArrow;
