import axios from 'axios';
import * as UrlConstants from '../../../constants/urls';
import { IsUrReport } from '../components/fullscreenloader';
import { b64Decode } from './common';
import axiosInstance from './axios';

const session_request_data = { ...JSON.parse(sessionStorage?.session_request_data || null) }

export async function getChapterData(chapterid,set_chapter_data_callback,before_callback=null){
    let session_report_data = JSON.parse(sessionStorage.getItem("session_report_data"))
    let session_request_data  = JSON.parse(sessionStorage.getItem("session_request_data"))
    session_request_data.outputFormat="json"
    session_request_data.defaultChapterId = chapterid // making the current selected tab as default chapter
    let dataToSend ={
        chapterId:chapterid,
        ...session_request_data
      }
    if(!session_report_data[chapterid]){
        let responseData = await axios({
            url: UrlConstants.GET_CHAPTER_URL,
            params: dataToSend,
        })
        let reportData = await JSON.parse(b64Decode(btoa(atob(decodeURIComponent(responseData.data.data.report)))))
        session_report_data[chapterid]={...reportData,isDataLoaded:true}
        sessionStorage.setItem("session_report_data",JSON.stringify(session_report_data))
        sessionStorage.setItem("session_request_data",JSON.stringify(session_request_data))
        if(IsUrReport()){
            document.body.classList.add('universal-report');
        }
    }
    set_chapter_data_callback(session_report_data[chapterid])    
}

export async function fetchChapterData(chapterid){
    let session_report_data = JSON.parse(sessionStorage.getItem("session_report_data"))
    let session_request_data  = JSON.parse(sessionStorage.getItem("session_request_data"))
    session_request_data.outputFormat="json"
    session_request_data.defaultChapterId = chapterid // making the current selected tab as default chapter
    let dataToSend ={
        chapterId:chapterid,
        ...session_request_data
      }
    if(!session_report_data[chapterid]){
        let responseData = await axios({
            url: UrlConstants.GET_CHAPTER_URL,
            params: dataToSend,
        })
        let reportData = await JSON.parse(b64Decode(btoa(atob(decodeURIComponent(responseData.data.data.report)))))
        session_report_data[chapterid]={...reportData,isDataLoaded:true}
        sessionStorage.setItem("session_report_data",JSON.stringify(session_report_data))
        sessionStorage.setItem("session_request_data",JSON.stringify(session_request_data))
        if(IsUrReport()){
            document.body.classList.add('universal-report');
        }
    }
   return session_report_data[chapterid];    
}

export async function makePostRequest(dataObj, url, callback, otherFlags={}){
    let dataString = JSON.stringify(dataObj)
    let bodyFormData = new FormData();
    bodyFormData.append('data',dataString)
    const axiosInstance = axios.create({
        baseURL: url
    })
    let axiosConfig = {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
    }
    if(otherFlags.before_callback){
        axiosInstance.interceptors.request.use(function (config) {
            otherFlags.before_callback()
            return config;
          }, function (error) {
            return Promise.reject(error);
          });
    }
    let responseData=null;
    await axiosInstance.post(url, bodyFormData,axiosConfig).then(response => {
        responseData=response;
    }).catch(err=>err.response)
    callback(responseData)
}

export async function makeGetRequest(params, url, callback, otherFlags={}){
    const axiosInstance = axios.create({
        baseURL: url
    })
    let axiosConfig = {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
    }
    let responseData = await axiosInstance.get(url, {params:params}, axiosConfig).catch(err=>err.response)
    callback(responseData)
}

export function logError(data){
    makePostRequest({data:{...data}},UrlConstants.LOG_FRONTEND_ERRORS_URL,r=>r,{})
}

export async function getCandidateList (callback, params=null) { 
    const session_request_data = { ...JSON.parse(sessionStorage?.session_request_data || null) }

    const candidateListParams = {
        reportId : params?.reportId || session_request_data?.reportId,
        testId : params?.testId || session_request_data?.testId,
        searchText : params?.string || "", 
        offset : params?.offset,
        limit : params?.limit
    }

    await axiosInstance.post(UrlConstants.GET_CANDIDATE_LIST_FROM_IASSESS, null, {params:candidateListParams})
        .then(res=>candidateListDataModel(res))
        .catch(err=>err.response)
    
    function candidateListDataModel (res) {
        let resObj, resArr, resModelArr;

        if(res?.data?.code === 200){
            resObj = res?.data?.data
            resArr = Object.values(resObj)
            resModelArr = resArr.map((item) => {
                return {
                    amcatId : item?.candidatePublicID,
                    name : item?.displayName,
                    emailId : item?.emailID[0], 
                    band : item?.band
                }
            })
            callback (resModelArr)
        } else {
            callback (res?.data?.code)
            console.error("No Candidates Found")
        }
    }
}

export async function getNeighbours (amcatId , callback, num=1) {
    const session_request_data = { ...JSON.parse(sessionStorage?.session_request_data || null) }

    const getNeighboursParams = {
        reportId : session_request_data?.reportId,
        testId : session_request_data?.testId,
        amcatId : amcatId,
        fetchMode : num,
        limit:UrlConstants.DEFAULT_CACHED_CANDIDATE_COUNT
    }
    
    await axiosInstance.post(UrlConstants.GET_NEIGHBOURING_CANDIDATES_FROM_IASSESS, null, {params:getNeighboursParams})
        .then(res=>getNeighboursDataModel(res))
        .catch(err=>err.response)

    function getNeighboursDataModel (res) {
        if (res?.data?.data?.candidatesList.length > 0) {
            const resArr = res?.data?.data?.candidatesList 
            callback(resArr);
        } else {
            console.error("Neighbouring Candidates not found")
        }
    }
}