import { BaseApi } from "./baseApi";
import { PDF_REPORT_URL , GET_CANDIDATE_LIST_FROM_IASSESS, GET_CANDIDATE_LIST_BY_EVALUATOR, GET_NEIGHBOURING_CANDIDATES_FROM_IASSESS, GET_CANDIDATE_LIST_FROM_REVIEW_GRID } from "../constants/urls";
const baseApi = new BaseApi()

export class HeaderApi {
     downloadPdf(parameter , requestAction , requestSuccessAction , requestFailureAction) {
          return baseApi.get(PDF_REPORT_URL , parameter , requestAction , requestSuccessAction , requestFailureAction)
     }
     getCandidateList(parameter , requestAction , requestSuccessAction , requestFailureAction ) {
          return baseApi.post(GET_CANDIDATE_LIST_FROM_IASSESS , parameter , null ,requestAction , requestSuccessAction , requestFailureAction )
     }
     getCandidateListByEvaluator(payload , requestAction , requestSuccessAction , requestFailureAction) {
          return baseApi.post(GET_CANDIDATE_LIST_BY_EVALUATOR , null ,payload ,requestAction , requestSuccessAction , requestFailureAction )
     }
     getNeighboursList(parameter , requestAction , requestSuccessAction , requestFailureAction) {
          return baseApi.post(GET_NEIGHBOURING_CANDIDATES_FROM_IASSESS , parameter ,null , requestAction , requestSuccessAction , requestFailureAction)
     }
     getCandidateListFromReviewGrid(parameter , requestAction , requestSuccessAction , requestFailureAction ) {
          return baseApi.get(GET_CANDIDATE_LIST_FROM_REVIEW_GRID , parameter , null ,requestAction , requestSuccessAction , requestFailureAction )
     }
}


