import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next';
import {htmlDecode} from '../../common/utils/common'

class WardheadTyping extends Component {
    constructor(props){
        super(props)
    }
    render(){
        let module = this.props.module  
        let moduleData = module['detailedResponse']['DR_WardheadTypingResponse']['data'][0]
        let resp_arr = moduleData['response']['data'].split(' ')
        return(
            <div className="module_container_detailed_response" style={{display:'block'}}>
                <div className="warhead_cont">
                    <div className="candidate_response">
                        <div className="candidate_response_heading">
                            {moduleData['response']['header']}
                        </div>
                        <div className="candidate_response_data">
                            {Object.entries(resp_arr).map(([key, word])=>{
                                return(
                                    (moduleData['response']['wrongWords'].find(e=> e==key) || (key==0 && moduleData['response']['wrongWords'][0] ==0) ) ?
                                        <span className="wrong_word">{word.trim()} </span>
                                    :<span>{word.trim()} </span>
                                )
                            })}
                        </div>
                    </div>

                    <div className="original_response">
                        <div className="original_response_heading">
                            {moduleData['passage']['header']}
                        </div>
                        <div className="original_response_data" 
                            dangerouslySetInnerHTML={{__html: htmlDecode(moduleData['passage']['data'])}}>
                        </div>
                    </div>

                    <div className="typing_error_info">
                        <div className="red_highlight_outer">
                            <div className="red_highlight_inner"></div>
                        </div>
                        <div className="err_info_text">{module['detailedResponse']['DR_WardheadTypingResponse']['info']['red']}</div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withNamespaces()(WardheadTyping)